
import { useCallback, useRef, useState } from "react";
import {  useDataNewestRecently, useProjectListAsync, useRecentlyDataAsync } from "../hooks";
import { useRecentlyState } from "../state";
import TaskRecently from "./TaskRecently";
import { SocketAssigneeConText } from "socket/socket-asssign-context";
const RecentlyTask = () => {
  const [page, setPage] = useState(1)
  const [hasMore, setHasmore] = useState(true)

  const valueSocketAssignee = SocketAssigneeConText()
  const changeAssigneeSocket = valueSocketAssignee?.changeAssigneeSocket

  
  const {tasks} = useRecentlyState()

  useRecentlyDataAsync(page,setHasmore,setPage);
  useProjectListAsync()
  useDataNewestRecently(changeAssigneeSocket)



  const observe = useRef<IntersectionObserver | null>(null)
  const lastElement:any = useCallback(
      (node: HTMLElement) => {
          if (observe.current) observe.current?.disconnect()
          observe.current = new IntersectionObserver((entries) => {
              if (entries[0].isIntersecting && hasMore) {
                  setPage((prev:number) => prev + 1)
              }
          })
  
          if (node) {
              observe.current?.observe(node)
          }
      },
      [hasMore]
  )


  return (
    <>
      {tasks && tasks.length>0 && 
          tasks?.map((task:any, index:number) => {
          if(task.completed && !task.hide) {
            return (
              <TaskRecently 
                key={task.taskId}
                task={task} 
                lastElement={tasks.length === index + 1 ? lastElement : null}
              />
            )
          }
        })
      }
    </>
  )
}
export default RecentlyTask
import React, { useEffect, useState } from "react";

import { DeleteOutlined } from "@ant-design/icons";
import { MentionData } from "@draft-js-plugins/mention";
import { useCreate, useDelete, useList } from "@pankod/refine-core";
import { useAppContext } from "App.context/App.context";
import { USER_ID } from "authProvider";
import { MentionMany } from "components/Mytask/MentionMany/MentionMany";
import "./DetailsFooter.scss";
import "./CollaboratorsFooter.scss";
import { Avatar } from "components/avatar/avatar";

interface CollaboratorsFooterProps {
  resourceId?: any;
}

export const CollaboratorsFooter: React.FC<CollaboratorsFooterProps> = ({
  resourceId,
}) => {
  const userId = localStorage.getItem(USER_ID);
  const { mutate } = useDelete();
  const Create = useCreate();
  const [allFollowers, setAllFollowers] = useState<any | undefined>([]);
  const [mentionData, setMentionData] = useState<MentionData | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [ischeck,setischeck]=useState(false);
  const [ischeckmember,setischeckmember]=useState(false);
  const [page, setPage] = useState<number>(1);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [newFollowers, setNewFollowers] = useState<any | undefined>([]);
  const [getRequest, setGetRequest] = useState(false)
  const followers = useList({
    resource: "resourcesfollowers",
    queryOptions: { cacheTime: 0 , enabled: isOpen ? true : false},
    config: {
      filters: [
        {
          field: "resourcesId",
          operator: "eq",
          value: resourceId,
        },
        
      ],
      pagination: { current: 1, pageSize: 400 },
    },
    metaData: {
      config: {
        fields: ["followersId"],
        joins: [{ field: "userfollow", select: ["id", "name"] }],
      },
    },
  }).data;

  useEffect(() => {
    if (mentionData) {
      Create.mutate(
        {
          resource: `resourcesfollowers`,
          values: {
            resourcesId: resourceId,
            followersId: mentionData?.id,
          },
          successNotification: {
            message: "Thêm người theo dõi thành công !",
            type: "success",
          },
        },
        {
          onSuccess: (data, variables, context) => {
          },
          onError: (error, variables, context) => {},
        }
      );
    }
  }, [mentionData]);
  
  const handleClickDeleteMember = (option: any) => {
    mutate({
      resource: "resourcesfollowers",
      id: option.id,
    });
  };

  useEffect(() => {
    setAllFollowers(followers?.data)
  }, [followers,isOpen]);
  

  const isOpenPopupMember =(ischeck:any)=>{
    setIsOpen(true)
    setGetRequest(true)
    setischeck(!ischeck)
    setischeckmember(false)
  }
  
  const isClosePopupMember =(ischeckmember:any)=>{
    setIsOpen(false)
    setGetRequest(true)
    setischeckmember(!ischeckmember)
    setischeck(false)
  }
  return (
    <div className="collaborators">
      <div className="box">
        <a
          className="button"
          // href="#popup1"
          style={{ display: "flex", gap: "10px", margin: "0" }}
          onClick={()=>isOpenPopupMember(ischeck)}
        >
          <p>Collaborators</p>
          <svg
            className="Icon UserPlusIcon"
            viewBox="0 0 32 32"
            aria-hidden="true"
            focusable="false"
          >
            <path d="M31,26h-3v-3c0-0.6-0.4-1-1-1s-1,0.4-1,1v3h-3c-0.6,0-1,0.4-1,1s0.4,1,1,1h3v3c0,0.6,0.4,1,1,1s1-0.4,1-1v-3h3c0.6,0,1-0.4,1-1S31.6,26,31,26z M16,18c4.4,0,8-3.6,8-8s-3.6-8-8-8s-8,3.6-8,8S11.6,18,16,18z M16,4c3.3,0,6,2.7,6,6s-2.7,6-6,6s-6-2.7-6-6S12.7,4,16,4z M21.2,20H8.8C5,20,2,23,2,26.8V31c0,0.6,0.4,1,1,1s1-0.4,1-1v-4.2C4,24.2,6.2,22,8.8,22h12.4c0.6,0,1-0.4,1-1S21.8,20,21.2,20z"></path>
          </svg>
        </a>
      </div>
      {isOpen ? 
      <div  className={"overlay" + (ischeck ?  " targetmember" + (ischeckmember ? " display_none" : "") : "")}>
        <div className="popup">
          <h2>Add members...</h2>
          <a className="close"   onClick={()=>isClosePopupMember(ischeckmember)}>×</a>

          <div className="overlay_mention">
            <MentionMany getData={setMentionData} resestOnMention={true} />
          </div>
          <br />
          <div className="overlay_input_margintop">
            {allFollowers &&
              allFollowers.map((item: any, index: any) => {
                return (
                  <div className="collaborators_items">
                    <div className="collaborators_icons">
                      <Avatar userId={item?.userfollow?.id} isTooltip={false}/>
                    </div>
                    <div className="collaborators_names">
                      <p>{item?.userfollow?.name}</p>
                    </div>
                    <div
                      className="collaborators_names_delete"
                      
                    >
                      <span className="deletemembers_cusor" onClick={() => handleClickDeleteMember(item)}>
                        Delete <DeleteOutlined />
                      </span>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
      : null}
    </div>
  );
};

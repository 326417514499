import axios from 'axios'
import { useEffect } from 'react'
import { USER_ID } from 'authProvider'

export const useFetCreactFromTask = (setDataTask: any, params: any) => {
    const idTaskCauHinh = params?.id
    useEffect(() => {
        const fetchDataTaskName = async () => {
            const result = await axios.get(`resources/lay-thong-tin-task-cau-hinh/${idTaskCauHinh}`)
            setDataTask(result?.data)
        }

        fetchDataTaskName()
    }, [])
}

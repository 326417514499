import axios from "axios";
import ApiConstants from "constanst";

export async function getListUsers(){
    try {
        const { data } = await axios.get(`${ApiConstants.BASE_URL}/${ApiConstants.RESOURCES}?join=resourceRelation2&filter=resourcetype||$eq||user&fields=name,id&limit=1500&page=1&offset=0`);
        return data;
      } catch (error) {    
        console.error(error);
      }
      
}





import { useAppContext } from "App.context/App.context";
import React from "react";
import { FcLock } from "react-icons/fc";
import "./LockPermission.scss";

const LockPermission = () => {
  const AppContext = useAppContext();
  const isSidebarShow = AppContext?.showSideBar;

  const style = { left: !isSidebarShow ? "calc(50% + 200px)" : "50%" };
  return (
    <div className="lock-con" style={style}>
      <FcLock className="lock-icon" />
      <h2>You don't have permission</h2>
    </div>
  );
};

export default LockPermission;

import { AppProvider, useAppContext } from "App.context/App.context";
import axios from "axios";
import { TeamContext } from "context/contextapi";
import { flatten, useGetValue } from "hooks/useGetValue";
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { NewInboxSocketTeamContext } from "socket/newinboxcontext";
import { SocketTeamContext } from "socket/socketcontext";
import AsignItemContext from "./assignee/AssignItem";
import ArchivedAllContext from "./DropdownArchivedAll";
import DropdownFilter from "./DropdownFilter";
import useFilterState from "../state";
import { isBoolean, isEmpty, size, uniqBy } from "lodash";
import useDebounce from "hooks/useDebounce";
import { Empty } from "antd";
import useSWRImmutable from "swr/immutable";

interface isContext {
  setDataScreenActivityValue?: any;
  DataScreenAcTivityvalue?: any;
  keyFilter: any;
  setKeyFilter: any;
  type: any;
  setType: any;
  isInboxLoading: any;
  setIsInboxLoading: any;
  dataScreenActivity: any;
  setDataScreenActivity: any;
  dataScreenArchive: any;
  setDataScreenArchive: any;
  disable: boolean | undefined;
  setDisable: any;
  showSideBarInbox: any;
  dataStarred?: any;
  setDataStarred?: any;
  resultStar: any;
  setResultStar: any;
  isNewInbox: boolean | undefined;
  socketCmtId: number | undefined;
}

interface isProp {
  dataScreenActivity: any;
  setDataScreenActivity: any;
  dataScreenArchive: any;
  setDataScreenArchive: any;
  dataStarred?: any;
  setDataStarred?: any;
}

const ActivityContext: React.FC<isProp> = ({
  dataScreenActivity,
  setDataScreenActivity,
  dataScreenArchive,
  setDataScreenArchive,
  dataStarred,
  setDataStarred,
}) => {
  const values = TeamContext();
  const collapse = useAppContext();
  const valueSocket = SocketTeamContext();

  const valueNewInboxSocket = NewInboxSocketTeamContext();

  return (
    <Activity
      keyFilter={values?.keyFilter}
      setKeyFilter={values?.setKeyFilter}
      type={values?.type}
      setType={values?.setType}
      isInboxLoading={values?.isInboxLoading}
      setIsInboxLoading={values?.setIsInboxLoading}
      dataScreenActivity={dataScreenActivity}
      setDataScreenActivity={setDataScreenActivity}
      dataScreenArchive={dataScreenArchive}
      setDataScreenArchive={setDataScreenArchive}
      disable={values?.disable}
      setDisable={values?.setDisable}
      showSideBarInbox={collapse?.showSideBarInbox}
      dataStarred={dataStarred}
      setDataStarred={setDataStarred}
      resultStar={values?.resultStar}
      setResultStar={values?.setResultStar}
      isNewInbox={valueNewInboxSocket?.isNewInbox}
      socketCmtId={valueNewInboxSocket?.socketCmtId}
    />
  );
};
const Activity: React.FC<isContext> = memo(
  ({
    setKeyFilter,
    type,
    setType,
    keyFilter,
    isInboxLoading,
    setIsInboxLoading,
    dataScreenActivity,
    setDataScreenActivity,
    dataScreenArchive,
    setDataScreenArchive,
    disable,
    setDisable,
    showSideBarInbox,
    dataStarred,
    setDataStarred,
    resultStar,
    setResultStar,
    isNewInbox,
    socketCmtId,
  }) => {
    const getUserId = localStorage.getItem("userId");
    const { isFilterByFromPerson, selectedUserId } = useFilterState();

    const [page, setPage] = useState(1);
    const [selected, setSelected] = useState("");
    const [noMore, setNoMore] = useState(false);
    const [checkClickItem, setCheckClickItem] = useState(false);

    const PAGE_SIZE = 10;

    const getUrl = useCallback(
      (mode: string | boolean, page: number, userId: number | null) => {
        if (isBoolean(mode) && userId) {
          return `resourcesfilterlastesmention/dataOnlyMention?join=resourcecmt&filter=resourcecmt.createdby||$eq||${userId}&filter=userId||$eq||1 &join=resourcecmt.resourcesLikes&filter=isArchived||$eq||0&join=resourcecmt.resourcesDetails&join=resourcecmt.resourcecm&page=${page}&limit=${PAGE_SIZE}&sort=resourcecmt.createdAt,DESC&join=resourceDetail&join=taskname&join=taskname.resourcestasks&join=taskname.resourcestasks.projectname&join=taskname.resourceStarredMention`;
        } else if (mode === "All") {
          return `resources/dataActivity?join=resourcecm&join=resourceRelation&join=resourceRelation.resourcesParent&join=resourceRelation.resourcesParent.resourcesDetails&join=resourcesmentions&filter=resourcesmentions.isArchived||$eq||0&filter=resourcesmentions.memberId||$eq||1&join=resourcesDetails&join=resourcesLikes&join=resourceRelation.resourcesParent.resourcestasks&join=resourceRelation.resourcesParent.resourcestasks.projectname&page=${page}&limit=${PAGE_SIZE}&sort=createdAt,DESC&join=resourceRelation.resourcesParent.resourcesDetails.resourcesstarred`;
        } else {
          return `resourcesfilterlastesmention/dataOnlyMention?join=resourcecmt&filter=userId||$eq||1&join=resourcecmt.resourcesLikes&filter=isArchived||$eq||0&join=resourcecmt.resourcesDetails&join=resourcecmt.resourcecm&page=${page}&limit=${PAGE_SIZE}&sort=resourcecmt.createdAt,DESC&join=resourceDetail&join=taskname&join=taskname.resourcestasks&join=taskname.resourcestasks.projectname&join=taskname.resourceStarredMention`;
        }
      },
      []
    );

    // Wait for reset data when change filter condition
    const jsonData = useDebounce(
      JSON.stringify({ keyFilter, isFilterByFromPerson, page, selectedUserId }),
      200
    );

    const fetcher = useCallback(
      async ([jsonData]: any) => {
        const { keyFilter, isFilterByFromPerson, page, selectedUserId } =
          JSON.parse(jsonData);
        // setIsDataLoading(true);
        const url = getUrl(
          isFilterByFromPerson ? isFilterByFromPerson : keyFilter,
          page,
          selectedUserId
        );

        const res = await axios.get(url);

        if (size(res.data.data) < PAGE_SIZE) {
          setNoMore(true);
        }

        // setIsDataLoading(false);

        return res.data.data ? res.data.data : [];
      },
      [getUrl]
    );

    const { data } = useSWRImmutable([jsonData], fetcher);

    const getDataScreenActivity = async () => {
      if (size(data)) {
        setDataScreenActivity((prev: any) => {
          const newData = uniqBy([...prev, ...data], "id");
          return newData;
        });
      }
    };

    useEffect(() => {
      getDataScreenActivity();
    }, [data]);

    const resetFilterConditions = useCallback(() => {
      setDataScreenActivity([]);
      setNoMore(false);
      setPage(1);
    }, []);

    // detect new comment mention by socket data
    useEffect(() => {
      const getSocketData = async () => {
        const res = await axios.get(
          `resourcesfilterlastesmention/dataOnlyMention?join=resourcecmt&filter=userId||$eq||1&join=resourcecmt.resourcesLikes&filter=isArchived||$eq||0&join=resourcecmt.resourcesDetails&join=resourcecmt.resourcecm&page=1&limit=1&sort=resourcecmt.createdAt,DESC&join=resourceDetail&join=taskname&join=taskname.resourcestasks&join=taskname.resourcestasks.projectname&join=taskname.resourceStarredMention`
        );
        const newMention = res?.data?.data[0];

        if(newMention) {
          setDataScreenActivity((prev: any) => {
            return uniqBy([newMention, ...prev], "id");
          });
        }

      };
      // only active when filter option is not from person
      if (socketCmtId && !isFilterByFromPerson && !selectedUserId) {
        getSocketData();
      }
    }, [socketCmtId, isFilterByFromPerson, selectedUserId]);

    const observe = useRef<IntersectionObserver | null>(null);

    const lastElement = useCallback(
      (node: HTMLElement) => {
        if (observe.current) observe.current?.disconnect();
        observe.current = new IntersectionObserver((entries) => {
          if (entries[0].isIntersecting && !noMore) {
            setPage(page + 1);
          }
        });

        if (node) {
          observe.current?.observe(node);
        }
      },
      [dataScreenActivity]
    );

    const customType = useMemo(() => {
      if (isFilterByFromPerson && selectedUserId) {
        return "Mention";
      }
      return type;
    }, [isFilterByFromPerson, selectedUserId, type]);

    return (
      <div
        className="archive-all"
        style={{ width: `${showSideBarInbox ? "52%" : "45%"}` }}
      >
        <div className="archive-all-notification" style={{ display: "flex" }}>
          <ArchivedAllContext
            disable={disable}
            dataScreenActivity={dataScreenActivity}
            setDataScreenActivity={setDataScreenActivity}
            dataScreenArchive={dataScreenArchive}
            setDataScreenArchive={setDataScreenArchive}
          />
          <DropdownFilter
            setKeyFilter={setKeyFilter}
            setType={setType}
            setDisable={setDisable}
            type={type}
            resetFilterConditions={resetFilterConditions}
          />
        </div>
        <div
          id="asign-list"
          style={{ height: "82vh", width: "100%", overflow: "auto" }}
        >
          {isEmpty(dataScreenActivity) ? (
            <div
              style={{
                display: "flex",
                height: "100%",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Empty />
            </div>
          ) : (
            dataScreenActivity?.map((item: any, index: any) => {
              return (
                <div
                  className="active-item-ibox"
                  key={item?.id}
                  style={{
                    background: `${item?.id === selected ? "#eee" : ""}`,
                  }}
                  onClick={() => {
                    setCheckClickItem(true);
                    setSelected(item?.id);
                  }}
                >
                  <AsignItemContext
                    comment={flatten(item)}
                    index={index}
                    type={customType}
                    getUserId={getUserId}
                    lastElement={
                      size(dataScreenActivity) === index + 1
                        ? lastElement
                        : null
                    }
                    dataScreenActivity={dataScreenActivity}
                    setDataScreenActivity={setDataScreenActivity}
                    dataScreenArchive={dataScreenArchive}
                    setDataScreenArchive={setDataScreenArchive}
                    setPage={setPage}
                    getIdStarred={() => {}}
                    commentId={item?.commentId}
                    checkClickItem={checkClickItem}
                  />
                </div>
              );
            })
          )}
        </div>
      </div>
    );
  }
);
export default ActivityContext;

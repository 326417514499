import { useRouterContext } from '@pankod/refine-core'
import { useAppContext } from 'App.context/App.context'
import axios from 'axios'
import { ALLTASK, INCOMPLETE, RECENTLYTASK } from 'components/Mytask/Constant'
import React, { memo, useCallback, useEffect, useRef } from 'react'
import { useMyTask } from '../../Context/MyTask.context'
import { SectionRowWrapperProps } from '../../Type/MyTaskType'
import SectionRowHOC from '../SectionRow/SectionRow'
import { SectionRowAddTask } from '../SectionRowAddTask/SectionRowAddTask'
import './SectionRowWrapper.scss'
import useMyTaskStore from 'pages/myTask/state/store'
import { Droppable } from 'react-beautiful-dnd'
import useDragDropStore from 'pages/myTask/state/DragDropStore'
import { ActionType, NewInsertTaskSocketTeamContext } from 'socket/newinserttaskcontext'
import { USER_ID } from 'authProvider'
import { SocketAssigneeConText } from 'socket/socket-asssign-context'

interface SectionRowWrapperSubProps {
    sectionId: any
    tasks: any
    setTasks: any
    getRequest: any
    setGetRequest: any
    page: number
    setPage: React.Dispatch<React.SetStateAction<number>>
    hasMore: any
    setHasMore: any
    inputRef: any
    setNewAddTask: any
    myTaskSetGetReq: any
    taskRef: any
    displayStatus: any
    params: any
    location: any
    currentSectionId: any
    socketNewTaskId: any
    nameAction: any
    appendTasks: any
    appendTaskToSection: any
    data: any
    ssIndex?: number
    removeTaskToSection?:any
    setCurrentTaskDetailTitle?:any
    resourceDetails?:any
    setResourceDetails?:any
    selectedTaskId?:any
    setProjectAndSection?:any
}

export const SectionRowWrapperHOC: React.FC<SectionRowWrapperProps> = ({
    sectionId,
    tasks,
    setTasks,
    getRequest,
    setGetRequest,
    page,
    setPage,
    hasMore,
    setHasMore,
    inputRef,
    setNewAddTask,
    myTaskSetGetReq,
    ssIndex
}) => {
    const values = useMyTask()
    const setCurrentTaskDetailTitle = values?.setCurrentTaskDetailTitle;
    const resourceDetails = values?.resourceDetails
    const setResourceDetails = values?.setResourceDetails
    const selectedTaskId = values?.selectedTaskId;
    const setProjectAndSection = values?.setProjectAndSection
    
    const taskRef = values?.taskRef
    const AppContext = useAppContext()
    const displayStatus = AppContext?.taskDisplayStatus
    const { useLocation, useParams } = useRouterContext()
    const params = useParams()
    const location = useLocation()
    const newInsertTaskSocketTeamContext = NewInsertTaskSocketTeamContext()
    const { appendTasks, data, appendTaskToSection,removeTaskToSection } = useMyTaskStore()
    const currentSectionId = newInsertTaskSocketTeamContext?.currentSectionId
    const socketNewTaskId = newInsertTaskSocketTeamContext?.socketNewTaskId
    const nameAction = newInsertTaskSocketTeamContext?.nameAction
    return (
        <SectionRowWrapper
            sectionId={sectionId}
            tasks={tasks}
            setTasks={setTasks}
            getRequest={getRequest}
            setGetRequest={setGetRequest}
            page={page}
            setPage={setPage}
            hasMore={hasMore}
            setHasMore={setHasMore}
            inputRef={inputRef}
            setNewAddTask={setNewAddTask}
            myTaskSetGetReq={myTaskSetGetReq}
            taskRef={taskRef}
            displayStatus={displayStatus}
            params={params}
            location={location}
            currentSectionId={currentSectionId}
            socketNewTaskId={socketNewTaskId}
            nameAction={nameAction}
            appendTasks={appendTasks}
            appendTaskToSection={appendTaskToSection}
            data={data}
            ssIndex={ssIndex}
            removeTaskToSection={removeTaskToSection}
            setCurrentTaskDetailTitle={setCurrentTaskDetailTitle}
            resourceDetails={resourceDetails}
            setResourceDetails={setResourceDetails}
            selectedTaskId={selectedTaskId}
            setProjectAndSection={setProjectAndSection}
        />
    )
}

const SectionRowWrapper: React.FC<SectionRowWrapperSubProps> =
    // const SectionRowWrapper: React.FC<SectionRowWrapperProps> =
    ({
        sectionId,
        tasks,
        setTasks,
        getRequest,
        setGetRequest,
        page,
        setPage,
        hasMore,
        setHasMore,
        inputRef,
        setNewAddTask,
        myTaskSetGetReq,
        taskRef,
        displayStatus,
        params,
        location,
        currentSectionId,
        socketNewTaskId,
        nameAction,
        appendTasks,
        appendTaskToSection,
        data,
        ssIndex,
        removeTaskToSection,
        setCurrentTaskDetailTitle,
        resourceDetails,
        setResourceDetails,
        selectedTaskId,
        setProjectAndSection,
    }) => {
        //Variable declare
        // const values = useMyTask()
        // const AppContext = useAppContext()
        const newInsertTaskSocketTeamContext = NewInsertTaskSocketTeamContext()
        // const displayStatus = AppContext?.taskDisplayStatus
        // const taskRef = values?.taskRef
        const userId = localStorage.getItem(USER_ID)
        // const [page, setPage] = useState(1);
        // const [callApiAgain, setCallApiAgain] = useState(false)
        // const { useLocation, useParams } = useRouterContext();
        // const params = useParams();
        // const location = useLocation();

        let { search, pathname } = location
        const regex = /[?&]projectid=(\d+)/
        const match = regex.exec(search)
        const projectId2 = match && match[1]

        const valueSocketAssignee = SocketAssigneeConText()
        const changeAssigneeSocket = valueSocketAssignee?.changeAssigneeSocket

        useEffect(() => {
            if (currentSectionId && !getRequest) {
                const getComment = async () => {
                    const res = await axios.get(
                        `resourcesrelation?filter=resourcesparentid%7C%7C%24eq%7C%7C${currentSectionId}&filter=resources.deletedAt%7C%7C%24isnull&filter=resources.id%7C%7C%24notnull&filter=resourceDetails.completed%7C%7C%24notnull&fields=resourcesid%2Corder&join=resources%7C%7Cname%2Ccreatedby%2CcreatedAt&join=resourceDetails%7C%7CdueOn%2Cassignee%2Ccompleted%2CcompletedAt&join=resources.resourcestasks%7C%7CresourcesId%2CsectionId&limit=15&page=${page}&offset=0&sort=order%2CDESC`
                    )

                    if (currentSectionId) {
                        if (userId !== newInsertTaskSocketTeamContext?.createById?.toString()) {
                            if (res.data?.data.length <= 0) {
                                const response = await axios.get(
                                    `resourcesrelation?filter=resourcesparentid%7C%7C%24eq%7C%7C${currentSectionId}&filter=resources.deletedAt%7C%7C%24isnull&filter=resources.id%7C%7C%24notnull&filter=resourceDetails.completed%7C%7C%24notnull&fields=resourcesid%2Corder&join=resources%7C%7Cname%2Ccreatedby%2CcreatedAt&join=resourceDetails%7C%7CdueOn%2Cassignee%2Ccompleted%2CcompletedAt&join=resources.resourcestasks%7C%7CresourcesId%2CsectionId&limit=15&page=${
                                        page - 1
                                    }&offset=0&sort=order%2CDESC`
                                )
                                if (
                                    response.data?.data[response.data?.data.length - 1]?.resourcesid !==
                                        data[currentSectionId]?.tasks[data[currentSectionId]?.tasks.length - 1]
                                            ?.resourcesid &&
                                    nameAction === ActionType?.Insert
                                ) {
                                    appendTasks([response.data?.data[response.data?.data.length - 1]], currentSectionId)
                                }
                            } else {
                                if (
                                    res.data?.data[res.data?.data.length - 1]?.resourcesid !==
                                        data[currentSectionId]?.tasks[data[currentSectionId]?.tasks.length - 1]
                                            ?.resourcesid &&
                                    nameAction === ActionType?.Insert
                                ) {
                                    appendTasks([res.data?.data[res.data?.data?.length - 1]], currentSectionId)
                                }
                            }
                        }
                    }
                }
                getComment()
            }
        }, [currentSectionId, socketNewTaskId])

        //Infiniti Load area
        const observe = useRef<IntersectionObserver | null>(null)

        const lastElement = useCallback(
            (node: HTMLElement) => {
                if (observe.current) observe.current?.disconnect()

                observe.current = new IntersectionObserver((entries) => {
                    if (entries[0].isIntersecting && hasMore) {
                        setPage((prev) => prev + 1)
                        setGetRequest(true)
                    }
                })

                if (node) {
                    observe.current?.observe(node)
                }
            },
            [hasMore]
        )

        useEffect(() => {
            data[sectionId]?.tasks?.length > 0 && setGetRequest(false)
        }, [data[sectionId].tasks])

        const getComment = async () => {
            const res = await axios.get(
                `resourcesrelation?filter=resourcesparentid%7C%7C%24eq%7C%7C${sectionId}&filter=resources.deletedAt%7C%7C%24isnull&filter=resources.id%7C%7C%24notnull&filter=resourceDetails.completed%7C%7C%24${
                    displayStatus === ALLTASK ? 'notnull' : 'eq'
                }%7C%7C${
                    displayStatus === ALLTASK ? '' : displayStatus === INCOMPLETE ? false : true
                }&fields=resourcesid%2Corder&join=resources%7C%7Cname%2Ccreatedby%2CcreatedAt&join=resourceDetails%7C%7CdueOn%2Cassignee%2Ccompleted%2CcompletedAt&join=resources.resourcestasks%7C%7CresourcesId%2CsectionId&limit=15&page=${page}&offset=0&sort=order%2CDESC`
            )

            const dataTask = await res.data.data
            const result = compareArrays(dataTask, data[sectionId]?.tasks)

            if (dataTask.length !== 0 && !result) {
                appendTasks(dataTask, sectionId)
                setGetRequest(false)
                if (dataTask?.length === 15) {
                    setHasMore(true)
                }
            }
        }

        useEffect(() => {
            if (sectionId) {
                getComment()
            }
        }, [sectionId, page])

        const compareArrays = (a: any[], b: any[]) => {
            if (a?.length !== b?.length) {
                return false
            }

            const aIds = a.map((obj) => obj?.id)
            const bIds = b.map((obj) => obj?.id)

            return aIds.every((id) => bIds.includes(id))
        }

        const fectchFirstDataBySectionId = async () => {
            if (changeAssigneeSocket?.sectionId == sectionId) {
                // add assignee socket
                if(changeAssigneeSocket.type == 'add') {
                    const res = await axios.get(
                        `resourcesrelation?filter=resourcesparentid||$eq||${sectionId}&filter=resources.deletedAt||$isnull&filter=resources.id||$notnull&filter=resourceDetails.completed||$${
                            displayStatus === ALLTASK ? 'notnull' : 'eq'
                        }||${
                            displayStatus === ALLTASK ? '' : displayStatus === INCOMPLETE ? false : true
                        }&fields=resourcesid,order&join=resources||name,createdby,createdAt&join=resourceDetails||dueOn,assignee,completed,completedAt&join=resources.resourcestasks||resourcesId,sectionId&limit=1&sort=order,DESC`
                    )
                    const dataTask = res.data
                    appendTaskToSection(dataTask[0], changeAssigneeSocket?.sectionId)

                
                    // Khi resource id focus trùng với resource id của task assignee thì update lại state khi đổi các giá trị rồi sau đó mới assingee
                    
                    if(selectedTaskId == dataTask[0]?.resourcesid) {
                        // set giá trị input
                        setCurrentTaskDetailTitle(dataTask[0]?.resources?.name)

                        // set duedate
                        setResourceDetails([{
                            ...resourceDetails,
                            ...dataTask[0]?.resourceDetails
                        }])

                        // set projects
                        setProjectAndSection(dataTask[0]?.resources?.resourcestasks)                 
                    }
                    
                }
                //remove assignee socket
                else {
                    removeTaskToSection(changeAssigneeSocket?.resourceId,changeAssigneeSocket?.sectionId)      
                }
            }
        }
        useEffect(() => {
            if (changeAssigneeSocket) {
                fectchFirstDataBySectionId()
            }
        }, [changeAssigneeSocket])

        const tasksToDisplay = data[sectionId].tasks

        const { isDisableTaskDrop } = useDragDropStore()

        const getDisplayStatus = useCallback(() => {
            if (displayStatus === RECENTLYTASK) return ssIndex === 0
            return true
        }, [displayStatus, ssIndex])

        return (
            <>
                {getDisplayStatus() && (
                    <Droppable droppableId={`${sectionId}`} isDropDisabled={isDisableTaskDrop}>
                        {(provided) => (
                            <div {...provided.droppableProps} ref={provided.innerRef} style={{ minHeight: '5px' }}>
                                {tasksToDisplay &&
                                    tasksToDisplay.map((task: any, index: any) => {
                                        if (task?.isNew) {
                                            return (
                                                <SectionRowAddTask
                                                    setNewAddTask={setNewAddTask}
                                                    inputRef={inputRef}
                                                    key={task?.key}
                                                />
                                            )
                                        }

                                        return (
                                            <SectionRowHOC
                                                key={task?.id}
                                                ssId={task?.sectionId ? task?.sectionId : sectionId}
                                                resources={task?.resources}
                                                lastElement={
                                                    data[sectionId]?.tasks?.length === index + 1
                                                        ? lastElement
                                                        : undefined
                                                }
                                                taskRef={taskRef}
                                                setTasks={setTasks}
                                                resourceDetails={task?.resourceDetails}
                                                resourceTask={task?.resources?.resourcestasks}
                                                hide={task?.hide}
                                                index={index}
                                                ssIndex={ssIndex}
                                            />
                                        )
                                    })}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                )}
            </>
        )
    }

export default memo(SectionRowWrapper)

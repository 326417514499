import './Policy.scss'
import Logo from '../../Assets/Image/logo.png'
export const Policy: React.FC = () => {

    return (
  <div className='rd-container'>
    <div className="rd-mb-header">
      <img src={Logo} />
      <h2>Family Hospital Work Space</h2>
    </div>

    <div id="privacy_content">
      <strong>Privacy Policy</strong> 
      <p>
                  Family Hospital built the FamilyHospital Workspace app as
                  a Free app. This SERVICE is provided by
                  Family Hospital at no cost and is intended for use as
                  is.
                </p> <p>
                  This page is used to inform visitors regarding our
                  policies with the collection, use, and disclosure of Personal
                  Information if anyone decided to use our Service.
                </p> <p>
                  If you choose to use our Service, then you agree to
                  the collection and use of information in relation to this
                  policy. The Personal Information that we collect is
                  used for providing and improving the Service. We will not use or share your information with
                  anyone except as described in this Privacy Policy.
                </p> <p>
                  The terms used in this Privacy Policy have the same meanings
                  as in our Terms and Conditions, which are accessible at
                  FamilyHospital Workspace unless otherwise defined in this Privacy Policy.
                </p> <p><strong>Information Collection and Use</strong></p> <p>
                  For a better experience, while using our Service, we
                  may require you to provide us with certain personally
                  identifiable information. The information that
                  we request will be retained by us and used as described in this privacy policy.
                </p>  <p><strong>Log Data</strong></p> <p>
                  We want to inform you that whenever you
                  use our Service, in a case of an error in the app
                  we collect data and information (through third-party
                  products) on your phone called Log Data. This Log Data may
                  include information such as your device Internet Protocol
                  (“IP”) address, device name, operating system version, the
                  configuration of the app when utilizing our Service,
                  the time and date of your use of the Service, and other
                  statistics.
                </p> </div>
  </div>
  )
}


import { useList } from "@pankod/refine-core";
import React, { createContext, useContext, useState, useEffect } from "react";

type PropsComment = {
  children: React.ReactNode;
};
type Context = {
  dataResultcomment:any,
  setdataResultcomment:any,
};
const ContextComment = createContext<Context | null>(null);

function ContextCommentProvider({ children }: PropsComment) {
  const [dataResultcomment,setdataResultcomment]=useState([])
  return (
    <ContextComment.Provider
      value={{
        dataResultcomment,setdataResultcomment
      }}
    >
      {children}
    </ContextComment.Provider>
  );
}
const CommentContext = () => useContext(ContextComment);
export { CommentContext, ContextCommentProvider };

import { MyTaskProvider } from 'components/Mytask/Context/MyTask.context'
import { MyTask, MyTaskHOC } from '../../components/Mytask/MyTask'

export const MyTaskPage: React.FC = () => {
  
  return (
    <MyTaskProvider>
          <MyTaskHOC />
    </MyTaskProvider>
  )
}


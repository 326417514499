import { message, Tooltip } from "@pankod/refine-antd";
import {
  BaseRecord,
  useNavigation,
  useRouterContext,
} from "@pankod/refine-core";
import MarkComplete from "components/Mytask/mtSession/SectionRow/MarkComplete/MarkComplete";
import React, { useEffect, useState } from "react";
import { BiArrowToRight } from "react-icons/bi";
import { IoMdClose } from "react-icons/io";
import { headerItems } from "./Data";
import "./DetailsHeader.scss";
import ProjectMentiontask from "pages/Teams/Inputmension/ProjectMentiontask";
import { useDetectProposePage, useProjectId, useSelectTask } from "pages/ProposeTasks/hooks";
import ProposeMarkComplete from "pages/ProposeTasks/components/MarkComplete";
import { useProposeTaskDetail } from "pages/ProposeTasks/state/details";
import { useMyTask } from "components/Mytask/Context/MyTask.context";
import { useProposeState } from "pages/ProposeTasks/state";
import { extractValuesFromURL } from "pages/ProposeTasks/services";

interface DetailsHeaderProps {
  type?: string;
}
export const ProposeDetailsHeader: React.FC<DetailsHeaderProps> = ({
  type,
}) => {
  const values = useMyTask();
  const { task } = useProposeTaskDetail();
const {projectId} = useProposeState()

  const sectionId = task?.sectionId as number;
  const proposeTaskId = task?.taskId as number;
  const activateTask = useSelectTask(sectionId, proposeTaskId);

  const handleClose = () => {
    values?.setShowMyTaskDetails(false);
    values?.setCurrentTaskDetailId(null);
    values?.setCurrentTaskDetailTitle(undefined);
    values?.setSubTaskDetailsMode(false);
    values?.setTaskDetailsQueue([]);

    replace(`/propose-task/show/${projectId}`)
  };

  const {  replace } = useNavigation();

  const handleIconCLick = (item: any) => {
    if (item.type === "link") {
      handleCopyLink();
    } else if (item.type === "expand") {
      goFullScreenTask();
    }
  };

   
  

  const handleCopyLink = () => {
    navigator.clipboard.writeText(
      `${window.location.origin}/task/show/${proposeTaskId}?projectid=${projectId}&fullscreen=true&propose=true&sectionId=${sectionId}`
    );
    message.success("Copied link!");
  };

  const goFullScreenTask = () => { 
    window.open( `${window.location.origin}/task/show/${proposeTaskId}?projectid=${projectId}&fullscreen=true&propose=true&sectionId=${sectionId}`)
  };

  const goHome = () => {
    
  };





  const isShowExpandIcon = (item: any) => {
    const result =
      type === "singledetails" && item.type === "expand"
        ? { display: "none" }
        : { display: "" };
    return result;
  };

  return (
    <div className="dt-header-container ">
      <div className="dt-header-main ">
        <div
          className={
            "main-complete-btn " + (values?.changeTitle ? "slide" : "")
          }
        >
          {activateTask && <ProposeMarkComplete type="button" task={activateTask} />}
        </div>

        <div className={"main-title " + (values?.changeTitle ? "slide2" : "")}>
          {activateTask && <ProposeMarkComplete task={activateTask} />}

          <h3>
            {task?.name}
          </h3>
        </div>
      </div>

      <div className="dt-header-item">
        {task?.taskId && <ProjectMentiontask taskId={task?.taskId} />}

        {headerItems.map((item, index) => {
          const Icon = item.icon;
          return (
            <Tooltip
              key={index}
              title={item.desc ? item.desc : ""}
              placement="bottom"
            >
              <Icon
                style={isShowExpandIcon(item)}
                className="item-icon"
                onClick={() => handleIconCLick(item)}
              />
            </Tooltip>
          );
        })}
        
        {type === "singledetails" ? (
          <IoMdClose className="item-icon" onClick={goHome} />
        ) : (
          values?.hideArrowToRightScreenDetail === "" && (
            <BiArrowToRight className="item-icon" onClick={handleClose} />
          )
        )}
      </div>
    </div>
  );
};

import create from 'zustand'

export type RecentlyTaskType = {
    taskId?: number
    sectionId?: number
    completed?:boolean
    dueOn: string | null
    assigneeId?: number
    assigneeName?: string
    name?: string
    completedAt?: Date
    createdAt?: Date
    id:number
}

interface RecentlyIF {
    tasks: any
    appendTasks: (tasks:any) => void
    removeTasks: (tasks:any) => void
    updateValueTask: (taskId:number, value:{}) => void
    appendTaskAssignee: (tasks:any) => void
}
 
export const useRecentlyState = create<RecentlyIF>((set) => ({
    tasks:[],
    appendTasks: (newTasks: []) => set((state):any => {
        return {
            ...state,
            tasks: [...state.tasks, ...newTasks]
        }
    }),

    removeTasks: (newTasks: []) => set((state):any => {
        return {
            ...state,
            tasks: [...newTasks]
        }
    }),

    updateValueTask: (taskId:number, value:any) => set((state):any => {
        const data = state.tasks.map((item:any) => {
            if(item.taskId == taskId) {
                return {...item,...value}
            }
            return item
        })

        return {
            ...state,
            tasks: data
        }
    }),

    appendTaskAssignee: (taskAssignee: any) => set((state):any => {
        const isDataExist = state?.tasks?.find((item:any) => item.taskId == taskAssignee.taskId)
        if(isDataExist) {
            return {
                ...state,
            }
            
        }
        return {
            ...state,
            tasks: [...[taskAssignee],...state.tasks],
        }
    }),

    resetTasks: () => set({ tasks: [] }),
}))
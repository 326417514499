import { BaseRecord } from "@pankod/refine-core";
import { File } from "../../DetailsFooter/FooterTextEdit/File/File";
import { FileListItem } from "./FileListItem/FileListItem";
import "./FilesList.scss";
import { useMemo } from "react";
import { size } from "lodash";
import { Image } from "@pankod/refine-antd";
import privateImg from "../../../../../Assets/Image/privateImg.png";

interface FileListProps {
  FileList: BaseRecord[];
  setFileList?: React.Dispatch<React.SetStateAction<BaseRecord[]>>;
}
export const FilesList: React.FC<FileListProps> = ({
  FileList,
  setFileList,
}) => {
  const CheckImg = (fileType: string) => {
    return (
      fileType === "png" ||
      fileType === "jpeg" ||
      fileType === "jpg" ||
      fileType === "pjpeg"
    );
  };

  const imgFiles = useMemo(() => {
    return FileList.filter((file) => {
      const fileType = file.resources.name.split(".").pop();
      return CheckImg(fileType);
    });
  }, [FileList]);

  const otherFiles = useMemo(() => {
    return FileList.filter((file) => {
      const fileType = file.resources.name.split(".").pop();
      return !CheckImg(fileType);
    });
  }, [FileList]);

  return (
    <div className="FileList-container">
      {size(imgFiles) ? (
        <Image.PreviewGroup>
          {imgFiles.map((img) => (
            <Image
              src={`${process.env.REACT_APP_API_URL || "http://localhost:7000/"}resources/getimagethumb/${img.resourcesid}`}
              height={54}
              width={54}
              style={{
                borderRadius: '10px'
              }}
            />
          ))}
        </Image.PreviewGroup>
      ) : (
        <></>
      )}

      {size(otherFiles) ? (
        otherFiles.map((file) => {
          return (
            <FileListItem
              createdAt={file.resources.createdAt}
              setFileList={setFileList}
              fileId={file.resourcesid}
              key={file.resourcesid}
              name={file.resources.name}
            />
          );
        })
      ) : (
        <></>
      )}
    </div>
  );
};

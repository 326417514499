import { useMyTask } from 'components/Mytask/Context/MyTask.context'
import React, { useEffect } from 'react'
import { MdNavigateNext } from 'react-icons/md'

interface QueueItemProps {
    name?: string
    taskId?: number
    index: number
    idParent: number
}


const QueueItem: React.FC<QueueItemProps> = ({name, taskId, index, idParent}) => {
    const values = useMyTask()
    const setTaskQueues = values?.setTaskDetailsQueue
   
    const changeTaskDetails = () => {

             //Sẽ set tên task hiển thị bên bảng detail bằng tên task mình vừa click 
             values?.setCurrentTaskDetailTitle(name);
  
             //Set biến CurrentTaskDetailId để biết detail đang hiển thị thông tin của task nào, đồng thời dùng id này để xác định thông tin task nào sẽ thay đổi khi detail thay đổi thông tin
             taskId && values?.setCurrentTaskDetailId(idParent);
            //  taskId && values?.setCurrentTaskDetailId(taskId);


           // Thay đổi url khi thay đổi subtask
            window.history.replaceState(
              {},
              "mytask/show",
              `/task/show/${idParent}?projectid=${values?.currentProjectId}`
            );


               setTaskQueues && setTaskQueues(prev => {
                const oldTaskQueues = [...prev,{name,taskId: idParent}]
                const newTaskQueues = oldTaskQueues.slice(-1)
                
                return newTaskQueues
             })        
    }
 


  return (
    <div className="queue-item" onClick={changeTaskDetails} >
        <span>{name}</span>
        {name ? <MdNavigateNext />: null}
    </div>
  )
}

export default QueueItem
import { useMyTask } from "components/Mytask/Context/MyTask.context";
import React, { memo } from "react";
import { BsChat } from "react-icons/bs";
import { MdNavigateNext } from "react-icons/md";
import "./GoSubTaskDetails.scss";

interface GoSubTaskDetailsProps {
  subTaskId: number
  subTaskName?: string
}
export const GoSubTaskDetails: React.FC<GoSubTaskDetailsProps> = memo(({subTaskId, subTaskName}) => {
  const values = useMyTask()
  
  const goSubTaskDetails = () => {
    const url = window.location.href;
        
        //Sẽ set tên task hiển thị bên bảng detail bằng tên task mình vừa click 
        values?.setCurrentTaskDetailTitle(subTaskName);
  
        //Set biến CurrentTaskDetailId để biết detail đang hiển thị thông tin của task nào, đồng thời dùng id này để xác định thông tin task nào sẽ thay đổi khi detail thay đổi thông tin
        values?.setCurrentTaskDetailId(subTaskId);

        // Chuyển details sang chế độ cho subtask
        values?.setSubTaskDetailsMode(true)

        //Thêm sub taskid vào hàng đợi task
        values?.setTaskDetailsQueue(prev => [...prev, {taskId: subTaskId, name: subTaskName}])
        if(url.includes('Inbox')){
          window.history.replaceState(
            {},
            "Inbox/show",
            `/Inbox/show/${subTaskId}?projectid=${values?.currentProjectId}`
          );
        }else{
          window.history.replaceState(
            {},
            "mytask/show",
            `/task/show/${subTaskId}?projectid=${values?.currentProjectId}`
          );
        }

        


  }
  return (
    <div className="chat-icon-wrapper" onClick={goSubTaskDetails}>
      <BsChat className="st-row-icon" />
      <MdNavigateNext className="st-row-icon" />
    </div>
  );
});

import { BaseRecord } from '@pankod/refine-core'
import create from 'zustand'
interface MyTaskState {
    data: BaseRecord,
    sectionIds: string[]
    appendSection: (sections: BaseRecord[]) => void
    reorderSectionIds:  (newSectionIds: string[]) => void
    addNewSection: (section: BaseRecord) => void
    appendTasks: (Tasks: BaseRecord[], sectionId: number) => void
    addNewTaskUp: ( sectionId: number) => void
    addNewTaskDown: ( sectionId: number) => void
    removeAddTask: ( sectionId: number) => void
    appendNewTasksUp: (tasks: BaseRecord, sectionId: number) => void
    appendNewTasksDown: (tasks: BaseRecord, sectionId: number) => void
    updateTaskIdAfterInsert: ( sectionId: number, taskAfterInsert: BaseRecord) => void
    deleteTask: ( taskId: number, sectionId: number) => void
    deleteSection: (  sectionId: number) => void
    updateTaskState: (sectionId: number, newTasks: BaseRecord[]) => void
    appendNewSectionIds: (id: string) => void
    updateSectionOrder: (updatedOrders: BaseRecord[]) => void
    appendTaskToSection: (Task: BaseRecord, sectionId: number) => void
    removeTaskToSection: (resourceId:number,sectionId: number) => void
    

}
const useMyTaskStore = create<MyTaskState>((set) => ({
    data: {},
    sectionIds: [],
    appendSection: (sections: BaseRecord[]) => set((state) => {

        const sectionObj = sections.reduce((tol, section) => {
             tol[section.resourcesid] = section
             return tol
        },{})

        state.data = sectionObj
        state.sectionIds = sections.map(_ => _.resourcesid)
        return {...state}
    }),
    reorderSectionIds: (newSectionIds: string[]) => set((state) => {
        state.sectionIds = newSectionIds
        return {...state}
    }),
    appendNewSectionIds: (id: string) => set((state) => {
        state.sectionIds = [...state.sectionIds, id]
        return {...state}

    }),
    addNewSection:  (section: BaseRecord) => set((state) => {
         state.data[section.resourcesid] = section

        
        return {...state}
    }),
    deleteSection: (sectionId: number) => set((state) => {
        delete state.data[sectionId]
        const newSectionIds = state.sectionIds.filter(_ => +_ !== sectionId)
       return {...state, sectionIds: newSectionIds}
   }),
    appendTasks: (Tasks, sectionId) => set((state) => {
        
        if(!state?.data[sectionId]?.tasks)  {
            state.data[sectionId].tasks = []
        }
       state.data[sectionId].tasks = [...state.data[sectionId].tasks, ...Tasks]
        return {...state}
    }),
    addNewTaskUp: (sectionId) => set((state) => {
        if(!state.data[sectionId].tasks)  {
            state.data[sectionId].tasks = []
        }
        state.data[sectionId].tasks = [{isNew: true, key: 'key'},...state.data[sectionId].tasks]
        return {...state}
    }),
    addNewTaskDown: (sectionId) => set((state) => {
        if(!state.data[sectionId].tasks)  {
            state.data[sectionId].tasks = []
        }
        
        state.data[sectionId].tasks = [...state.data[sectionId].tasks, {isNew: true, key: 'key'}]
        return {...state}
    }),
    removeAddTask: (sectionId) => set((state) => {
        if(!state?.data[sectionId]?.tasks)  {
            state.data[sectionId].tasks = []
        }
        const tasks = [...state.data[sectionId].tasks]
        const tasksOnly = tasks.filter(task => !task.isNew)

        state.data[sectionId].tasks = tasksOnly
        return {...state}
    }),
    appendNewTasksUp: (task, sectionId) => set((state) => {
        
        if(!state.data[sectionId].tasks)  {
            state.data[sectionId].tasks = []
        }
       state.data[sectionId].tasks = [task,...state.data[sectionId].tasks]
        return {...state}
    }),
    appendNewTasksDown: (task, sectionId) => set((state) => {
        
        if(!state.data[sectionId].tasks)  {
            state.data[sectionId].tasks = []
        }
       state.data[sectionId].tasks = [...state.data[sectionId].tasks, task]
        return {...state}
    }),
    updateTaskIdAfterInsert: ( sectionId, taskAfterInsert) => set((state) => {
        const currentTasks = [...state.data[sectionId].tasks]
        const newTasks = currentTasks.map((task: BaseRecord) => {
            if(taskAfterInsert?.id === task.id) {

                
                return {...taskAfterInsert, resourcesid: taskAfterInsert.resources.id}}
                
                return task
            })
            
          state.data[sectionId].tasks = [...newTasks]
        return {...state}
    }),
    deleteTask: ( taskId, sectionId) => set((state) => {
        const currentTasks = [...state.data[sectionId].tasks]
        const newTasks = currentTasks.filter((task: BaseRecord) => task.resources.id !== taskId )

        state.data[sectionId].tasks = [...newTasks]
        return {...state}
    }),
    updateTaskState: (sectionId, newTasks) => set((state) => {

        state.data[sectionId].tasks = [...newTasks]
        return {...state}
    }),
    updateSectionOrder: (updatedOrders) => set((state) => {
        updatedOrders.forEach(_ => {
            state.data[_.resourcesid].order = _.order
        })
        return {...state}
    }),

    appendTaskToSection: (Task: BaseRecord, sectionId: number) => set((state) => {
        const currentTasksInSection = state?.data[sectionId]?.tasks || []
        const isNewTaskExisted = currentTasksInSection?.find((task: any) => {
            return task.resourcesid === Task.resourcesid
        })
        if(isNewTaskExisted) {
            return {...state}
        } else {
            const newData = {...state.data}
            newData[sectionId] = {...newData[sectionId], tasks: [Task, ...newData[sectionId]?.tasks]}
            return {...state, data: newData}
        }
    }),

    removeTaskToSection: (resourceId:number,sectionId: number) => set((state) => {
        const currentTasksInSection = state?.data[sectionId]?.tasks || []
        const dataRemoveAssignee = currentTasksInSection.filter((item:any) => item?.resourcesid !== resourceId)

        state.data[sectionId].tasks = [...dataRemoveAssignee]
        return {
            ...state
        }
    }),
}))

export default useMyTaskStore
import { Tooltip } from "@pankod/refine-antd";
import { BaseRecord } from "@pankod/refine-core";
import { useAppContext } from "App.context/App.context";
import React, { useState, useEffect } from "react";
import "./FooterTextEditButton.scss";
import _ from "lodash";

interface FooterTextEditButton {
  handleComment: (e: React.MouseEvent) => void;
  type?: string;
  setShowCmtEditor?: React.Dispatch<React.SetStateAction<boolean>>;
  cmtContent?: string;
  handleUpdateComment: () => void;
  notifiedPeople?: BaseRecord[];
  isCmtLoading: boolean;
}

export const FooterTextEditButton: React.FC<FooterTextEditButton> = ({
  handleComment,
  notifiedPeople,
  handleUpdateComment,
  setShowCmtEditor,
  type,
  isCmtLoading,
}) => {
  const AppContext = useAppContext();
  const [followerStrList, setFollowerStrList] = useState<string | null>(null);
  const handleCancelEdit = () => {
    setShowCmtEditor && setShowCmtEditor(false);
  };
  const userData = AppContext?.usersData;

  useEffect(() => {
    let peoples: any;
    if (notifiedPeople && notifiedPeople?.length > 0 && userData) {
      peoples = notifiedPeople.map((_) => {
        const user = userData[_.followersId as keyof Object];
        if (user && user?.name) {
          return user.name;
        }
      });

      setFollowerStrList(peoples.join(" \n "));
    }
  }, [notifiedPeople]);

  const overlayStyle = {
    backgroundColor: "var(--tool-tip-bg-color)",
    whiteSpace: "pre-line",
    display: "flex",
    maxHeight: 200,
    overflowY: "auto",
  };

  const btnStyle = { backgroundColor: isCmtLoading ? "lightgray" : "" };

  return (
    <>
      <div className="ft-toolbar-submit">
        {!type && (
          <>
            <Tooltip
              title={followerStrList}
              overlayInnerStyle={overlayStyle as any}
            >
              <span>
                <span
                  style={{
                    color: "var(--btn-primary-color)",
                    fontWeight: "bold",
                    marginRight: "8px",
                  }}
                >
                  {notifiedPeople?.length}
                </span>
                peoples will be notified
              </span>
            </Tooltip>
            <button
              style={btnStyle}
              disabled={isCmtLoading ? true : false}
              onMouseDown={_.debounce(handleComment, 500, {
                leading: true,
                trailing: false,
              }
          )}
            >
              {isCmtLoading ? "Loading..." : "Comment"}
            </button>
          </>
        )}

        {type && (
          <>
            <button className="cancel-btn" onMouseDown={handleCancelEdit}>
              Cancel
            </button>
            <button onMouseDown={_.debounce(handleUpdateComment, 500, {
                leading: true,
                trailing: false,
              })}>
              Save changes
            </button>
          </>
        )}
      </div>
    </>
  );
};

import { USER_ID } from "authProvider";
import { MyTaskContextType, useMyTask } from "components/Mytask/Context/MyTask.context";
import React, { useCallback } from "react";
import { AiOutlineCheck } from "react-icons/ai";
import { BsCheckCircle, BsFillCheckCircleFill } from "react-icons/bs";

import { toast } from "react-toastify";
import { RecentlyTaskType, useRecentlyState } from "pages/Recently-completed-task/state";
import { upsertResourceDetailAsync } from "pages/ProposeTasks/api.ts";
import { toggleCompleteStatusAsync } from "../service";

interface MarkCompleteProps {
  type?: string;
  task: RecentlyTaskType;
}

const MarkCompletedRecently: React.FC<MarkCompleteProps> = ({ type, task }) => {
  const values = useMyTask();
  const {setComments} = values as MyTaskContextType
  const { updateValueTask } = useRecentlyState()

  const handleToggleCompleteStatus = useCallback(async () => {
    toggleCompleteStatusAsync(task,upsertResourceDetailAsync,USER_ID,updateValueTask,setComments)
  },[task])

  if (type) {
    return (
      <div
        className={"mark-complete-datails " + (task?.completed ? "completed" : "")}
      >
        <AiOutlineCheck onClick={handleToggleCompleteStatus} />
        {
          <span onClick={handleToggleCompleteStatus}>
            {task?.completed ? "Completed" : "Mark complete"}
          </span>
        }
      </div>
    );
  }

  return (
    <>
      {task?.completed ? (
        <BsFillCheckCircleFill
          className="row-title-check-icon"
          style={{ color: "#58a182" }}
          onClick={handleToggleCompleteStatus}
        />
      ) : (
        <BsCheckCircle
          className="row-title-check-icon"
          onClick={handleToggleCompleteStatus}
        />
      )}
    </>
  );
};

export default MarkCompletedRecently;

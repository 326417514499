

const useCheckIsMobile = () => {
  let details = navigator.userAgent;
  let regexp = /android|iphone|kindle|ipad/i;
  let isMobileDevice = regexp.test(details);
  
    return isMobileDevice
}

export default useCheckIsMobile
const itemTypes:any = {
  'All': {
      id: 'id',
      resourcecmId: 'resourcecm.id',
      resourcecmName: 'resourcecm.name',
      resourcesmentions: 'resourcesmentions.id',
      resourcestasks: 'resourceRelation.resourcesParent.resourcestasks',
      resourcesParentName: 'resourceRelation.resourcesParent.name',
      numLikes: 'resourcesDetails.numLikes',
      resourcesLikes: 'resourcesLikes',
      name: 'name',
      createdAt: 'createdAt',
      complete:"resourceRelation.resourcesParent.resourcesDetails.completed",
      parentId:"resourceRelation.resourcesparentid",
      resourceId:"resourceRelation.resourcesParent.id",
      parent:"id",
      isStarred:"resourceRelation.resourcesParent.resourcesDetails.resourcesstarred",
      taskId:"",
      memberId:"resourceRelation.resourcesParent.resourcesDetails.resourcesstarred.memberId"

  },
  'Mention': {
      parent:"id",
      id: 'resourcecmt.id',
      resourcecmId: 'resourcecmt.resourcecm.id',
      resourcecmName: 'resourcecmt.resourcecm.name',
      resourcesmentions: 'id',
      resourcestasks: 'taskname.resourcestasks',
      resourcesParentName: 'taskname.name',
      numLikes: 'resourcecmt.resourcesDetails.numLikes',
      resourcesLikes: 'resourcecmt.resourcesLikes',
      name: 'resourcecmt.name',
      createdAt: 'resourcecmt.createdAt',
      parentId:"taskname.id",
      resourceId:"taskname.id",
      complete:"resourceDetail.completed",
      isStarred:"taskname.resourceStarredMention",
      memberId:"resourceStarredMention.memberId"

  },
  'Starred': {
    parent:"id",
    id: 'comment.comment_id',
    resourcecmId: 'comment.created_by',
    resourcecmName: 'comment.created_by_name',
    resourcesmentions: 'id',
    resourcestasks: 'taskname.resourcestasks',
    resourcesParentName: 'taskname.name',
    numLikes: 'resourcecmt.resourcesDetails.numLikes',
    resourcesLikes: 'comment.likes',
    name: 'comment.comment',
    createdAt: 'comment.created_at',
    parentId:"taskname.id",
    resourceId:"taskId",
    complete:"taskname.complete.completed",
    isStarred:"id",
    
}
}

const traverseAndFlatten = (currentNode: any, target: any, flattenedKey: any) => {
  for (var key in currentNode) {
      if (currentNode.hasOwnProperty(key)) {
          var newKey
          if (flattenedKey === undefined) {
              newKey = key
          } else {
              newKey = flattenedKey + '.' + key
          }

          var value = currentNode[key]
          if (typeof value === 'object' && !Array.isArray(value)) {
              traverseAndFlatten(value, target, newKey)
          } else {
              target[newKey] = value
          }
      }
  }
}

const flatten = (obj: any) => {
  let flattenedObject = {}
  let flattenedKey
  traverseAndFlatten(obj, flattenedObject,flattenedKey)
  return flattenedObject
}

const useGetValue = (item: any, key: any, type: any) => {
  return item[itemTypes[type][key]]
}

export { useGetValue, flatten }

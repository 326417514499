import React, { useState } from "react";
import "./File.scss";
import {
  AttachmentFileIcon,
  ExcelFileIcon,
  PdfFileIcon,
  PowpointFileIcon,
  WordFileIcon,
} from "./Icons";
import { BsChevronDown, BsTrash } from "react-icons/bs";
import { FaAngleDown } from "react-icons/fa";
import { useMyTask } from "components/Mytask/Context/MyTask.context";
import { useMyTaskFooter } from "components/Mytask/Context/MyTask.footer.context";
import { Dropdown, Menu } from "@pankod/refine-antd";
import { AiOutlineCheckCircle } from "react-icons/ai";
import Editor from '@draft-js-plugins/editor'



interface FileProps {
  id: number
  name: string;
  type?: string;
  imgUrl?: string;
  setFiles?: React.Dispatch<React.SetStateAction<File[]>>
  editorRef?:React.RefObject<Editor>
  setShowTollBar?:  React.Dispatch<React.SetStateAction<boolean>>
}

export const File: React.FC<FileProps> = ({ name, imgUrl, setFiles, editorRef, id, setShowTollBar }) => {
  let type;
  let FileIcon = AttachmentFileIcon
  const fileType = name.split(".")[1];

  const footerContext = useMyTaskFooter()

  switch (fileType) {
    case "docx":
      type = "Word Document";
      FileIcon = WordFileIcon;
      break;

    case "pdf":
      type = "PDF";
      FileIcon = PdfFileIcon;

      break;

    case "xlsx":
      type = "Excel SpreadSheet";
      FileIcon = ExcelFileIcon;

      break;

    case "pptx":
      type = "Powpoint Slideshow";
      FileIcon = PowpointFileIcon;

      break;

      case "png":
        type = "Image";
        break;

      

    default:
      type = "Attachment";
      break;
  }

  const handleDeleteFile = () => {
    setFiles && setFiles(prev => {
      return prev.filter(file => file.lastModified !== id)
    })
    editorRef?.current?.blur()
  }

  const handleDeleteAllFiles = () => {
    setShowTollBar && setShowTollBar(false)
    setFiles && setFiles([])
    editorRef?.current?.blur()

  }

  const menu = (
    <Menu
      items={[
        {
          
          label: 'Delete attachment',
          key: '1',
          danger: true,
          onClick: handleDeleteFile,
          icon: <BsTrash />
        },
        {
          
          label: 'Delete all attachments',
          key: '2',
          danger: true,
          onClick: handleDeleteAllFiles,
          icon: <BsTrash />
        },
      
      ]}
    />
  );

  return (
    <>
    {!imgUrl && (
    <div className="file-container" onMouseDown={e => e.preventDefault()}>
      <div className="file-info">
      <div className="file-icon">
        <FileIcon />
      </div>

      <div className="file-content">
        <p>{name}</p>
        <span>{type} - Download</span>
      </div>

      <div>
          <Dropdown overlay={menu} trigger={['click']}>
            <div className="file-option" >
                <BsChevronDown />
            </div>
          </Dropdown >

      </div>

      </div>
      

      <div className="progress-bar">
        <div className="bar" style={{width: footerContext?.uploadedPercent + '%'}}></div>
      </div>
    </div>
    )} 

       {imgUrl && (
        <div className="img-thumnail-container"  onMouseDown={e => e.preventDefault()}>
          <img src={imgUrl} alt="" />
          <Dropdown overlay={menu} trigger={['click']}>
            <FaAngleDown className="image-option" />
          </Dropdown >
        </div>
       )}
    </>

  );
};

import { Dropdown, Menu } from "@pankod/refine-antd";


import { useRouterContext } from "@pankod/refine-core";

import { useNavigation } from "@pankod/refine-core";


import { useAppContext } from "App.context/App.context";
import { useEffect, useState } from "react";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { BiSort } from "react-icons/bi";
import { ALLTASK, COMPLETED, DELETETASK, INCOMPLETE, RECENTLYTASK } from "../Constant";
import "./MyTaskSortFeature.scss";
import { SocketAssigneeConText } from "socket/socket-asssign-context";

interface MyTaskSortFilterProps {
  myTaskSetGetReq: React.Dispatch<React.SetStateAction<boolean>>;

  resource: string;
  id: any



}
export const MyTaskSortFeature: React.FC<MyTaskSortFilterProps> = ({myTaskSetGetReq,resource, id}) => {
  const AppContext = useAppContext()

  const valueSocketAssignee = SocketAssigneeConText()
  const setChangeAssigneeSocket = valueSocketAssignee?.setChangeAssigneeSocket

  const [option, setOption] = useState(() => {
    if(resource === 'mytask') {
      return INCOMPLETE
    }

    return ALLTASK
  });
  



  const { replace, push } = useNavigation();
  const {useLocation} = useRouterContext()
  const location = useLocation()

  const handleSelectChange = (val: string) => {
    if(val === option) return
    setOption(val);
    AppContext?.setTaskDisplayStatus(val)
    AppContext?.setIsMyTaskLoading(true)
    myTaskSetGetReq(true)

    // khi chuyển trạng thái filter task không append task khi assignee
    setChangeAssigneeSocket({})
  };

  const handleDelete=(val: string)=>{
    setOption(val);
    push(location.pathname)
    AppContext?.setTaskDisplayStatus(val)
    AppContext?.setIsMyTaskLoading(true)
    replace(`/deletetask/show/${id}`)
  }
  const handleCompletedTask=(val: string)=>{
    setOption(val);
    push(location.pathname)
    replace(`/completedtask/show/${id}`)
  }
  const menu = (
    <Menu
      selectable
      items={[
        {
          label: "Incomplete tasks",
          key: "1",
          onClick: () => handleSelectChange(INCOMPLETE),
        },

        {
          label: "Completed tasks",
          key: "2",
          onClick: () => handleSelectChange( COMPLETED),
        },

        {
          label: "All tasks",
          key: "3",
          onClick: () => handleSelectChange(ALLTASK),
        },
        {
          label: "Delete tasks",
          key: "4",
          onClick: () => handleDelete(DELETETASK),
        },
        {
          label: "Recently completed tasks",
          key: "5",
          onClick: () => handleSelectChange(RECENTLYTASK),
        },
      ]}
    />
  );

  useEffect(()=>{
    if(AppContext?.taskDisplayStatus){
      setOption(AppContext?.taskDisplayStatus)
    }
  },[AppContext?.taskDisplayStatus])

  return (
    <div className="mytask-header-items">
      <Dropdown overlay={menu} trigger={["click"]}>
        <div className="mytask-header-item">
          <AiOutlineCheckCircle />
          <span>{option}</span>
        </div>
      </Dropdown>
      {/* <div className="mytask-header-item">
        <BiSort />
        <span>Sort</span>
      </div> */}
    </div>
  );
};

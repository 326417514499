import { DatePicker } from "@pankod/refine-antd";
import dayjs from "dayjs";
import React, { useCallback, useRef } from "react";
import { BsCalendar2Week } from "react-icons/bs";
import { USER_ID } from "authProvider";
import { MyTaskContextType, useMyTask } from "components/Mytask/Context/MyTask.context";
import { upsertResourceDetailAsync } from "pages/ProposeTasks/api.ts";
import { toast } from "react-toastify";
import { TaskType } from "pages/ProposeTasks/state";
import { useRecentlyState } from "../state";
import '../../ProposeTasks/components/DueDate/DueDate.scss'
import { format, startOfDay } from "date-fns";

interface DueDateProps {
  task: TaskType;
  placeholder?: string;
}

const DueDateRecently: React.FC<DueDateProps> = ({ task, placeholder }) => {
  const {setComments} = useMyTask() as MyTaskContextType;
  const {updateValueTask} = useRecentlyState()
  const { dueOn, taskId } = task ?? {};

  const ref = useRef<any>(null);
  const userId = localStorage.getItem(USER_ID);

  const handleDateChange = useCallback((val: any) => {
    if (val) {
      ref.current?.blur();
      const dateNoTime = format(startOfDay(val.$d), 'yyyy-MM-dd');

      upsertResourceDetailAsync(taskId, {
        dueAt: dateNoTime,
        dueOn: dateNoTime,
        type: "addduedate",
      })
        .then((res) => {
        
        
        updateValueTask(taskId, { dueOn: dateNoTime });
          toast.success("Cập nhật thành công");

          const newComment = {
            resourcesid: Math.random(),
            resources: {
              createdAt: new Date().toISOString(),
              createdby: userId,
              name: `{{${userId}}} changed the due date to ${dateNoTime}`,
              resourcetype: "log",
            },
          };

          setComments && setComments((prev) => [...prev, newComment]);
        })
        .catch((error) => {
          const message = error?.response?.data?.message;
          toast.error(message ?? "Có lỗi xảy ra !");
        });
    } else {

      upsertResourceDetailAsync(taskId, {
        dueAt: null,
        dueOn: null,
        type: "removeduedate",
      })
        .then((res) => {
          updateValueTask(taskId, { dueOn: null });
          toast.success('"Xóa Thành công !"');

          const newComment = {
            resourcesid: Math.random(),
            resources: {
              createdAt: new Date().toISOString(),
              createdby: userId,
              name: `{{${userId}}} removed the due date.`,
              resourcetype: "log",
            },
          };

          setComments && setComments((prev) => [...prev, newComment]);
        })
        .catch((error) => {
          const message = error?.response?.data?.message;
          toast.error(message ?? "Có lỗi xảy ra !");
        });
    }
  },[taskId,userId, upsertResourceDetailAsync, dueOn, ref ]);

  const dateFormat = "DD - MM - YYYY";

  return (
    <>
      <DatePicker
        placeholder={placeholder ? placeholder : ""}
        format={dateFormat}
        placement="bottomRight"
        className="datepicker"
        onChange={handleDateChange}
        value={dueOn ? dayjs(dueOn) : null}
        suffixIcon={<BsCalendar2Week />}
        ref={ref}
      />
    </>
  );
};

export default DueDateRecently
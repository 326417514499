import { Select } from "@pankod/refine-antd";
import ProjectItem from "../ProjectItem/ProjectItem";
import { useEffect, useState } from "react";
import "./AddToProject.scss";
import { ProjectsSelect } from "components/Mytask/ProjectsSelect/ProjectsSelect";
import { useMyTask } from "components/Mytask/Context/MyTask.context";
import { BaseRecord } from "@pankod/refine-core";

const AddToProject = () => {
  const values = useMyTask()
  const [openSearch, setOpenSearch] = useState(false)
  const [projectAndSections, setProjectAndSections] = useState<BaseRecord[]>([])


  const onChange = (value: string) => {
  };

  const onSearch = (value: string) => {
  };

  const onBlur = () => {
    setOpenSearch(false)

  }

  const handleOpen = () => {
    setOpenSearch(true)
  }

  useEffect(() => {
    values?.projectAndSection && setProjectAndSections(values?.projectAndSection)

  },[values?.projectAndSection])


  return (
    <div className="info-items">
      <div className="info-label">Projects</div>
        <div className="projects-details">

          <div className="listproject">
            {projectAndSections?.map(item => {
               return <ProjectItem key={item.sectionId} projectId={item.resourcesId} sectionId={item.sectionId}/>
            })}
         
          </div>


          {!openSearch && <div className="project-btn" onClick={handleOpen}>Add to projects</div>}

          {openSearch &&  <ProjectsSelect setOpenSearch={setOpenSearch}   onBlur={onBlur} onChange={onChange} onSearch={onSearch} />}



        </div>
      </div>
  );
};

export default AddToProject;

import React, {useState, useRef, useEffect} from "react";
import "./AddSection.scss";
import {BsPlusLg} from 'react-icons/bs'
import {AiFillCaretDown} from 'react-icons/ai'
import { BaseRecord, useCreate } from "@pankod/refine-core";
import useMyTaskStore from "pages/myTask/state/store";

interface AddSectionProps {
  setSections?: React.Dispatch<React.SetStateAction<BaseRecord[] >>,
  projectId?: number
}

export const AddSection: React.FC<AddSectionProps> = ({setSections, projectId}) => {
  const [showIp, setShowIp] = useState(false)
  const [ipValue, setIpValue] = useState('')
  const {mutate} = useCreate()
  const {addNewSection, appendNewSectionIds} = useMyTaskStore() 

 
  const handleShow = () => {
    setShowIp(!showIp)
  }

  const handleBlur = () => {
    if(ipValue === '') {
      setShowIp(!showIp)
    } 
    else if (ipValue !== ' ') {
      const sectionData = {
        name: ipValue,
        resourcetype: "section",
        mytaskId: projectId,
      }
      mutate(
        {
          resource: `resources/inserttask/${projectId}`,
          values: sectionData,
          successNotification: {
            message: "Tạo Section thành công !",
            type: "success" 
          }
        },
  
        {
          onSuccess: (data, variables, context) => {
            const newSection = {
              id: data.data.resourcesid,
              resourcesid: data.data.resourcesid,
              resources: {id: data.data.resourcesid, name: ipValue}
            }
            
            // setSections && setSections(prev => [...prev, newSection])
            addNewSection(newSection)
            appendNewSectionIds(`${data.data.resourcesid}`)
            setIpValue('')

          },
          onError: (error, variables, context) => {
          },
        }
      );
      setShowIp(!showIp)
    }

  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIpValue(e.target.value)
  }

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if(e.keyCode !== 13) return

    handleBlur()
  }
  
  const inputRef = useRef<HTMLInputElement>(null)
  useEffect(() => {
    if(showIp) {
      inputRef.current?.focus()
    }
  }, [showIp])
  

  return (
    <div className="add-section-container">
      {showIp && <div className="add-section-input">
        <AiFillCaretDown />
        <input type="text" ref={inputRef} onBlur={handleBlur} onKeyDown={handleKeyDown} value={ipValue} onChange={handleChange}/>
      </div>}

      <div className="add-section" onClick={handleShow}>
        <BsPlusLg className="add-icon"/>
        <div className="">Add section</div>
      </div>
      
    </div>
  );
};

import axios from "axios"
import ApiConstants from "constanst"

export const getDataRecently = async(projectId:number,type:string,page?:number,) => {
    return await axios.get(`${ApiConstants.BASE_URL}/resourcesrelation/recently-completed?projectId=${projectId}&type=${type}${type == 'all' ? `&page=${page}` : ''}`)
}


export const getProjectListRecently = async() => {
    return await axios.get(`${ApiConstants.BASE_URL}/resourcesmembers/projectlist?filter=memberId||$eq||1&filter=isRead||$eq||true&filter=resources.resourcetype||$eq||project&fields=resourcesId&join=resources||name,resourcetype&limit=1500&page=1&offset=0`)
}

export const getProjectListByResourceId = async(resourceId:number) => {
    return await axios.get(`${ApiConstants.BASE_URL}/resourcesrelation/resource-task-by-resource-id?resourceId=${resourceId}`)
}
export const addProject = async(taskId:number,projectId:number) => {
    const values = {
        taskId,
        projectId
    }
    return await axios.post(`${ApiConstants.BASE_URL}/resources/addtoproject`,values)
}

export const getSectionTaskDetailRecently = async(projectId:number) => {
    return await axios.get(`/resourcesrelation?filter=resourcesparentid||$eq||${projectId}&filter=resources.deletedAt||$isnull&filter=resources.id||$notnull&fields=resourcesid,order&join=resources||name&limit=1000&page=1&offset=0&sort=order,DESC`)
}

export const updateSectionTaskDetailRecently = async(values:any) => {
    return await axios.patch(`resources/updatesectionfortask/${values.newSectionId}`,values)
}

export const removeSectionTaskDetailRecently = async(values:any) => {
    return await axios.patch(`resources/removefromproject/1`,values)
}

export const removeAssignRecentlyAsync = async (taskId: number) => {
    await axios.post(`${ApiConstants.BASE_URL}/resources/removeassign`, { resourceId: taskId })
}

export const updateAssignRecently = async (value: { memberId: number, resourceId: number }) => {
    await axios.post(`${ApiConstants.BASE_URL}/resources/assigntask`, value)
}

export const deleteTaskRecently = async (taskId:number) => {
    return await axios.delete(`${ApiConstants.BASE_URL}/resources/${taskId}`)
}


import { Popover } from "@pankod/refine-antd";
import { TbUsers } from "react-icons/tb";
import ShowMoreProject from "./ShowMoreProject";
interface isPropProjectName {
  projectName?: any;
}
const ProjectNameItem: React.FC<isPropProjectName> = ({ projectName }) => {
  
  return (
    <>
      {projectName?.length > 0 && (
        <div className="project-name-wrap">
          {/* <TbUsers className="icon-User"/> */}
          {projectName?.map((item: any, index: any) => {
            if (index == 0) {
              return (
                <div className="project-name" key={index}>
                  {item?.projectname?.name && <TbUsers className="icon-User"/> }
                  <span className="name-item">{item?.projectname?.name}</span>
                </div>
              );
            }         
          })}
          {projectName?.length > 1 && (
                <Popover
                  content={
                    <ShowMoreProject showMoreProjectName={projectName} />
                  }
                >
                  <div className="show-more">
                    and {projectName?.length - 1} more
                  </div>
                </Popover>
              )
            }
        </div>
      )}
    </>
  );
};
export default ProjectNameItem;

import { useRouterContext } from "@pankod/refine-core";
import { useCallback, useEffect, useMemo, useState } from "react";
import { ErrorCode, Section, useProposeState } from "../state";
import { SortType, useSortState } from "../state/sort";

import useSWR from "swr";
import useSWRImmutable from "swr/immutable";
import axios, { AxiosError } from "axios";
import { useAppContext } from "App.context/App.context";
import { getListUsers } from "method";
import { useGlobalDataState } from "../state/global";
import { isEmpty, isString, size } from "lodash";
import ApiConstants from "constanst";
import { getProjectList } from "../api.ts";
import { useProposeTaskDetail } from "../state/details";
import { MyTaskContextType, useMyTask } from "components/Mytask/Context/MyTask.context";
import { ueSectionFetchState } from "../state/loading";
import useDebounce from "hooks/useDebounce";

const PAGE_SIZE = 200

export const useProjectId = () => {
  const { useLocation, useParams } = useRouterContext();
  const { pathname } = useLocation();
  const { addProjectId } = useProposeState();

  const urlId = useMemo(() => {
    if (pathname) {
      const regex = /\/propose-task\/show\/(\d+)/;
      const match = pathname?.match(regex);

      addProjectId(match[1]);
    }
  }, [pathname]);

  return urlId;
};

export const useProjectSectionsAsync = () => {
  const { projectId, addSections, addSectionsError } = useProposeState();
  const { updateSectionsFetchState } = ueSectionFetchState()

  const fetcher = useCallback(async ([projectId]: any) => {
    if (!projectId) return
    try {
      const url = `resourcesrelation/propose-project?filter=resourcesparentid||$eq||${projectId}&filter=resources.deletedAt||$isnull&filter=resources.id||$notnull&fields=resourcesid,order&join=resources||name,id,createdAt&limit=1500&page=1&offset=0&sort=order,DESC&sort=resources.createdAt,DESC`;
      const sectionRes = await axios.get(url);
      return sectionRes.data;
    } catch (error) {
      throw error;
    }
  }, []);

  const { data, isLoading, error } = useSWRImmutable([projectId], fetcher);

  useEffect(() => {
    if (error as AxiosError) {
      const errorRes = error?.response?.data;
      const errorMess = errorRes.message;

      if (errorMess === "Bạn chưa được phân quyền") {
        addSectionsError(ErrorCode.AUTHORIZE);
      } else {
        addSectionsError(ErrorCode.SERVER_ERROR);
      }
    }

    if (data) {
      const modifyData: Section[] = data?.data.map((section: any, index: number) => {
        return {
          sectionId: section?.resourcesid,
          order: section?.order ?? -1000,
          hasMoreTask: true,
          page: 1,
          ...section.resources,
        };
      });

      const fetchState = modifyData.map((section: any, index: number) => {
        return {
          sectionId: section?.sectionId,
          hasMore: true,
          page: 1,
          extend: index === 0 ? true : false,
        }
      })
      updateSectionsFetchState(fetchState)
      addSections(modifyData);
    }
  }, [data, error]);

  return { isLoading };
};

export const useTasksDataAsync = (
  sectionId: number,
  shouldFetch: boolean,
  page: number,
  updateFetchState: any
) => {
  const { appendTasksToSection } = useProposeState();
  const { users } = useGlobalDataState()

  const fetcher = useCallback(async ([page, sectionId, shouldFetch]: any) => {
    if (!shouldFetch || !sectionId) return null;

    try {
      const url = `resourcesrelation/propose-section?filter=resourcesparentid||$eq||${sectionId}&filter=resources.deletedAt||$isnull&filter=resources.id||$notnull&filter=resourceDetails.completed||$notnull&fields=resourcesid,order&join=resources||name,createdby,createdAt&join=resourceDetails||dueOn,assignee,completed,completedAt&join=resources.resourcestasks||resourcesId,sectionId&limit=${PAGE_SIZE}&page=${page}&offset=0&sort=order,DESC`;
      const taskRes = await axios.get(url);

      if (size(taskRes?.data?.data) < PAGE_SIZE) {
        updateFetchState(sectionId, { hasMore: false })
      }

      return taskRes.data;
    } catch (error) {
      throw error;
    }
  }, []);

  const { data, isLoading, error } = useSWRImmutable([page, sectionId, shouldFetch], fetcher);

  useEffect(() => {
    if (error as AxiosError) {
      const errorRes = error?.response?.data;
      const errorMess = errorRes.message;

      if (errorMess === "Bạn chưa được phân quyền") {
        // addSectionsError(ErrorCode.AUTHORIZE);
      } else {
        // addSectionsError(ErrorCode.SERVER_ERROR);
      }
    }

    if (data && !isEmpty(users)) {
      const modifyData: Section[] = data?.data.map((task: any) => {
        const { resources, resourceDetails, order } = task;

        const { assignee, completed, completedAt, dueOn } = resourceDetails
        return {
          taskId: resources?.id,
          sectionId,
          order,
          assignee: users[assignee] ?? null,
          completed,
          completedAt,
          dueOn,
          ...resources,
        };
      });

      appendTasksToSection(sectionId, modifyData);
    }
  }, [data, error, sectionId, users]);


  return { isLoading };
};


export const useGlobalDataAsync = () => {
  const { addUsers, addReadableProjects } = useGlobalDataState()
  const { data, isLoading, error } = useSWRImmutable(`list-user`, getListUsers);
  const { data: projectData, isLoading: isPLoading, error: pError } = useSWRImmutable(`list-projects-by-memberid`, getProjectList);



  useEffect(() => {
    if (error as AxiosError) {
      console.log('Eror when fetching list users', error);
    }

    if (data) {
      addUsers(data?.data.map((user: any) => ({ ...user, avatar: `${ApiConstants.BASE_URL}/${ApiConstants.RESOURCES}/avatar/${user.id}` })));
    }
    if (projectData) {
      addReadableProjects(projectData.data.data.map((project: any) => ({ ...project.resources })))
    }
  }, [data, error, projectData]);


  return { isLoading: isLoading || isPLoading }
}

export const useDetectProposePage = () => {
  const { useLocation, useParams } = useRouterContext();
  const { pathname } = useLocation();

  return { isProposeTaskPage: pathname.includes('propose-task') }
}

export const useSelectTask = (sectionId: number, taskId: number) => {
  const { sections } = useProposeState()

  const task = useMemo(() => {

    if (sections[sectionId]?.tasks?.[taskId]) {
      return sections[sectionId]?.tasks[taskId]
    }

  }, [sectionId, taskId, JSON.stringify(sections[sectionId]?.tasks)])

  return task
}

function extractIdsFromString(inputString: string): { taskId: string, sectionId: string } | null {
  const regex = /taskId=(\d+)&sectionId=(\d+)/;
  const match = inputString.match(regex);

  if (match) {
    const taskId = match[1];
    const sectionId = match[2];
    return { taskId, sectionId };
  } else {
    return null; // If the string doesn't match the expected pattern
  }
}

export const useDetectIsShowDetails = () => {
  const { useLocation, useParams } = useRouterContext();
  const { search } = useLocation();
  const { id, resource } = useParams() as any

  const isShow = useMemo(() => {
    return (resource === "propose-task" && isString(search) && search?.includes('taskId') && search?.includes('sectionId'))
  }, [])

  return { isShowDetails: isShow }
}

export const useGetIdsFromUrl = () => {
  const { useLocation, useParams } = useRouterContext();
  const { search } = useLocation();

  const idsFromSearchString = extractIdsFromString(search)


  return idsFromSearchString
}

export const useAutoShowDetails = (isDataLoading: boolean) => {
  const { isShowDetails } = useDetectIsShowDetails()
  const urlIds = useGetIdsFromUrl()
  const { sections, appendTasksToSection } = useProposeState()
  const { addDetailTask } = useProposeTaskDetail()
  const { sectionsFetchState, updateSectionsFetchStateById } = ueSectionFetchState()
  const { users } = useGlobalDataState()
  const {updateSort} = useSortState()

  const [isScroll, setIsScroll] = useState(true)


  const {
    setShowMyTaskDetails,
    setCurrentTaskDetailId,
    setSelectedTaskId,
  } = useMyTask() as MyTaskContextType;

  const dependentValues = useDebounce(JSON.stringify({
    sections, isDataLoading, sectionsFetchState, users, isScroll
  }), 200)


  useEffect(() => {

    if (isShowDetails) {
      const { sections, isDataLoading, sectionsFetchState, users, isScroll } = JSON.parse(dependentValues as string)

      const taskId = urlIds?.taskId
      const sectionId = urlIds?.sectionId

      if (taskId && sectionId && !isDataLoading && isScroll) {

        const existedSections: Section | undefined = sections?.[sectionId]

        if (existedSections) {
          const task = existedSections?.tasks?.[taskId]
          updateSort(SortType.AllTasks)
          
          if (task) {
            addDetailTask(task)
            setShowMyTaskDetails(true);
            setCurrentTaskDetailId(Number(taskId));
            setSelectedTaskId(Number(taskId));
            scrollToActiveTask(sectionId ,taskId)
            setIsScroll(false)

          } else {
            const isSectionExtend = sectionsFetchState[sectionId]?.extend

            if (!isSectionExtend) {
              updateSectionsFetchStateById(existedSections?.sectionId, { extend: true })
            } else {
              getDataWhenCanNotFindTaskInSection(sectionId).then((data: any) => {

                const modifyData: Section[] = data?.tasks?.map((task: any) => {
                  const { resources, resourceDetails, order } = task;

                  const { assignee, completed, completedAt, dueOn } = resourceDetails
                  return {
                    taskId: resources?.id,
                    sectionId,
                    order,
                    assignee: users[assignee] ?? null,
                    completed,
                    completedAt,
                    dueOn,
                    ...resources,
                  };
                });

                updateSectionsFetchStateById(Number(sectionId), { page: data.page, hasMore: data.hasMore })
                appendTasksToSection(Number(sectionId), modifyData);

              })
            }
          }

        }
      }

    }

  }, [dependentValues])


}

const getDataWhenCanNotFindTaskInSection = async (sectionId: string) => {
  let page = 1

  let allTasks: any = []

  while (true) {
    const url = `resourcesrelation/propose-section?filter=resourcesparentid||$eq||${sectionId}&filter=resources.deletedAt||$isnull&filter=resources.id||$notnull&filter=resourceDetails.completed||$notnull&fields=resourcesid,order&join=resources||name,createdby,createdAt&join=resourceDetails||dueOn,assignee,completed,completedAt&join=resources.resourcestasks||resourcesId,sectionId&limit=${PAGE_SIZE}&page=${page}&offset=0&sort=order,DESC`;
    const taskRes = await axios.get(url);
    const data = taskRes?.data?.data

    allTasks = [...allTasks, ...data]

    ++page

    if (size(data) < PAGE_SIZE) {
      break
    }
  }


  return {
    tasks: allTasks,
    page,
    hasMore: false
  }
}

const scrollToActiveTask = (sectionId: string, taskId: string) => {
  if(!sectionId || !taskId) return
  const el = document.getElementById(`${sectionId}-${taskId}`);
  
  if (el) {
    setTimeout(() => {
      el.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }, 1000)

  }
}
import React from 'react'
import { BsPlusLg } from 'react-icons/bs'
import styled from 'styled-components'

const Button = styled.div`
    background-image: linear-gradient(to right, #1fa2ff 0%, #12d8fa 51%, #1fa2ff 100%);
    display: flex;
    align-items: center;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;
    box-shadow: 0 0 20px #eee;
    border-radius: 6px;
    height: 28px;
    &:hover {
        transform: scale(1.05);
        background-position: right center;
        color: #fff;
        text-decoration: none;
    }
`

const Content = styled.div`
    display: flex;
    align-items: center;
    padding: 0 10px;
    gap: 8px;
    cursor: pointer;
    .mt-btn-title {
        color: var(--white-color);
    }
`

const Text = styled.div`
    color: var(--white-color);
    font-weight: 600;
`

const AddNewBtn = () => {
    return (
        <Button onClick={() => window.open(`${window.location.origin}/form/show/752634`)}>
            <Content>
                <BsPlusLg className="mt-btn-icon" />
                <Text>Tạo Đề Xuất</Text>
            </Content>
        </Button>
    )
}

export default AddNewBtn

import ApiConstants from "constanst";
import React, { createContext, useContext, useState, useEffect } from "react";

import { io, Socket } from "socket.io-client";
import SocketEmitDataInterface from "./interface/socket.interface";

type Props = {
  children: React.ReactNode;
};
type Context = {
  socket: Socket | undefined;
  isNewInbox?: any;
  setIsNewInbox?: React.Dispatch<React.SetStateAction<boolean>>;
  tokenKey?: string;
  setTokenKey?: React.Dispatch<React.SetStateAction<string>>;
  socketCmtId?: number;
  setSocketCmtId?: React.Dispatch<React.SetStateAction<number>>;
};
const SocketContextTeam = createContext<Context | null>(null);

function SocketContextProvider({ children }: Props) {
  const [isNewInbox, setIsNewInbox] = useState(false);
  const [tokenKey, setTokenKey] = useState("");
  const [socketCmtId, setSocketCmtId] = useState(0);
  const [socket, setSocket] = useState<Socket>();

  useEffect(() => {
    if (tokenKey) {
      const newsocket = io(ApiConstants.SOCKET_URL, {
        autoConnect: true,
        reconnection: true,
        transportOptions: {
          polling: {
            extraHeaders: { Authorization: `${tokenKey}` },
          },
          websocket: {
            extraHeaders: { Authorization: `${tokenKey}` },
          },
          flashsocket: {
            extraHeaders: { Authorization: `${tokenKey}` },
          },
        },
        transports: ["websocket"]
      });
      setSocket(newsocket);
      return () => {
        newsocket?.disconnect();
      };
    }
  }, [tokenKey]);
  

  useEffect(() => {
    socket?.on("connect", function () {
      const data: SocketEmitDataInterface = {
        token: `${tokenKey}`
      }
      socket?.emit('joinRoom', data);
    });
  }, [socket]);

  return (
    <SocketContextTeam.Provider
      value={{
        socket,
        isNewInbox,
        setIsNewInbox,
        tokenKey,
        setTokenKey,
        socketCmtId,
        setSocketCmtId,
      }}
    >
      {children}
    </SocketContextTeam.Provider>
  );
}
const SocketTeamContext = () => useContext(SocketContextTeam);
export { SocketTeamContext, SocketContextProvider };

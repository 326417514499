import React, { useEffect, useState } from "react";
import ProjectItem from "../mtDetails/mtDetails-Content/TaskInfo/ProjectItem/ProjectItem";
import { ProjectsSelect } from "../ProjectsSelect/ProjectsSelect";
import "./ProjectList.scss";

import { Select, Tag, Tooltip } from '@pankod/refine-antd';
import type { CustomTagProps } from 'rc-select/lib/BaseSelect';
import { useAppContext } from "App.context/App.context";
import { BaseRecord, useCreate } from "@pankod/refine-core";
import { useMyTask } from "../Context/MyTask.context";
interface ProjectList {
  openProjectSearch: boolean
  setOpenProjectSearch: React.Dispatch<React.SetStateAction<boolean>>
  taskId: number | undefined
  resourceTask?: BaseRecord[]
}


export const ProjectList: React.FC<ProjectList> = ({openProjectSearch, setOpenProjectSearch,resourceTask, taskId}) => {
  const AppContext = useAppContext()
  const ProjectData = AppContext?.projectData
  const[projectOptions, setProjectOptions] = useState<any[]>([])
  const[projectList, setProjectList] = useState<any[]>([])
  const [isDropDownVisible, setIsDropDownVisible] = useState(false)
  const {mutate} = useCreate()
  const values = useMyTask()

  

  useEffect(() => {
    if(ProjectData) {

      const projectsOption = Object.values(ProjectData).map(item => {
        return {value: item?.id as number, label: item?.name as string}
      })

      setProjectOptions(projectsOption)
    }
  },[ProjectData])
  
  
useEffect(() => {
  resourceTask && setProjectList(() => { 

   const projectIds =  resourceTask.map(item => {
      return item.resourcesId
    })

    return projectIds
  })
},[resourceTask])


const handleSeclect = (projectId: any) => {
  mutate({
    resource: 'resources/addtoproject',
    values: {
      projectId, 
      taskId: taskId 
    }
  }, {
    onSuccess(data, variables, context) {
      
      setProjectList(prev => [...prev, projectId])
      values?.setResourceTaskChange({projectId, sectionId: data.data.sectionId, type: 'add'})
    },
  })
  
}

const handleCLick = () => {
  setOpenProjectSearch(true)
}

const getListProjectName = () => {
 const NameString = ProjectData && projectList.map(id => {
    return ProjectData[id]?.name
  })

return NameString?.join(' \n ')
  
}

const overlayStyle = {
  backgroundColor: "var(--tool-tip-bg-color)",
  whiteSpace: "pre-line",
  display: "flex",
  maxHeight: 200,
  overflowY: "auto",
};

  return (
    <Tooltip overlayInnerStyle={overlayStyle as any}   title={!isDropDownVisible && (projectList.length > 0 ? `This task is also part of: \n ${getListProjectName()} ` : '')} placement="bottom" >

    <div className="ProjectList-container" onClick={handleCLick}>

        <Select
          onDropdownVisibleChange={e => setIsDropDownVisible(e)}
          mode="multiple"
          showArrow={false}
          tagRender={tagRender}
          defaultValue={[]}
          style={{ width: '100%' }}
          options={projectOptions}
          className='ProjectList-seclect'
          onSelect={handleSeclect}
          value={projectList}
          filterOption={(input, option) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
          
      />

    </div>
    </Tooltip>
  );
};

const tagRender = (props: CustomTagProps) => {
  const { label, value, closable, onClose } = props;
  const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };
  return (
    <Tag
      color='green'
      onMouseDown={onPreventMouseDown}
      style={{ marginRight: 3 }}
    >
      {label}
    </Tag>
  );
};


import { useUpdate } from "@pankod/refine-core";
import { Dropdown, Menu, Space } from "@pankod/refine-antd";
import React, { memo } from "react";

interface ArchiveAll {
  dataScreenActivity?: any;
  setDataScreenActivity?: any;
  dataArchiveAll?: any;
  dataScreenArchive?: any;
  setDataScreenArchive: any;
  disable?: boolean;
}
interface prop {
  disable?: boolean;
  dataScreenActivity: any;
  setDataScreenActivity: any;
  dataScreenArchive: any;
  setDataScreenArchive: any;
}

const ArchivedAllContext: React.FC<prop> = ({
  disable,
  dataScreenActivity,
  setDataScreenActivity,
  dataScreenArchive,
  setDataScreenArchive,
}) => {
  return (
    <DropdownArchivedAll
      dataScreenActivity={dataScreenActivity}
      setDataScreenActivity={setDataScreenActivity}
      dataScreenArchive={dataScreenArchive}
      setDataScreenArchive={setDataScreenArchive}
      disable={disable}
    />
  );
};

const DropdownArchivedAll: React.FC<ArchiveAll> = memo(
  ({
    dataScreenActivity,
    setDataScreenActivity,
    dataScreenArchive,
    setDataScreenArchive,
    disable,
  }) => {
    const { mutate } = useUpdate();
    const getUserId: any = localStorage.getItem("userId");

    const handleArchiveAll = (e: any) => {
      mutate(
        {
          resource: "resourcesmentions/archiveAllNotification",
          values: {},
          id: getUserId,
          invalidates: ["all"],
        },
        {
          onSuccess: (data, variables, context) => {
            dataScreenActivity?.map((item: any) =>
              setDataScreenArchive((prev: any) => [item, ...prev])
            );
            setDataScreenActivity([]);
          },
        }
      );
    };
    const menu = (
      <Menu
        items={[
          {
            label: "Archive All Notifications",
            onClick: handleArchiveAll,
            key: "0",
            disabled: disable,
          },
        ]}
      />
    );
    return (
      <>
        <Dropdown overlay={menu} trigger={["click"]}>
          <a onClick={(e) => e.preventDefault()}>
            <Space>
              <button className="btn-archive">Archive All</button>
            </Space>
          </a>
        </Dropdown>
      </>
    );
  }
);

export default ArchivedAllContext;

import { Dropdown, Menu } from "@pankod/refine-antd";
import { BaseRecord, useDelete, useUpdate } from "@pankod/refine-core";
import { useMyTask } from "components/Mytask/Context/MyTask.context";
import MarkComplete from "components/Mytask/mtSession/SectionRow/MarkComplete/MarkComplete";
import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import { Draggable } from "react-beautiful-dnd";
import { AiOutlineCheckCircle } from "react-icons/ai";
import {
  BsTrash
} from "react-icons/bs";
import { MdDragIndicator, MdNavigateNext } from "react-icons/md";
import { GoSubTaskDetails } from "../GoSubTaskDetails/GoSubTaskDetails";
import "./SubtaskRow.scss";

interface SubTaskRowProps {
  subTaskId: number;
  selectedSubTaskId: number | null;
  setSelectedSubTaskId: React.Dispatch<React.SetStateAction<number | null>>;
  resources?: BaseRecord;
  setSubTasks?: React.Dispatch<React.SetStateAction<BaseRecord[]>>;
  index: number
}

export const SubTaskRowHOC: React.FC<SubTaskRowProps> = ({
  resources,
  setSubTasks,
  subTaskId,
  selectedSubTaskId,
  setSelectedSubTaskId,
  index
}) => {
  return (
    <SubTaskRow
      index={index}
      setSubTasks={setSubTasks}
      subTaskId={subTaskId}
      resources={resources}
      selectedSubTaskId={
        selectedSubTaskId === subTaskId ? selectedSubTaskId : null
      }
      setSelectedSubTaskId={setSelectedSubTaskId}
    />
  );
};

const SubTaskRow: React.FC<SubTaskRowProps> = memo(
  ({
    subTaskId,
    setSubTasks,
    resources,
    selectedSubTaskId,
    setSelectedSubTaskId,
    index,
  }) => {
    const textAreaRef = useRef<HTMLTextAreaElement>(null);
    const [taskContent, setTaskContent] = useState(resources?.name);
    const [oldContent, setOldContent] = useState<string | undefined>(undefined);
    const [isComplete, setIsComplete] = useState(
      resources?.resourcesDetails?.completed
    );
    const { mutate } = useUpdate();
    const values = useMyTask();

    //Textbox autoresize
    const resizeTextArea = () => {
      textAreaRef?.current && (textAreaRef.current.style.height = "auto");
      textAreaRef?.current &&
        (textAreaRef.current.style.height =
          textAreaRef.current?.scrollHeight + "px");
    };

    useEffect(resizeTextArea, [taskContent]);

    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      setTaskContent(e.target.value);
    };
    //-------------------------------------------------------

    const handleSubtaskClick = () => {
      subTaskId && setSelectedSubTaskId(subTaskId);
    };

    //Handle Function
    const handleBlur = () => {
      if (taskContent === oldContent) return;

      const updateValues = {
        name: taskContent,
      };

      mutate(
        {
          resource: "resources",
          id: subTaskId,
          values: updateValues,
          successNotification: {
            message: "Cập nhật thành công !",
            type: "success",
          },
        },
        {
          onError: (error, variables, context) => {
            setTaskContent(oldContent);
          },
          onSuccess: (data, variables, context) => {},
        }
      );
      setOldContent(undefined);
    };

    const handleFocus = () => {
      setOldContent(taskContent);
    };

    const handleOnKeyDown = (e: React.KeyboardEvent) => {
      if (e.keyCode !== 13) {
        return;
      }
      e.preventDefault();
      handleBlur();
      textAreaRef.current?.blur();
    };

    //Delete---------------------
    const Delete = useDelete();
    const deleteFunc = Delete.mutate;

    const handleTaskDelete = () => {
      if (subTaskId) {
        deleteFunc(
          {
            resource: "resources",
            id: subTaskId,
            successNotification: {
              message: "Xóa thành công !",
              type: "success",
            },
          },
          {
            onSuccess(data, variables, context) {
              setSubTasks &&
                setSubTasks((prev) => {
                  const oldSubTasks = [...prev];
                  const newSubTasks = oldSubTasks.filter(
                    (subtask) => subtask.resourcesid !== subTaskId
                  );
                  return newSubTasks;
                });
            },
          }
        );
      }
    };
    
    const handleclickOpenNewTab = () => {
      window.open(
        `/task/show/${subTaskId}?projectid=${values?.currentProjectId}&fullscreen=true`,
        "_blank",
        "noopener,noreferrer"
      );
      // window.open(
      //   `/task/f/show/${subTaskId}?projectid=${values?.currentProjectId}`,
      //   "_blank",
      //   "noopener,noreferrer"
      // );
    };

    const handleToggleCompleteStatus = () => {
      if(subTaskId) {
          setIsComplete(!isComplete)


          mutate(
              {
              resource: 'resourcesdetails/upsert',
              id: subTaskId,
              values: {
                  completed: !isComplete,
                  type: !isComplete ? 'markcomplete' : 'markuncomplete'
              },
              successNotification: {
                  message: "Cập nhật trạng thái thành công!",
                  type: "success" 
                }
          },{
              onSuccess(data, variables, context) {
              },
              onError(error, variables, context) {
                setIsComplete(isComplete)
              },
          }
          )
      }
  }

    const menu = (
      <Menu
        items={[
          {
            label: isComplete ?  "Mark incomplete" : "Mark complete",
            key: "1",
            onClick: handleToggleCompleteStatus,
            icon: <AiOutlineCheckCircle />,
          },
          {
            label: "Open in new tab",
            key: "2",
            onClick: handleclickOpenNewTab,
            icon: <AiOutlineCheckCircle />
          },
          { type: "divider" },
          {
            label: "Delete task",
            key: "3",
            danger: true,
            onClick: handleTaskDelete,
            icon: <BsTrash />,
          },
        ]}
      />
    );

    return (
      <Draggable draggableId={`${resources?.id}`} index={index}>
        {(provided, snapshot) => {
          return (
             <Dropdown overlay={menu} trigger={["contextMenu"]}> 
              <div
                ref={provided.innerRef}
                // snapshot={snapshot}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
              >
                <div
                  onClick={handleSubtaskClick}
                  className={
                    "subtaskrow-container " +
                    (subTaskId === selectedSubTaskId ? "selected" : "")
                  }
                >
                  <div className="subtaskrow-wrapper">
                    <MdDragIndicator className="dragIcon" />

                    <div className="subtaskrow-title">
                      <MarkComplete
                        completedStatus={isComplete}
                        resourceId={subTaskId}
                        isSubtask={true}
                      />
                    </div>

                    <div className="textarea-container">
                      <textarea
                        onKeyDown={handleOnKeyDown}
                        rows={1}
                        ref={textAreaRef}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={taskContent}
                        spellCheck={false}
                      />
                    </div>

                    <div className="subtaskrow-detail-icon">

                      <GoSubTaskDetails
                        subTaskId={subTaskId}
                        subTaskName={taskContent}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Dropdown>
          );
        }}
      </Draggable>
    );
  }
);

import React, { useState } from "react";
import "./CommentFile.scss";
import {
  AttachmentFileIcon,
  ExcelFileIcon,
  PdfFileIcon,
  PowpointFileIcon,
  WordFileIcon,
} from "../File/Icons";



interface FileProps {
  fileId?: number;
  name: string;
  type?: string;
  imgUrl?: string;
  setFiles?: React.Dispatch<React.SetStateAction<File[]>>
}

export const CommentFile: React.FC<FileProps> = ({ name, imgUrl, setFiles, fileId }) => {
  let type;
  let FileIcon = AttachmentFileIcon
  const fileType = name.slice(name.lastIndexOf('.') + 1)


  switch (fileType) {
    case "docx":
      type = "Word Document";
      FileIcon = WordFileIcon;
      break;

    case "pdf":
      type = "PDF";
      FileIcon = PdfFileIcon;
      break;

    case "xlsx":
      type = "Excel SpreadSheet";
      FileIcon = ExcelFileIcon;
      break;

    case "pptx":
      type = "Powpoint Slideshow";
      FileIcon = PowpointFileIcon;
      break;

      case "png":
        type = "Image";
        break;      

    default:
      type = "Attachment";
      break;
  }

  const handleFileClick = () => {
    window.open(`${process.env.REACT_APP_API_URL || 'http://localhost:7000/'}resources/getfile/${fileId}`)
  }

  return (

    <div className="cmtfile-container" onClick={handleFileClick} style={{maxHeight: '58px'}}>
      <div className="file-icon">
        <FileIcon />
      </div>

      <div className="file-content">
        <p>{name} </p>
        <span>{type} - Download</span>
      </div>

    </div>

  );
};

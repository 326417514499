
import React, { useState } from 'react'
import './myTaskHeader.scss'
import { AiOutlineArrowDown } from 'react-icons/ai'
import { FiChevronDown } from 'react-icons/fi'
import { useMyTask } from '../Context/MyTask.context'




export const MyTaskHeader: React.FC = () => {
const values = useMyTask()
const pathName = values?.pathName
    return (
        <div style={{paddingRight: values?.scrollVisible ? '8px' : '0px'}} className="mytask-header-container">
            <div className="mytask-header-wrap">
                <div className="task-name-col header-col">
                    <span>Task name</span>
                </div>
                <div className="created-on-col header-col">
                    <div className="col-item">
                        {pathName?.includes('/mytask') ? <span>Created On</span> : <span>Created On</span>}
                        <AiOutlineArrowDown />
                    </div>

                    <FiChevronDown className="col-item-icon" />
                </div>
                <div className="due-date-col header-col">
                    <div className="col-item">
                        {pathName?.includes('/mytask') ? <span>Due date</span> : <span>Assignee</span>}
                        <AiOutlineArrowDown />
                    </div>

                    <FiChevronDown className="col-item-icon" />
                </div>
                <div className="projects-col header-col">
                {pathName?.includes('/mytask') ? <span>Projects</span> : <span>Due date</span>}
                    <FiChevronDown className="col-item-icon" />
                </div>
               
            </div>
        </div>
    )
}
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable import/first */
import { AuthProvider, useNavigation } from "@pankod/refine-core";
import { redirectPage } from "@pankod/refine-core/dist/definitions/helpers";
import Cookies from 'universal-cookie'
export const TOKEN_KEY = "refine-auth";
export const TOKEN_KEY_LOGIN = "refine-auth-login";
export const USER_ID = "userId";
export const DEFAULT_WORKSPACE = "defaultworkspace";

import axios from "axios";

const cookies = new Cookies()

export const authProvider: AuthProvider = {


  login: async ({ username, password }) => {
    let dataUser = {
      username,
      password
    }
    return axios.post("auth/login",dataUser)
    .then((res)=>{ 
      if (username === res.data?.user) {
          localStorage.setItem(TOKEN_KEY,res.data.accessToken);
          localStorage.setItem(TOKEN_KEY_LOGIN,res?.data?.userTaskList?.resourcesid);
          localStorage.setItem(USER_ID,res?.data?.id);
          localStorage.setItem(DEFAULT_WORKSPACE,res?.data?.defaultOrganization?.id);

          cookies.set('auth-cookie', res.data.accessToken, {path: '/', expires: new Date(Date.now() + 60 * 60 * 24 * 7 * 1000)})
          axios.defaults.headers.common = {
            Authorization: `Bearer ${res.data.accessToken}`,
          };
          return Promise.resolve('/mytask/show/'+res?.data?.userTaskList?.resourcesid);
      }
      else{ 
        return Promise.reject(new Error("Vui long kiem tra lai user and password"))

      } 
 
    })
  },

  logout: () => {
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem(USER_ID);
    localStorage.removeItem(TOKEN_KEY_LOGIN);
    return Promise.resolve();
  },
  checkError: () => Promise.resolve(),
  checkAuth: () => {
    const token = localStorage.getItem(TOKEN_KEY);
    if (token) {
      return Promise.resolve();
    }

    return Promise.reject();
  },
  getPermissions: () => Promise.resolve(),
  getUserIdentity: async () => {
    const token = localStorage.getItem(TOKEN_KEY);
    if (!token) {
      return Promise.reject();
    }

    return Promise.resolve({
      id: 1,
    });
  },
};

import './RedirectMobile.scss'
import Logo from '../../Assets/Image/logo.png'
import { MOBILE_URL } from 'constanst'
export const RedirectMobilePage: React.FC = () => {

  const handleClick = () => {
    const fullUrl = window.location.href
    const origin = window.location.origin

    const pathName = MOBILE_URL + fullUrl.replace(origin, '')
    window.location.replace(pathName)

  }

  return (
  <div className='rd-container'>
    <div className="rd-mb-header">
      <img src={Logo} />
      <h2>Family Working Space</h2>
    </div>

    <div className="rd-goto-app" onClick={handleClick}>
      Open in the Family Working Space App
    </div>
  </div>
  )
}


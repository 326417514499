import { MentionData } from '@draft-js-plugins/mention';
import { BaseRecord } from "@pankod/refine-core";
import { ALLTASK, INCOMPLETE } from "components/Mytask/Constant";
import { hashMapFunc } from "helper";
import { getListUsers } from "method";
import { DISPLAYSTATUS } from 'pages/CompletedTasks/CompletedTaskPage';
import React, { createContext, useContext, useEffect, useState } from "react";

interface Props {
  children: React.ReactNode;
}

export interface MentionHasmap {
  [key: string | number]: {
    id: string | number,
    name: string
  }
}

const KEY_HASMAP = {
  USER_ID: 'user-id',
  TASK_ID: 'task-id'
}



export interface AppContextType {
  showSideBar: boolean ;
  setShowSideBar: React.Dispatch<React.SetStateAction<boolean>>;
  mentionsData: MentionData[];
  setMentionsData: React.Dispatch<React.SetStateAction<MentionData[]>>;
  isHasUserTaskList: boolean;
  setIsHasUserTaskList: any;
  usersData: BaseRecord
  setUsersData: React.Dispatch<React.SetStateAction<BaseRecord>>;
  setProjectList: React.Dispatch<React.SetStateAction<BaseRecord[]>>;
  projectList: BaseRecord[]
  projectData: BaseRecord
  setProjectData: React.Dispatch<React.SetStateAction<BaseRecord>>;
  isMyTaskLoading: boolean
  setIsMyTaskLoading: React.Dispatch<React.SetStateAction<boolean>>;
  taskDisplayStatus: string, 
  setTaskDisplayStatus: React.Dispatch<React.SetStateAction<string>>;
  mentionsDataHasmap: MentionHasmap
  tasksDataHasmap: MentionHasmap
  showSideBarInbox:any, setShowSideBarInbox:any
  cmtIdPtt:any
  setcmtIdPtt:any
}

const AppConText = createContext<AppContextType | null>(null);

function AppProvider({ children }: Props) {
  const [showSideBar, setShowSideBar] = useState<boolean>(false);
  const [mentionsData, setMentionsData] = useState<MentionData[]>([])
  const [mentionsDataHasmap, setMentionsDataHasmap] = useState<any>([])
  const [tasksDataHasmap, setTasksDataHasmap] = useState<any>([])
  const [isHasUserTaskList, setIsHasUserTaskList] = useState<boolean>(false);
  const [usersData, setUsersData] = useState<BaseRecord>({});
  const [projectList, setProjectList] = useState<BaseRecord[]>([])
  const [projectData, setProjectData] = useState<BaseRecord>({})
  const [isMyTaskLoading, setIsMyTaskLoading] = useState(false)
  const [taskDisplayStatus, setTaskDisplayStatus] = useState(INCOMPLETE)
  const [showSideBarInbox, setShowSideBarInbox] = useState("")

  const [cmtIdPtt,setcmtIdPtt]=useState()



  useEffect(() => {
    const fetch = async () => {
      const listUser = await getListUsers()
      setMentionsData(listUser?.data)
    
      setMentionsDataHasmap(hashMapFunc(listUser?.data, KEY_HASMAP.USER_ID))
     
      setTasksDataHasmap(hashMapFunc(listUser?.data, KEY_HASMAP.TASK_ID))
      
    }
    fetch()
  }, [])

  const itemdisplaystatus = localStorage.getItem(DISPLAYSTATUS)

  
  useEffect(()=>{
    if(itemdisplaystatus){

      setTaskDisplayStatus(itemdisplaystatus)
      localStorage.removeItem(DISPLAYSTATUS)
    }
  },[itemdisplaystatus])
  

  const values = {
    taskDisplayStatus, 
    setTaskDisplayStatus,
    isMyTaskLoading, 
    setIsMyTaskLoading,
    setProjectList,
    projectList,
    showSideBar,
    setShowSideBar,
    mentionsData, 
    setMentionsData,
    mentionsDataHasmap,
    isHasUserTaskList,
    setIsHasUserTaskList,
    usersData,
    setUsersData,
    projectData,
    setProjectData,
    tasksDataHasmap,
    showSideBarInbox,
    setShowSideBarInbox,
    setcmtIdPtt,
    cmtIdPtt
  }

  return (
    <AppConText.Provider value={values} >
      {children}
    </AppConText.Provider> 
  )
}

const useAppContext = (): AppContextType | null => useContext(AppConText);

export { AppProvider, useAppContext };

import create from 'zustand'

interface DragDropState {
    isDisableSectionDrop: boolean
    isDisableTaskDrop: boolean
    toggleSectionDrop: (result: boolean) => void
    toggleTaskDrop: (result: boolean) => void
}
const useDragDropStore = create<DragDropState>((set) => ({
    isDisableSectionDrop: false,
    isDisableTaskDrop: false,
 
    toggleSectionDrop: (result: boolean) => set((state) => {
        state.isDisableSectionDrop = result
        return {...state}
    }),
    toggleTaskDrop: (result: boolean) => set((state) => {
        state.isDisableTaskDrop = result
        return {...state}
    }),
    
}))

export default useDragDropStore
import React from 'react'
import { FcLock } from 'react-icons/fc'

import styled from 'styled-components'

const Wrap = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 48px) ;
`
const LockIcon = styled(FcLock) `
    font-size: 6rem;
`
const Inner = styled.div`
        display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`
const Permission = () => {
  return (
    <Wrap>
        <Inner>

        <LockIcon />
        <h2>You do not have permission to view this task !</h2>
        </Inner>
    </Wrap>
  )
}

export default Permission
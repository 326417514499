import { MentionData } from "@draft-js-plugins/mention";
import Fuse from "fuse.js";

export const reorder = (list: any, startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const removeFromList = (list: any, index: any) => {
  const result = Array.from(list);
  const [removed] = result.splice(index, 1);
  return [removed, result];
};

export const addToList = (list: any, index: any, element: any) => {
  const result = Array.from(list);
  result.splice(index, 0, element);
  return result;
};

export const customSuggestionsFilter = (searchValue: string, suggestions: MentionData[]) => {
  const options = {
    useExtendedSearch: true,
    keys: ["name"], // search by name
  };

  const fuse = new Fuse(suggestions, options);

  const searchValueConvert = searchValue.split(" ").map((word) => ({
    name: `'` + word,
  }));

  const filteredSuggestions = searchValue
    ? fuse.search({ $and: searchValueConvert }).map((result) => result.item)
    : suggestions;

  return filteredSuggestions;
};

import { ResponsiveBox } from "devextreme-react";
import {
  Col,
  Row,
  Item as ItemBox,
  Location,
} from "devextreme-react/responsive-box";
import { DanhSachFormHOC } from "./danh-sach-form";
import { CauHinhChiTietHOC } from "./cau-hinh-chi-tiet";
import { useState } from "react";
export const CauHinhDanhMucFormHOC: React.FC = () => {
  const [formId, setFormId] = useState(0)
  return (
    <div id="content_body" style={{ marginTop: "70px", height: '100%' }}>
      <ResponsiveBox>
        <Row ratio={0} />
        <Col ratio={1} />
        <Col ratio={1.5} />

        <ItemBox>
          <Location screen="xs sm md lg" row={0} col={0} />
          <DanhSachFormHOC setFormId={setFormId}/>
        </ItemBox>

        <ItemBox>
          <Location screen="xs sm md lg" row={0} col={1} />
          <CauHinhChiTietHOC formId={formId}/>
        </ItemBox>
      </ResponsiveBox>
    </div>
  );
};

import { MyTaskProvider } from 'components/Mytask/Context/MyTask.context'
import {MyTask, MyTaskHOC} from '../../components/Mytask/MyTask'
import {Provider} from 'react-redux'
import { store } from 'components/Mytask/Redux/store'
import { PopUpCarlendar } from 'components/Mytask/PopUpCarlendar/PopUpCarlendar'
import { MentionMany } from 'components/Mytask/MentionMany/MentionMany'
export const ProjectsPage: React.FC = () => {
  return (
    <MyTaskProvider>
       <MyTaskHOC />
       {/* <MyTask /> */}
    </MyTaskProvider>
  )
}


import React, { useEffect, useRef, useState } from 'react'
import './TaskNameInput.scss'
import {  useMyTask } from '../../../Context/MyTask.context'
import {TaskNameInputProps} from '../../../Type/MyTaskType'
import { BaseRecord, useList, useUpdate } from '@pankod/refine-core'

interface TaskNameInputHOCProps {
  resourceData: BaseRecord
  type?: string
}
export const TaskNameInputHOC: React.FC<TaskNameInputHOCProps> = ({ type}) => {
  const values = useMyTask()
  const detailsRef = values?.detailsRef
  const setChangeTitle = values?.setChangeTitle
  const currentTaskDetailTitle = values?.currentTaskDetailTitle
  const setCurrentTaskDetailTitle = values?.setCurrentTaskDetailTitle
  const setCurrentAddTaskDetailTitle = values?.setCurrentAddTaskDetailTitle
  const currentAddTaskDetailTitle = values?.currentAddTaskDetailTitle
  const currentTaskDetailId = values?.currentTaskDetailId
  const setInputBlur = values?.setInputBlur
  const inputBlur = values?.inputBlur
  const setIsTaskUpdate = values?.setIsTaskUpdate
  const isTaskUpdate = values?.isTaskUpdate
  const setDetailInputFocus = values?.setDetailInputFocus
  const detailInputFocus = values?.detailInputFocus
  const subTaskDetailsMode = values?.subTaskDetailsMode

  return(
    <>
      <TaskNameInput
      detailsRef={detailsRef}
      setChangeTitle={setChangeTitle}
      currentTaskDetailTitle={currentTaskDetailTitle}
      setCurrentTaskDetailTitle={setCurrentTaskDetailTitle}
      setCurrentAddTaskDetailTitle={setCurrentAddTaskDetailTitle}
      currentAddTaskDetailTitle={currentAddTaskDetailTitle}
      currentTaskDetailId={currentTaskDetailId}
      setInputBlur={setInputBlur}
      inputBlur={inputBlur}
      setIsTaskUpdate={setIsTaskUpdate}
      isTaskUpdate={isTaskUpdate}
      setDetailInputFocus={setDetailInputFocus}
      detailInputFocus={detailInputFocus}
      subTaskDetailsMode={subTaskDetailsMode}
      type={type}
      />
    </>
  )
}

export const TaskNameInput: React.FC<TaskNameInputProps> = (
  {detailsRef,
  currentTaskDetailId,
  currentAddTaskDetailTitle,
  setCurrentAddTaskDetailTitle, 
  setChangeTitle, 
  currentTaskDetailTitle,
  setCurrentTaskDetailTitle,
  setInputBlur,
  inputBlur,
  setIsTaskUpdate,
  isTaskUpdate,
  detailInputFocus,
  setDetailInputFocus,
  subTaskDetailsMode,
  type
}) => {
  const textAreaRef = useRef<HTMLTextAreaElement>(null)
  const {mutate} = useUpdate()
  const [oldSubTaskName, setOldSubTaskName] = useState<string | undefined>(undefined)
  const [allError, setAllError] = useState({
    commentRes: true,
    taskRes: true,
    taskDetailRes: true,
    subTasksRes: true,
    FilesRes: true,
  });
  const [getRequest, setGetRequest] = useState(false);
  
  const taskRes = useList({
    resource: "resources",
    queryOptions: {
      enabled: typeof currentTaskDetailId === "number" ? getRequest : false,
      onSuccess(data) {
        setAllError((prev) => ({ ...prev, taskRes: false }));
      },
    },

    config: {
      filters: [
        {
          field: "id",
          operator: "eq",
          value: currentTaskDetailId,
        },
      ],
      pagination: { current: 1, pageSize: 1 },
    },
    metaData: {
      config: {
        fields: ["createdby", "name", "resourcetype", "createdAt"],
      },
    },
  }).data;


  //Textbox autoresize-----------------------------------------------------------------------
  const resizeTextArea = () => {
    textAreaRef?.current && (textAreaRef.current.style.height = 'auto');
    textAreaRef?.current && (textAreaRef.current.style.height = textAreaRef.current?.scrollHeight + 'px');
  }

  const handleScroll = (e: Event) => {
    const scToTopHeight = detailsRef && detailsRef.current?.scrollTop 
    const textAreaPosition = textAreaRef?.current && textAreaRef.current?.offsetTop + textAreaRef.current?.scrollHeight

    if (scToTopHeight && textAreaPosition) {
      scToTopHeight + 56  >= textAreaPosition ? (setChangeTitle && setChangeTitle(true)) : (setChangeTitle && setChangeTitle(false))
    }
  }

  useEffect(resizeTextArea, [currentAddTaskDetailTitle, currentTaskDetailTitle])


  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    typeof currentTaskDetailId === 'number' && setCurrentTaskDetailTitle && setCurrentTaskDetailTitle(e.target.value)
    typeof currentTaskDetailId === 'string' && setCurrentAddTaskDetailTitle && setCurrentAddTaskDetailTitle(e.target.value)
  }

  const handleBlur = () => {    
 
    if(subTaskDetailsMode || type === "singledetails"  ||type === "inbox"  ) {

      if(currentTaskDetailTitle && currentTaskDetailId && oldSubTaskName !== currentTaskDetailTitle) {

        mutate({
          resource: 'resources',
          id: currentTaskDetailId,
          values: {name: currentTaskDetailTitle},
          successNotification: {
            message: "Cập nhật thành công !",
            type: "success" 
          }
        },
        {
          onError: (error, variables, context) =>{
          //   oldValue && setIpValue(oldValue)
          },
          onSuccess: (data, variables, context) =>{
          },
        }
        )

      }
    }



    setInputBlur && setInputBlur(!inputBlur)
    setIsTaskUpdate && setIsTaskUpdate(!isTaskUpdate)
  }

  //Khi focus thì sẽ gửi tín hiệu cho bên sectionrow biết để xét giá trị cho oldvalue, dùng để update
  const handleFocus = () => {
    if(subTaskDetailsMode || type === "singledetails") {
      setOldSubTaskName(currentTaskDetailTitle)
      return
    }
    setDetailInputFocus && setDetailInputFocus(!detailInputFocus)
  }

  useEffect(() => {
    detailsRef && detailsRef.current?.addEventListener('scroll', handleScroll)
    return () => detailsRef && detailsRef.current?.removeEventListener('scroll', handleScroll)
  }, [])
  //--------------------------------------------------------------------------------------------

  useEffect(() => {
    if(taskRes && taskRes.data[0]?.name ) {
      setCurrentTaskDetailTitle && setCurrentTaskDetailTitle((taskRes.data[0]?.name))
    }
  },[])

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if(e.keyCode !== 13){
      return
    }
    e.preventDefault()
  }

  return (
    <div className="taskname-container">
      <textarea 
      placeholder="Write a task name" 
      ref={textAreaRef} 
      value={typeof currentTaskDetailId === 'number' ? ( currentTaskDetailTitle ? currentTaskDetailTitle : '') : (currentAddTaskDetailTitle ? currentAddTaskDetailTitle : '')} 

      onChange={handleChange} rows={1}
      onBlur={handleBlur}
      onFocus={handleFocus}
      spellCheck={false}
      onKeyDown={handleKeyDown}
      >
      </textarea>
    </div>
  )
}
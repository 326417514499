import { BaseRecord } from "@pankod/refine-core";
import React, { createContext, useState, useContext, useRef, useEffect, useMemo } from "react";
interface Props {
  children: React.ReactNode;
}

export interface AddTaskBtnProps {
  position: string | null;
  show: boolean;
}

export interface MyTaskContextType {
  addTaskByBtn: AddTaskBtnProps | undefined;
  showMyTaskDetails: boolean | null;
  changeTitle: boolean | null;
  addTaskByid: number | null;
  currentTaskDetailTitle: string | undefined;
  firstSectionId: number | null;
  currentTaskDetailId: number | null | string;
  selectedTaskId: number | undefined;
  scrollVisible: boolean | undefined;
  currentAddTaskDetailTitle: string | undefined;
  prevShow: boolean;
  addTaskPosition: string | undefined;
  inputBlur: boolean ;
  isTaskUpdate: boolean ;
  detailInputFocus: boolean ;
  isDetailsLoading: boolean ;

  subTasks: BaseRecord[]
  comments: BaseRecord[]
  subTaskPage: number
  uploadedPercent: number
  pathName: string
  resourceDetailsChange: BaseRecord
  detailsHeaderData: BaseRecord | undefined
  subTaskDetailsMode: boolean
  taskDetailsQueue: BaseRecord[]
  currentProjectId: number | undefined
  projectAndSection: BaseRecord[]
  resourceTaskChange: BaseRecord
  hideArrowToRightScreenDetail:any
  sectionDragInfo: BaseRecord | null
  fileList: BaseRecord[] 
  isBackInbox: boolean;
  isBackMyTask: number;
  isBackMyTaskType: string;
  resourceDetails:any,


  setResourceDetailsChange: React.Dispatch<React.SetStateAction<BaseRecord>>;
  setDetailsHeaderData: React.Dispatch<
    React.SetStateAction<BaseRecord | undefined>
  >;
  setShowMyTaskDetails: React.Dispatch<React.SetStateAction<boolean | null>>;
  setChangeTitle: React.Dispatch<React.SetStateAction<boolean | null>>;
  setPrevShow: React.Dispatch<React.SetStateAction<boolean>>;
  setInputBlur: React.Dispatch<React.SetStateAction<boolean>>;
  setScrollVisible: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  setAddTaskByBtn: React.Dispatch<
    React.SetStateAction<AddTaskBtnProps | undefined>
  >;
  setAddTaskByid: React.Dispatch<React.SetStateAction<number | null>>;
  setFirstSectionId: React.Dispatch<React.SetStateAction<number | null>>;
  setCurrentTaskDetailTitle: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
  setCurrentTaskDetailId: React.Dispatch<
    React.SetStateAction<number | null | string>
  >;
  setSelectedTaskId: React.Dispatch<React.SetStateAction<number | undefined>>;
  setCurrentAddTaskDetailTitle: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
  setAddTaskPosition: React.Dispatch<React.SetStateAction<string | undefined>>;
  setIsTaskUpdate: React.Dispatch<React.SetStateAction<boolean>>;
  setDetailInputFocus: React.Dispatch<React.SetStateAction<boolean>>;
  setIsDetailsLoading: React.Dispatch<React.SetStateAction<boolean>>;

  setSubTasks: React.Dispatch<React.SetStateAction<BaseRecord[]>>;
  setSubTaskPage: React.Dispatch<React.SetStateAction<number>>;
  setComments: React.Dispatch<React.SetStateAction<BaseRecord[]>>;
  setUploadedPercent: React.Dispatch<React.SetStateAction<number>>;
  setPathName: React.Dispatch<React.SetStateAction<string>>;
  setSubTaskDetailsMode: React.Dispatch<React.SetStateAction<boolean>>;
  setTaskDetailsQueue: React.Dispatch<React.SetStateAction<BaseRecord[]>>;
  setProjectAndSection: React.Dispatch<React.SetStateAction<BaseRecord[]>>;
  setResourceTaskChange: React.Dispatch<React.SetStateAction<BaseRecord>>;

  setCurrentProjectId: React.Dispatch<React.SetStateAction<number | undefined>>;
  setHideArrowToRightScreenDetail:any
  setSectionDragInfo: React.Dispatch<React.SetStateAction<BaseRecord | null>>
  setFileList: React.Dispatch<React.SetStateAction<BaseRecord[] >>
  setIsBackInbox: React.Dispatch<React.SetStateAction<boolean>>
  setIsBackMyTask: React.Dispatch<React.SetStateAction<number>>
  setIsBackMyTaskType: React.Dispatch<React.SetStateAction<string>>
  setResourceDetails:any



  
  
  
  
  
  addTaskRef: React.RefObject<HTMLInputElement>;
  addTaskBtnRef: React.RefObject<HTMLInputElement>;
  detailsRef: React.RefObject<HTMLDivElement>;
  mtContentRef: React.RefObject<HTMLDivElement>;
  taskRef: React.RefObject<HTMLDivElement[]>;
}

const MyTaskConText = createContext<MyTaskContextType | null>(null);

function MyTaskProvider({ children }: Props) {
  const [addTaskByBtn, setAddTaskByBtn] = useState<AddTaskBtnProps | undefined>(
    { position: null, show: false }
  );
  const [addTaskByid, setAddTaskByid] = useState<number | null>(null);
  const [firstSectionId, setFirstSectionId] = useState<number | null>(null);
  const [showMyTaskDetails, setShowMyTaskDetails] = useState<boolean | null>(
    false
  );
  const [currentTaskDetailTitle, setCurrentTaskDetailTitle] = useState<
    string | undefined
  >(undefined);
  const [changeTitle, setChangeTitle] = useState<boolean | null>(false);
  const [prevShow, setPrevShow] = useState<boolean>(false);
  const [currentTaskDetailId, setCurrentTaskDetailId] = useState<
    number | null | string
  >(null);
  const [selectedTaskId, setSelectedTaskId] = useState<number | undefined>(
    undefined
  );
  const [scrollVisible, setScrollVisible] = useState<boolean | undefined>(
    undefined
  );
  const [inputBlur, setInputBlur] = useState<boolean>(false);
  const [addTaskPosition, setAddTaskPosition] = useState<string | undefined>(
    undefined
  );
  const [currentAddTaskDetailTitle, setCurrentAddTaskDetailTitle] = useState<
    string | undefined
  >(undefined);
  const [isTaskUpdate, setIsTaskUpdate] = useState<boolean>(false);
  const [detailInputFocus, setDetailInputFocus] = useState<boolean>(false);
  const [subTasks, setSubTasks] = useState<BaseRecord[]>([]);
  const [subTaskPage, setSubTaskPage] = useState<number>(1);
  const [comments, setComments] = useState<BaseRecord[]>([]);
  const [isDetailsLoading, setIsDetailsLoading] = useState(false);
  const [uploadedPercent, setUploadedPercent] = useState(0);
  const [pathName, setPathName] = useState<string>('');
  const [resourceDetailsChange, setResourceDetailsChange] = useState<BaseRecord>({});
  const [detailsHeaderData, setDetailsHeaderData] = useState<BaseRecord | undefined>(undefined)
  const [subTaskDetailsMode, setSubTaskDetailsMode] = useState<boolean>(false)
  const [taskDetailsQueue, setTaskDetailsQueue] = useState<BaseRecord[]>([])
  const [currentProjectId, setCurrentProjectId] = useState<number | undefined>(undefined)
  const [projectAndSection, setProjectAndSection] = useState<BaseRecord[]>([])
  const [resourceTaskChange, setResourceTaskChange] = useState<BaseRecord>({})
  const [hideArrowToRightScreenDetail,setHideArrowToRightScreenDetail] = useState("")
  const [sectionDragInfo,setSectionDragInfo] = useState<BaseRecord | null>(null)

  const [fileList,setFileList] = useState<BaseRecord[] >([])
  const [isBackInbox, setIsBackInbox] = useState<boolean>(false);  
  const [isBackMyTask, setIsBackMyTask] = useState<number>(0);  
  const [isBackMyTaskType, setIsBackMyTaskType] = useState<string>('');
  const [resourceDetails, setResourceDetails] = useState<BaseRecord[]>([]);

  const addTaskRef = useRef<HTMLInputElement>(null);
  const addTaskBtnRef = useRef<HTMLInputElement>(null);
  const detailsRef = useRef<HTMLDivElement>(null);
  const mtContentRef = useRef<HTMLDivElement>(null);
  const taskRef = useRef<HTMLDivElement[]>([]);

  const values = {
    sectionDragInfo,
    setSectionDragInfo,
    resourceTaskChange, 
    setResourceTaskChange,
    projectAndSection,
    setProjectAndSection,
    setCurrentProjectId,
    currentProjectId,
    setTaskDetailsQueue,
    taskDetailsQueue,
    setSubTaskDetailsMode,
    subTaskDetailsMode,
    setDetailsHeaderData,
    detailsHeaderData,
    setResourceDetailsChange,
    resourceDetailsChange,
    setPathName,
    pathName,
    setUploadedPercent,
    uploadedPercent,
    isDetailsLoading,
    setIsDetailsLoading,
    comments,
    setComments,
    setSubTaskPage,
    subTaskPage,
    addTaskByBtn,
    setAddTaskByBtn,
    addTaskByid,
    setAddTaskByid,
    showMyTaskDetails,
    setShowMyTaskDetails,
    addTaskRef,
    addTaskBtnRef,
    detailsRef,
    changeTitle,
    setChangeTitle,
    firstSectionId,
    setFirstSectionId,
    currentTaskDetailTitle,
    setCurrentTaskDetailTitle,
    currentTaskDetailId,
    setCurrentTaskDetailId,
    setSelectedTaskId,
    selectedTaskId,
    scrollVisible,
    setScrollVisible,
    mtContentRef,
    addTaskPosition,
    setAddTaskPosition,
    prevShow,
    setPrevShow,
    setCurrentAddTaskDetailTitle,
    currentAddTaskDetailTitle,
    taskRef,
    inputBlur,
    setInputBlur,
    isTaskUpdate,
    setIsTaskUpdate,
    detailInputFocus,
    setDetailInputFocus,
    subTasks,
    setSubTasks,
    hideArrowToRightScreenDetail,
    setHideArrowToRightScreenDetail,
    fileList,
    setFileList,
    isBackInbox,
    setIsBackInbox,
    isBackMyTask, 
    setIsBackMyTask,
    isBackMyTaskType,
    setIsBackMyTaskType,
    resourceDetails,
    setResourceDetails,
  };

  return (
    <MyTaskConText.Provider value={values}>{children}</MyTaskConText.Provider>
  );
}

const useMyTask = (): MyTaskContextType | null => useContext(MyTaskConText);

export { MyTaskProvider, useMyTask };

import React, { useCallback, useMemo, useRef } from "react";
import "./TaskNameInput.scss";
import { useMyTask } from "components/Mytask/Context/MyTask.context";
import { TaskNameInputProps } from "components/Mytask/Type/MyTaskType";
import { useDetectScroll, useTextboxAutoResize } from "./hooks";
import { useProposeTaskDetail } from "pages/ProposeTasks/state/details";
import { useSelectTask } from "pages/ProposeTasks/hooks";
import { useProposeState } from "pages/ProposeTasks/state";
import { updateTaskAsync } from "pages/ProposeTasks/services";


export const ProposeTaskNameInput: React.FC= () => {
  const values = useMyTask();
  const detailsRef = values?.detailsRef;
  const setChangeTitle = values?.setChangeTitle;
  const currentTaskDetailTitle = values?.currentTaskDetailTitle;

  return (
    <>
      <TaskNameInput
        detailsRef={detailsRef}
        setChangeTitle={setChangeTitle}
        currentTaskDetailTitle={currentTaskDetailTitle}
      />
    </>
  );
};

export const TaskNameInput: React.FC<TaskNameInputProps> = ({
  detailsRef,
  setChangeTitle,
  currentTaskDetailTitle,
}) => {

  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  useTextboxAutoResize(textAreaRef, currentTaskDetailTitle)
  useDetectScroll(detailsRef, textAreaRef, setChangeTitle)

  const { task, updateDetailTask } = useProposeTaskDetail();
  const {updateTask} = useProposeState()


  const [sectionId, taskId] = useMemo(() => {
    if(task?.sectionId && task.taskId) {
      return [task?.sectionId, task?.taskId]
    }

    return [null, null]
  },[task])

  const activateTask = useSelectTask(sectionId as number, taskId as number);

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if(taskId && sectionId) {
      updateTask(sectionId, taskId, {name: e.target.value})
    }
  };

  const handleBlur = useCallback(() => {
    if(taskId && task) {
      updateTaskAsync(taskId, {name: activateTask?.name},task, updateTask, updateDetailTask)
    }
  },[taskId, JSON.stringify(activateTask), JSON.stringify(task), updateTask])



  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.keyCode !== 13) {
      return;
    }
    e.preventDefault();
  };

 
  return (
    <div className="taskname-container">
      <textarea
        placeholder="Write a task name"
        ref={textAreaRef}
        value={activateTask?.name}
        onChange={handleChange}
        rows={1}
        onBlur={handleBlur}
        spellCheck={false}
        onKeyDown={handleKeyDown}
      />
    </div>
  );
};

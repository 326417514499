import { Tooltip } from "@pankod/refine-antd";
import { useAppContext } from "App.context/App.context";
import ApiConstants from "constanst";
import React, { useEffect, useState } from "react";
import "./avatar.scss";
import avatar from './user_default.jpeg';
interface Avatar {
    width?: any,
    height?: any,
    userId?: any,
    isTooltip?: any
  }
export const Avatar: React.FC<Avatar> = ({userId, width, height, isTooltip = true}) => {
    const AppContext = useAppContext()
    const mentionsDataHasmap = AppContext?.mentionsDataHasmap
    const [url, setUrl] = useState<any>(null)
    const [name, setName] = useState<any>('')

    useEffect(() => {
        if(mentionsDataHasmap){
            setName(mentionsDataHasmap[userId]?.name)
            setUrl(`${ApiConstants.BASE_URL}/${ApiConstants.AVATAR}/${userId}`)            
        }

    }, [userId])

    return(
        <>
            <Tooltip  title={ !isTooltip ? '' : (name ? name : '')}>
                {url &&
                    <img onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src=avatar;
                    }} style={{ width: width ? width : '30px', height: height ? height : '30px' }} className="avatar-img" src={url} />
                }              
            </Tooltip>
      
        </>

    )
};

// usersData

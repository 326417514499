import { USER_ID } from "authProvider";
import React, {
  createContext,
  Reducer,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react";

import { SocketTeamContext } from "./socketcontext";
import { io, Socket } from "socket.io-client";

type Props = {
  children: React.ReactNode;
};
type Context = {
  tokenKey?: string;
  setTokenKey?: React.Dispatch<React.SetStateAction<string>>;
  socketNewTaskId?: number;
  setSocketNewTaskId?: React.Dispatch<React.SetStateAction<number>>;
  currentProjectId?: number;
  setCurrentProjectId?: React.Dispatch<React.SetStateAction<number>>;
  prevProjectId?: number;
  setPrevProjectId?: React.Dispatch<React.SetStateAction<number>>;
  currentSectionId?: number;
  setCurrentSectionId?: React.Dispatch<React.SetStateAction<number>>;
  createById?: number;
  setCreateById?: React.Dispatch<React.SetStateAction<number>>;
  userReceiveMessage?: number;
  setUserReceiveMessage?: React.Dispatch<React.SetStateAction<number>>;
  nameTask?: string;
  setNameTask?: React.Dispatch<React.SetStateAction<string>>;
  oldNameTask?: string;
  setOldNameTask?: React.Dispatch<React.SetStateAction<string>>;
  nameAction?: string;
  setNameAction?: React.Dispatch<React.SetStateAction<string>>;
  dispatch?: React.Dispatch<ActionNewComment>;
};
const NewInsertTaskContextTeam = createContext<Context | null>(null);

export type StateNewComment = {
  projectId: string;
};
export enum ActionType {
  Update = "Update",
  Insert = "Insert",
}
export type ActionNewComment =
  | { type: "joinRoomProject"; payload: string }
  | { type: "leaveRoomProject"; payload: string }
  | { type: "checkRoomProject"; payload: string }
  | { type: "joinRoomTaskAction"; payload: string }
  | { type: "leaveRoomTaskAction"; payload: string };

export const inittialNewTaskState: StateNewComment = { projectId: "" };

function NewInsertTaskContextProvider({ children }: Props) {
  const userId = localStorage.getItem(USER_ID);
  const socketTeamContext = SocketTeamContext();
  const [socketNewTaskId, setSocketNewTaskId] = useState(0);
  const [currentProjectId, setCurrentProjectId] = useState(0);
  const [userReceiveMessage, setUserReceiveMessage] = useState(0);
  const [prevProjectId, setPrevProjectId] = useState(0);
  const [currentSectionId, setCurrentSectionId] = useState(0);
  const [createById, setCreateById] = useState(0);
  const [nameTask, setNameTask] = useState("");
  const [oldNameTask, setOldNameTask] = useState("");
  const [nameAction, setNameAction] = useState("");
  const initialState = { projectId: "" };
  const reducer: Reducer<StateNewComment, ActionNewComment> = (
    state,
    action
  ) => {
    switch (action.type) {
      case "joinRoomProject":
        socketTeamContext?.socket?.emit("joinRoomProject", action?.payload);
        return { projectId: "" };
      case "leaveRoomProject":
        socketTeamContext?.socket?.emit(
          "leaveRoomProject",
          action?.payload?.toString()
        );
        return { projectId: "" };
      case "checkRoomProject":
        return { projectId: "" };

      case "joinRoomTaskAction":
        // console.log('joinRoomTaskAction',action?.payload?.toString());

        socketTeamContext?.socket?.emit(
          "joinRoomTaskAction",
          action?.payload?.toString()
        );
        return { projectId: "" };

      case "leaveRoomTaskAction":
        // console.log('joinRoomTaskAction',action?.payload?.toString());

        socketTeamContext?.socket?.emit(
          "leaveRoomTaskAction",
          action?.payload?.toString()
        );
        return { projectId: "" };
      default:
        throw new Error();
    }
  };

  const [state, dispatch] = useReducer(reducer, inittialNewTaskState);

  useEffect(() => {
    socketTeamContext?.socket?.on("getNewInsertTaskClient", (response: any) => {
      if (userId === response?.userId.toString()) {
        // console.log('getNewInsertTaskClient', response);

        setUserReceiveMessage(response?.userId);
        setSocketNewTaskId(response?.taskId);
        setCurrentSectionId(response?.sectionId);
        setCreateById(response?.createBy);
        setNameAction(ActionType.Insert);
      }
    });
  }, [socketTeamContext?.socket]);

  useEffect(() => {
    socketTeamContext?.socket?.disconnect();
    socketTeamContext?.socket?.connect();
    socketTeamContext?.socket?.emit("joinRoom");
    dispatch({ type: "joinRoomProject", payload: currentProjectId.toString() });
    dispatch({ type: "leaveRoomProject", payload: prevProjectId.toString() });
    // return ()=>{socketTeamContext?.socket?.disconnect()}
  }, [currentProjectId]);

  useEffect(() => {
    socketTeamContext?.socket?.on("getNewUpdateTaskClient", (response: any) => {
      if (userId === response?.userId.toString()) {
        setNameAction(ActionType.Update);
        setUserReceiveMessage(response?.userId);
        setSocketNewTaskId(response?.taskId);
        setCurrentSectionId(response?.sectionId);
        setCreateById(response?.createBy);
        setNameTask(response?.name);
        setOldNameTask(response?.oldNameTask);
      }
    });
    // return ()=>{socketTeamContext?.socket?.disconnect()}
  }, [socketTeamContext?.socket]);

  return (
    <NewInsertTaskContextTeam.Provider
      value={{
        socketNewTaskId,
        setSocketNewTaskId,
        currentProjectId,
        setCurrentProjectId,
        dispatch,
        userReceiveMessage,
        setUserReceiveMessage,
        prevProjectId,
        setPrevProjectId,
        currentSectionId,
        setCurrentSectionId,
        createById,
        setCreateById,
        setNameTask,
        nameTask,
        oldNameTask,
        setOldNameTask,
        setNameAction,
        nameAction,
      }}
    >
      {children}
    </NewInsertTaskContextTeam.Provider>
  );
}
const NewInsertTaskSocketTeamContext = () =>
  useContext(NewInsertTaskContextTeam);
export { NewInsertTaskSocketTeamContext, NewInsertTaskContextProvider };

import { Dropdown, Menu, Select, Space } from "@pankod/refine-antd";
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { AiOutlineCheck } from "react-icons/ai";
import { BsFilter } from "react-icons/bs";
import useFilterState from "../state";
import FilterfromPopup from "../components/popup";
import { useGlobalDataState } from "pages/ProposeTasks/state/global";
import styled from "styled-components";
import { SelectBox } from "devextreme-react";
import { useClickAway } from "react-use";
import { MdClose } from "react-icons/md";
import useTabState from "../state/tab";

interface prop {
  setKeyFilter?: any;
  setGetRequest?: any;
  setType?: any;
  setDataTemp?: any;
  setDisable?: any;
  type?: any;
  resetFilterConditions?: () => void;
  isArchive?: boolean
  isActivivy?: boolean

  tabActive?:any
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  position: relative;
`;

const SelectContainer = styled.div`
  width: 300px;
  border-radius: 6px;
  border: 1px solid #d9d9d9;
  padding: 10px;
  position: absolute;
  top: 55px;
  left: 8px;
  z-index: 10;
  background-color: white;
`;

const Text = styled.p`
  margin: 0;
  font-weight: bold;
  margin-bottom: 10px;
`;

const IconWrap = styled.div`
  padding: 5px;
  cursor: pointer;
`;

const CustomDropDown = styled(Dropdown)`
  width: fit-content;
`

const DropdownFilter: React.FC<prop> = ({
  setKeyFilter,
  setType,
  setDisable,
  type,
  resetFilterConditions,
  isArchive,
}) => {
  const {
    isFilterByFromPerson,
    setIsFilterByFromPerson,
    setIsOpenPopupToSelectFilter,
    selectedUserId,
    setSelectedUserId,
    isOpenPopupToSelectFilter,
  } = useFilterState();
  const { users } = useGlobalDataState();
  const { tabActive} = useTabState()


  

  const handleFilter = (key: string) => {
    setKeyFilter(key);
    setType(key);

    if (key === "Mention") {
      setDisable && setDisable(true);
    } else {
      setDisable && setDisable(false);
    }
  };

  const onClickFromPerson = useCallback(() => {
    setIsOpenPopupToSelectFilter(true);
  }, []);

  const userData = useMemo(() => {
    return Object.values(users);
  }, [users]);

  const ref = useRef(null);

  const onSelectUser = useCallback(
    (e: any) => {
      if (!isOpenPopupToSelectFilter) return;
      resetFilterConditions && resetFilterConditions();
      const item = e?.selectedItem;
      setSelectedUserId(item?.id);
      setIsOpenPopupToSelectFilter(false);
      setIsFilterByFromPerson(true);
    },
    [isOpenPopupToSelectFilter]
  );

  useEffect(() => {
  
    // reset when change tab
      setIsFilterByFromPerson(false);
      setIsOpenPopupToSelectFilter(false);
      setSelectedUserId(null);
      resetFilterConditions && resetFilterConditions();
      handleFilter("Mention");
 
  },[tabActive])

  const menu = (
    <Menu
      items={[
        {
          label: "All",
          icon:
            type === "All" && !isFilterByFromPerson ? (
              <AiOutlineCheck className="icon-ckeck-inbox" />
            ) : (
              ""
            ),
          onClick: () => {
            setIsFilterByFromPerson(false);
            setIsOpenPopupToSelectFilter(false);
            setSelectedUserId(null);
            resetFilterConditions && resetFilterConditions();
            handleFilter("All");
          },
          key: "0",
          style: {
            backgroundColor: `${
              type === "All" && !isFilterByFromPerson ? "#eee" : ""
            }`,
          },
        },
        {
          label: "Mention",
          icon:
            type === "Mention" && !isFilterByFromPerson ? (
              <AiOutlineCheck className="icon-ckeck-inbox" />
            ) : (
              ""
            ),
          onClick: () => {
            setIsFilterByFromPerson(false);
            setIsOpenPopupToSelectFilter(false);
            setSelectedUserId(null);
            resetFilterConditions && resetFilterConditions();
            handleFilter("Mention");
          },
          key: "1",
          style: {
            backgroundColor: `${
              type === "Mention" && !isFilterByFromPerson ? "#eee" : ""
            }`,
          },
        },
        {
          label: "From person",
          icon: isFilterByFromPerson ? (
            <AiOutlineCheck className="icon-ckeck-inbox" />
          ) : (
            ""
          ),
          onClick: onClickFromPerson,
          key: "2",
          style: { backgroundColor: `${type === "Fromperson" ? "#eee" : ""}`},
        },
      ]}
    />
  );
  return (
    <Container>
      <CustomDropDown overlay={menu} trigger={["click"]} >
        <a onClick={(e) => e.preventDefault()}>
          <Space>

            {!isFilterByFromPerson && (
              <button className="btn-archive" style={{ margin: 0 }}>
                <BsFilter className="icon-filter" /> Filter
                {`: ${type === "Mention" ? "@" : ""}${type}`}
              </button>
            )}

            {isFilterByFromPerson && (
              <button className="btn-archive" style={{ margin: 0 }}>
                <BsFilter className="icon-filter" /> Filter
                {`: @${users[selectedUserId ? selectedUserId : ""]?.name}`}
              </button>
            )}

          </Space>
        </a>
      </CustomDropDown>

      <SelectContainer
        style={{ display: isOpenPopupToSelectFilter ? "block" : "none" }}
        ref={ref}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Text>Choose person</Text>

          <IconWrap onClick={() => setIsOpenPopupToSelectFilter(false)}>
            <MdClose />
          </IconWrap>
        </div>
        <SelectBox
          items={userData}
          displayExpr="name"
          valueExpr="id"
          onSelectionChanged={onSelectUser}
          searchEnabled
          value={selectedUserId}
        />
      </SelectContainer>
    </Container>
  );
};

export default DropdownFilter;

import React, { useCallback, useMemo, useRef, useState, useEffect } from "react";
import { ContentState, convertFromHTML, EditorState, SelectionState } from "draft-js";

import "@draft-js-plugins/mention/lib/plugin.css";
import "./Mention.scss";

import Editor from "@draft-js-plugins/editor";
import createMentionPlugin, { defaultSuggestionsFilter } from "@draft-js-plugins/mention";
import mentionsStyles from "./MentionsStyles.module.css";

import { MentionData } from "@draft-js-plugins/mention";
import { useList } from "@pankod/refine-core";
import { useAppContext } from "App.context/App.context";
import { useGlobalDataState } from "pages/ProposeTasks/state/global";
import { isEmpty } from "lodash";
import { customSuggestionsFilter } from "../utils";

interface MentionProps {
  open: boolean;
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  customFunc?: React.Dispatch<React.SetStateAction<any>>;
  getData?: React.Dispatch<React.SetStateAction<MentionData | null>>;
  oldAssignee?: MentionData | null;

  onSelectMention?: (data: MentionData) => void;
}

export const Mention: React.FC<MentionProps> = ({
  open,
  setOpen,
  oldAssignee,
  getData,
  customFunc,
  onSelectMention,
}) => {
  const AppContext = useAppContext();
  const { users } = useGlobalDataState();

  const [editorState, setEditorState] = useState(() => {
    const blocksFromHTML = convertFromHTML("<p>@</p>");
    const state = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);
    return EditorState.createWithContent(state);
    // return EditorState.createEmpty()
  });
  //Get users------------------------------------------------------------------------------

  const getMentionsData = useCallback(() => {
    if (!isEmpty(users)) {
      return Object.values(users);
    }
    if (AppContext?.mentionsData) {
      return AppContext?.mentionsData;
    }

    return [];
  }, [AppContext, users]);

  const mentionsData = getMentionsData();

  // Mention-------------------------------------------------------------------------------
  const ref = useRef<Editor>(null);

  const [suggestions, setSuggestions] = useState(mentionsData);

  const { MentionSuggestions, plugins } = useMemo(() => {
    const mentionPlugin = createMentionPlugin({
      entityMutability: "IMMUTABLE",
      theme: mentionsStyles,
      mentionTrigger: "@",
      mentionPrefix: "@",
      supportWhitespace: true,
    });
    const { MentionSuggestions } = mentionPlugin;
    const plugins = [mentionPlugin];
    return { plugins, MentionSuggestions };
  }, []);

  const onChange = useCallback((_editorState: EditorState) => {
    setOpen && setOpen(false);
    setEditorState(_editorState);
  }, []);
  const onOpenChange = useCallback((_open: boolean) => {
    setOpen && setOpen(_open);
  }, []);
  const onSearchChange = useCallback(({ value }: { value: string }) => {
    setSuggestions(customSuggestionsFilter(value, mentionsData));
  }, []);

  //----------------------------------------------------------------------------

  const handleBlur = () => {
    setOpen && setOpen(false);
    customFunc && customFunc(false);

    getData && oldAssignee && getData({ ...oldAssignee, type: "blur" });
  };

  useEffect(() => {
    setTimeout(() => {
      setEditorState((prev) => EditorState.moveFocusToEnd(prev));
      setOpen && setOpen(true);
    }, 0);
  }, []);

  return (
    <div className="mention-container">
      <Editor editorState={editorState} onChange={setEditorState} ref={ref} plugins={plugins} onBlur={handleBlur} />

      <MentionSuggestions
        open={open}
        onOpenChange={onOpenChange}
        suggestions={suggestions}
        onSearchChange={onSearchChange}
        onAddMention={(data) => {
          getData && getData(data);
          setOpen && setOpen(false);
          customFunc && customFunc(false);

          onSelectMention && onSelectMention(data);
        }}
      />
    </div>
  );
};

import Editor from "@draft-js-plugins/editor";
import createLinkifyPlugin from "@draft-js-plugins/linkify";
import "@draft-js-plugins/linkify/lib/plugin.css";
import createMentionPlugin, { defaultSuggestionsFilter, MentionData } from "@draft-js-plugins/mention";
import "@draft-js-plugins/mention/lib/plugin.css";
import { message } from "@pankod/refine-antd";
import { BaseRecord, useCreate, useList, useUpdate } from "@pankod/refine-core";
import { MentionHasmap, useAppContext } from "App.context/App.context";
import { USER_ID } from "authProvider";
import axios from "axios";
import { useMyTask } from "components/Mytask/Context/MyTask.context";
import { useMyTaskFooter } from "components/Mytask/Context/MyTask.footer.context";
import ApiConstants from "constanst";
import {
  CompositeDecorator,
  ContentState,
  convertFromHTML,
  convertToRaw,
  DraftInlineStyle,
  EditorState,
  Modifier,
} from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import useDebounce from "hooks/useDebounce";
import linkifyIt from "linkify-it";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { MdAttachFile } from "react-icons/md";
import tlds from "tlds";
import { BLOCK_TYPES, INLINE_STYLES } from "../../DescTextEditor/data";
import { File } from "./File/File";
import "./FooterTextEdit.scss";
import { FooterTextEditButton } from "./FooterTextEditSubComponents";
import mentionsStyles from "./MentionsStyles.module.css";
import {
  createMentionEntities,
  getUserIdentity,
  getUserName,
  onStyleIconMouseDown,
  sendComment,
  updateComment,
  updateDescription,
} from "./untils";
import { io, Socket } from "socket.io-client";
import { SocketTeamContext } from "socket/socketcontext";
import _, { result } from "lodash";
import { DESCRIPTIONCONTENT } from "components/Mytask/Constant";
import { customSuggestionsFilter } from "components/Mytask/utils";

interface FooterTextEditProps {
  setComments?: React.Dispatch<React.SetStateAction<BaseRecord[]>>;
  comments?: BaseRecord[];
  taskId?: number | string | null;
  setUploadedPercent?: React.Dispatch<React.SetStateAction<number>>;
  mentionsData?: MentionData[];
  type?: string;
  setShowCmtEditor?: React.Dispatch<React.SetStateAction<boolean>>;
  cmtContent?: string;
  cmtId?: number;
  setCmtContent?: React.Dispatch<React.SetStateAction<string>>;
  detailsRef?: React.RefObject<HTMLDivElement> | undefined;
  mentionsDataHasmap?: MentionHasmap;
  tasksDataHasmap?: MentionHasmap;
  setFileList?: React.Dispatch<React.SetStateAction<BaseRecord[]>>;
  socket?: Socket;
  content?: string;
  resourcesid?: number;
  setValueDescription?: any;
  setHideEditorDescription?: any;
  valueDescription?: any;
}

const urlWorkingspaceArr = [ApiConstants.BASE_URL_CLIENT, "w.familyhospital.vn", "workspace.familyhospital.vn"];

const fieldUrls: any = {
  "mytask/show": (pathName: string, mentionsData: any) => getUserInfo(pathName, mentionsData),
};

const replaceHttp = (path: string) => {
  return path.replace("https://", "").replace("http://", "");
};

const checkIsUrl = (url: string) => {
  let check = false;
  for (let item of urlWorkingspaceArr) {
    if (url.includes(item)) {
      check = true;
      break;
    }
  }
  return check;
};

const handleGetUser = (url: string, tasksDataHasmap: any) => {
  let hostName = replaceHttp(ApiConstants.BASE_URL_CLIENT);
  if (window.location.host === hostName || checkIsUrl(url)) {
    for (let key in fieldUrls) {
      if (url.includes(key)) {
        return fieldUrls[key](url, tasksDataHasmap);
      }
    }
  }
};
// getbyuserID
const getUserInfo = (pathName: string, tasksDataHasmap: any) => {
  const id: string | undefined = pathName.split("/").pop();

  const user = tasksDataHasmap[id as string];

  return { ...user, name: `@${user?.name}` };
};

export const FooterTextEditHOC: React.FC<FooterTextEditProps> = ({
  type,
  cmtId,
  setCmtContent,
  setShowCmtEditor,
  cmtContent,
  socket,
  content,
  resourcesid,
  setValueDescription,
  setHideEditorDescription,
  valueDescription,
}) => {
  const values = useMyTask();
  const AppContext = useAppContext();
  const footerContext = useMyTaskFooter();
  const comments = values?.comments;
  const setComments = values?.setComments;
  const taskId = values?.currentTaskDetailId;

  const setUploadedPercent = footerContext?.setUploadedPercent;
  const mentionsData = AppContext?.mentionsData;
  const mentionsDataHasmap = AppContext?.mentionsDataHasmap;
  const tasksDataHasmap = AppContext?.tasksDataHasmap;
  const detailsRef = values?.detailsRef;
  const setFileList = values?.setFileList;
  const value = SocketTeamContext();

  return (
    <FooterTextEdit
      comments={comments}
      setComments={setComments}
      taskId={typeof taskId === "number" ? taskId : undefined}
      setUploadedPercent={setUploadedPercent}
      mentionsData={mentionsData}
      type={type}
      setShowCmtEditor={setShowCmtEditor}
      cmtContent={cmtContent}
      cmtId={cmtId}
      setCmtContent={setCmtContent}
      detailsRef={detailsRef}
      mentionsDataHasmap={mentionsDataHasmap}
      tasksDataHasmap={tasksDataHasmap}
      setFileList={setFileList}
      socket={value?.socket}
      content={content}
      resourcesid={resourcesid}
      setValueDescription={setValueDescription}
      setHideEditorDescription={setHideEditorDescription}
      valueDescription={valueDescription}
    />
  );
};

const FooterTextEdit: React.FC<FooterTextEditProps> = ({
  comments,
  setComments,
  taskId,
  setUploadedPercent,
  mentionsData,
  type,
  setShowCmtEditor,
  cmtContent,
  cmtId,
  setCmtContent,
  detailsRef,
  mentionsDataHasmap,
  tasksDataHasmap,
  setFileList,
  socket,
  content,
  resourcesid,
  setValueDescription,
  setHideEditorDescription,
  valueDescription,
}) => {
  const findLinkEntities = (contentBlock: any, callback: any, contentState: any) => {
    contentBlock.findEntityRanges((character: any) => {
      const entityKey = character.getEntity();

      return entityKey !== null && contentState.getEntity(entityKey).getType() === "LINK";
    }, callback);
  };

  const Link = (props: any) => {
    const { url } = props.contentState.getEntity(props.entityKey).getData();
    // if(!user){
    //   return <a href={url} >{props.children}</a>;
    // }
    // if(props.decoratedText !== user?.name){
    //   setTimeout(() => {
    //     setEditorState(replaceText(editorState, user?.name, editorState.getCurrentInlineStyle(),props.entityKey,true))
    //   }, 100);
    // }
    return <a href={url}>{props.children}</a>;
  };

  const replaceText = (
    editorState: EditorState,

    text: string,
    inlineStyle: DraftInlineStyle,
    entityKey: string,
    forceSelection: boolean
  ): EditorState => {
    const contentState = Modifier.replaceText(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      text,
      inlineStyle,
      entityKey
    );
    return EditorState.push(editorState, contentState, "insert-characters");
  };

  const decorator = new CompositeDecorator([
    {
      strategy: findLinkEntities,
      component: Link,
    },
  ]);
  const [iseDraggingOver, setisDraggingOver] = useState(false);

  const [editorState, setEditorState] = useState(() => {
    if (!type || !cmtContent) {
      return EditorState.createEmpty();
    }

    return EditorState.createWithContent(createMentionEntities([], cmtContent));
  });
  const [editorStateDescription, setEditorStateDescription] = useState(() => EditorState.createEmpty());
  const [oldTextValue, setOldTextValue] = useState<string>("");
  const [editorText, setEditorText] = useState<string>("");

  const [commentAfterInsertId, setCommentAfterInsertId] = useState<null | number | string>(null);
  const [editInnerWarpperHeight, setEditInnerWarpperHeight] = useState(88);
  const [showTollBar, setShowTollBar] = useState(() => {
    if (!type) {
      return false;
    }
    return true;
  });
  const [Files, setFiles] = useState<File[]>([]);
  const createFunc = useCreate();
  const { mutateAsync: updateFunc, mutate } = useUpdate();
  const innerRef = useRef<HTMLDivElement>(null);
  const values = useMyTask();

  //----------------------

  //Lấy mention IDS
  const [getRequest, setGetRequest] = useState(false);

  let mentionIdsRes = useList({
    resource: "resourcesmentions",
    queryOptions: { enabled: getRequest, cacheTime: 0 },

    config: {
      filters: [
        {
          field: "resourcesId",
          operator: "eq",
          value: cmtId,
        },
        {
          field: "isComment",
          operator: "eq",
          value: true,
        },
      ],
      pagination: { current: 1, pageSize: 1500 },
    },
    metaData: {
      config: {
        fields: ["memberId"],
      },
    },
  }).data;

  useEffect(() => {
    type && cmtId && setGetRequest(true);
  }, [type]);

  useEffect(() => {
    if (mentionIdsRes && mentionsData) {
      const mentionInfos = getUserName({ mentionsData, mentionIdsRes });

      if (cmtContent) {
        const newState = EditorState.createWithContent(createMentionEntities(mentionInfos, cmtContent));
        setEditorState(newState);
      }
    }
  }, [mentionIdsRes]);

  //Custom textEdit style button
  const handleChangeStyle = (name: string) => {
    if (content == DESCRIPTIONCONTENT) {
      onStyleIconMouseDown(name, { editorState: editorStateDescription, setEditorState: setEditorStateDescription });
    } else {
      onStyleIconMouseDown(name, { editorState, setEditorState });
    }
  };

  // const [editorHeight, setEditorHeight] = useState(22);
  const [editWarpperHeight, setEditWarpperHeight] = useState(() => {
    if (type) {
      return 96 + 52;
    } else {
      if (comments && comments.length > 0) {
        return 32;
      }
      return 96;
    }
  });

  useEffect(() => {
    getDescription();
  }, [values?.currentTaskDetailId, valueDescription]);

  const getDescription = async () => {
    const description: any =
      values?.currentTaskDetailId &&
      (await axios.get(`resourcesdetails/description-by-resouceid?resouceId=${values?.currentTaskDetailId}`));
    if (description?.data) {
      const blocksFromHTML = convertFromHTML(description?.data);

      const state = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);

      const updatesEditor = EditorState.createWithContent(state);

      setEditorStateDescription(updatesEditor);
      setValueDescription && setValueDescription(description?.data);
    } else {
      const updatesEditor = EditorState.createEmpty();
      setEditorStateDescription(updatesEditor);
    }
  };

  const handleFocus = () => {
    if (content == DESCRIPTIONCONTENT) {
      setShowTollBar(true);
      const contentState = editorStateDescription.getCurrentContent();
      const editorHTML = stateToHTML(contentState);
      const editorTextToRaw = convertToRaw(contentState).blocks[0].text;
      setOldTextValue(editorHTML);
      setEditorText(editorTextToRaw);
    } else {
      if (!type) {
        setShowTollBar(true);
        setEditWarpperHeight(96 + 52);
        setEditInnerWarpperHeight(250);

        if (Files.length > 0) {
          setEditWarpperHeight(140 + 52);
          // setEditInnerWarpperHeight(132);
        }
      }
    }
  };

  const handleBlur = () => {
    if (content == DESCRIPTIONCONTENT) {
      const contentState = editorStateDescription.getCurrentContent();
      const editorTextToRaw = convertToRaw(contentState).blocks[0].text;
      setShowTollBar(false);
      setHideEditorDescription(true);
      updateDescription({
        editorStateDescription,
        oldTextValue,
        resourcesid,
        editorText,
        mutate,
        setValueDescription,
        editorTextToRaw,
      });
      setTimeout(() => {
        getDescription();
      }, 100);
    } else {
      if (!type) {
        if (comments && comments.length > 0 && !(Files.length > 0)) {
          setEditWarpperHeight(32);
          // setEditInnerWarpperHeight(32);
        } else {
          setEditWarpperHeight(96);
        }

        // if (Files.length > 0) {
        //   setEditInnerWarpperHeight(88);
        // }

        setEditInnerWarpperHeight(88);

        setShowTollBar(false);
        setisDraggingOver(false);
      }
    }
  };

  useEffect(() => {
    if (!type) {
      Files.length > 0 && setEditWarpperHeight(140 + 52);
      Files.length > 0 && setEditInnerWarpperHeight(250);
      innerRef.current?.scrollTo(0, innerRef.current.scrollHeight);

      if (Files.length === 0) {
        if (comments && comments.length > 0) {
          setEditWarpperHeight(32);
        } else {
          setEditWarpperHeight(96);
          setEditInnerWarpperHeight(88);
        }
      }
    }
  }, [Files]);
  //--------------------------------------------------------------------------------------------------------------

  const fileRef = useRef<HTMLInputElement>(null);

  //Handle func
  const handleAddFile = (e: React.MouseEvent) => {
    e.preventDefault();
    fileRef.current && fileRef.current.click();
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget.files) {
      const files = e.currentTarget.files;
      let filesArr: File[] = [];

      for (const key in files) {
        if (Object.prototype.hasOwnProperty.call(files, key)) {
          const element = files[key];
          filesArr.push(element);
        }
      }

      if (filesArr.length > 10) {
        message.error("Please select up to 10 files !");
        return;
      }
      setFiles((prev) => {
        return [...prev, ...filesArr];
      });
    }
  };

  const handleSubMitComment = () => {
    if (typeof taskId === "number") {
      sendComment({
        taskId,
        editorState,
        Files,
        setComments,
        createFunc,
        setCommentAfterInsertId,
        setEditorState,
        setUploadedPercent,
        setFiles,
        detailsRef,
        mentionsDataHasmap,
        setIsCmtLoading,
        setFileList,
      });
    }
  };

  const handleUpdateComment = () => {
    updateComment({
      editorState,
      cmtId,
      setCmtContent,
      setShowCmtEditor,
      updateFunc,
      setComments,
      mentionsDataHasmap,
    });
  };

  const handleMouseDown = (e: React.MouseEvent) => {
    e.preventDefault();
    ref.current?.focus();
    // innerRef.current?.scrollTo(0, 0)
  };

  // Xử lý khi insert thành công và thất bại
  useEffect(() => {
    const excute = async () => {
      if (typeof commentAfterInsertId === "number" && comments) {
        const newComments = [...comments];
        const commentAfterInsertIndex = newComments.length - 1;
        const { id } = await getUserIdentity();

        const newCommentAfterInsert: BaseRecord = {
          id: commentAfterInsertId,
          resources: {
            id: commentAfterInsertId,
            name: newComments[commentAfterInsertIndex].resources.name,
            createdby: id,
            createdAt: new Date().toISOString(),
          },
          resourcesid: commentAfterInsertId,
        };

        newComments.splice(commentAfterInsertIndex, 1, newCommentAfterInsert);
        setComments && setComments(newComments);
        setCommentAfterInsertId(null);
        return;
      }

      if (commentAfterInsertId === "error" && comments) {
        const oldComments = [...comments];
        const commentAfterInsertIndex = oldComments.length - 1;

        oldComments.splice(commentAfterInsertIndex, 1);
        setComments && setComments(oldComments);
        setCommentAfterInsertId(null);
      }
    };
    excute();
  }, [commentAfterInsertId]);

  // Mention-------------------------------------------------------------------------------
  const ref = useRef<Editor>(null);

  const [open, setOpen] = useState(false);
  const [suggestions, setSuggestions] = useState(() => {
    if (mentionsData) {
      return mentionsData;
    }

    return [];
  });

  const { MentionSuggestions, plugins } = useMemo(() => {
    const mentionPlugin = createMentionPlugin({
      entityMutability: "IMMUTABLE",
      theme: mentionsStyles,
      mentionPrefix: "@",
      supportWhitespace: true,
    });

    const linkifyPlugin = createLinkifyPlugin({
      component(props) {
        return <a {...props} onClick={() => alert("Clicked on Link!")} />;
      },
      customExtractLinks: (text) => linkifyIt().tlds(tlds).set({ fuzzyEmail: false }).match(text),
    });

    const { MentionSuggestions } = mentionPlugin;
    const plugins = [mentionPlugin, linkifyPlugin];

    return { plugins, MentionSuggestions };
  }, []);

  const onChange = useCallback((_editorState: EditorState) => {
    setEditorState(_editorState);
  }, []);
  const onOpenChange = useCallback((_open: boolean) => {
    setOpen(_open);
  }, []);
  const onSearchChange = useCallback(({ value }: { value: string }) => {
    setSuggestions(customSuggestionsFilter(value, mentionsData || []));
  }, []);

  const [notifiedPeople, setNotifiedPeople] = useState<BaseRecord[]>([]);

  useEffect(() => {
    const getCount = async () => {
      const res = await axios.get(`resourcesfollowers/count/${taskId}`);
      res.data && setNotifiedPeople(res.data);
    };
    taskId && getCount();
  }, [taskId]);

  //Comment loading
  const [isCmtLoading, setIsCmtLoading] = useState(false);

  const [isFirst, setIsFirst] = useState(true);

  //Save typing text
  const contentState = editorState.getCurrentContent();
  const debounce = useDebounce(stateToHTML(contentState), 1000);

  useEffect(() => {
    const currentEditorText = convertToRaw(contentState).blocks[0].text;
    const saveTypingText = async () => {
      const data = {
        resourceId: taskId,
        typingtext: debounce,
      };

      await axios.post("resources/typingtext", data);
    };

    if (!isFirst) {
      if (!type) {
        saveTypingText();
      }
    } else {
      setIsFirst(false);
    }
  }, [debounce]);

  //Get typing-text
  useEffect(() => {
    const getTypingText = async () => {
      const Res: any = taskId && (await axios.get(`resources/typingtext/${taskId}`));
      if (!Res?.data) {
        return;
      }
      const blocksFromHTML = convertFromHTML(Res?.data);
      const state = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);
      const updatesEditor = EditorState.createWithContent(state);
      setEditorState(updatesEditor);
    };

    if (!type) {
      // Khi edit comment thì không gọi api
      getTypingText();
    }
  }, []);

  const onDragOver = (e: any) => {
    e.preventDefault();
    e.dataTransfer.dropEffect = "copy";
    setisDraggingOver(true);
  };
  const onDrop = (e: any) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (e.dataTransfer.files) {
      const files = e.dataTransfer.files;
      let filesArr: File[] = [];

      for (const key in files) {
        if (Object.prototype.hasOwnProperty.call(files, key)) {
          const element = files[key];
          filesArr.push(element);
        }
      }

      if (filesArr.length > 10) {
        message.error("Please select up to 10 files !");
        return;
      }
      setFiles((prev) => {
        return [...prev, ...filesArr];
      });
      setisDraggingOver(false);
    }
  };

  const onDragLeave = () => {
    setisDraggingOver(false);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (content == DESCRIPTIONCONTENT && ref.current) {
        // focus cuối dòng
        setEditorState(EditorState.moveFocusToEnd(EditorState.createEmpty()));
        ref.current.focus();
      }
    }, 100);
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <div className="footer-editor-container" style={{ minHeight: editWarpperHeight }}>
        <div
          className={"footer-editor-wapper "}
          style={{
            maxHeight: editInnerWarpperHeight,
            marginBottom: showTollBar ? "54px" : 0,
            border: iseDraggingOver ? "2px dashed #181313" : "none",
          }}
          ref={innerRef}
          onDragOver={onDragOver}
          onDrop={onDrop}
          onDragLeave={onDragLeave}
        >
          <div id="areadropfile" style={{ display: iseDraggingOver ? "block" : "none" }}>
            Drop file here
          </div>

          <Editor
            editorKey={"editor"}
            editorState={content == DESCRIPTIONCONTENT ? editorStateDescription : editorState}
            onChange={content == DESCRIPTIONCONTENT ? setEditorStateDescription : setEditorState}
            ref={ref}
            spellCheck={false}
            plugins={plugins}
            onFocus={handleFocus}
            onBlur={handleBlur}
            placeholder={
              iseDraggingOver
                ? ""
                : content == DESCRIPTIONCONTENT
                ? "Add more detail to this task..."
                : "Ask question or post an update..."
            }
            decorators={[decorator]}
          />

          {content !== DESCRIPTIONCONTENT && (
            <MentionSuggestions
              open={open}
              onOpenChange={onOpenChange}
              suggestions={suggestions}
              onSearchChange={onSearchChange}
              onAddMention={() => {}}
            />
          )}

          {Files?.length > 0 && (
            <div className="file" onMouseDown={handleMouseDown}>
              {Files.map((file) => {
                const fileType = file?.name.split(".").pop();
                let imgUrl;
                if (fileType === "png" || fileType === "jpeg" || fileType === "jpg" || fileType === "pjpeg") {
                  imgUrl = URL.createObjectURL(file);
                }
                return (
                  <File
                    setShowTollBar={setShowTollBar}
                    id={file.lastModified}
                    editorRef={ref}
                    setFiles={setFiles}
                    name={file?.name}
                    type={file.type}
                    key={file?.name}
                    imgUrl={imgUrl}
                  />
                );
              })}
            </div>
          )}
        </div>

        <input type="file" style={{ display: "none" }} ref={fileRef} onChange={handleInputChange} multiple />

        {showTollBar && (
          <div className="footerEdit-toolbar" onMouseDown={(e) => e.preventDefault()} style={{ border: "1px" }}>
            <div className="ft-toolbar-icons">
              <InlineStyleControl
                editorState={content == DESCRIPTIONCONTENT ? editorStateDescription : editorState}
                onStyleBtnClick={handleChangeStyle}
              />
              <BlockStyleControl
                editorState={content == DESCRIPTIONCONTENT ? editorStateDescription : editorState}
                onStyleBtnClick={handleChangeStyle}
              />
              {!type && content != DESCRIPTIONCONTENT && (
                <MdAttachFile className="textEdit-icon" onMouseDown={handleAddFile} />
              )}
            </div>

            {content != DESCRIPTIONCONTENT && (
              <FooterTextEditButton
                handleComment={handleSubMitComment}
                type={type}
                setShowCmtEditor={setShowCmtEditor}
                handleUpdateComment={handleUpdateComment}
                notifiedPeople={notifiedPeople}
                isCmtLoading={isCmtLoading}
              />
            )}
          </div>
        )}
      </div>
    </>
  );
};

//------------------------------------------------------------------

interface InlineStyleControlProps {
  active?: boolean;
  editorState: EditorState;
  onStyleBtnClick: (name: string) => void;
}

const InlineStyleControl: React.FC<InlineStyleControlProps> = ({ editorState, onStyleBtnClick }) => {
  const currentStyles = editorState.getCurrentInlineStyle();
  return (
    <>
      {INLINE_STYLES.map((type) => {
        const Icon = type.Icon;
        let className = "textEdit-icon";
        const active = currentStyles.has(type.style);
        if (active) {
          className += " active";
        }

        return (
          <Icon
            key={type.style}
            className={className}
            onMouseDown={(e) => {
              e.preventDefault();
              onStyleBtnClick(type.style);
            }}
          />
        );
      })}
    </>
  );
};

const BlockStyleControl: React.FC<InlineStyleControlProps> = ({ editorState, onStyleBtnClick }) => {
  const selection = editorState.getSelection();
  const blockType = editorState.getCurrentContent().getBlockForKey(selection.getStartKey()).getType();
  return (
    <>
      {BLOCK_TYPES.map((type) => {
        const Icon = type.Icon;
        let className = "textEdit-icon";
        const active = type.style === blockType;
        if (active) {
          className += " active";
        }

        return (
          <Icon
            key={type.style}
            className={className}
            onMouseDown={(e) => {
              e.preventDefault();
              onStyleBtnClick(type.style);
            }}
          />
        );
      })}
    </>
  );
};

import './index.scss'
import { message } from '@pankod/refine-antd'
import DateBox from 'devextreme-react/date-box'
import { useEffect, useRef, useState } from 'react'
import { File } from 'components/Mytask/mtDetails/DetailsFooter/FooterTextEdit/File/File'
import SimpleMentionEditor from './MentionForm'
import { useList } from '@pankod/refine-core'
import { MentionData } from '@draft-js-plugins/mention'
import { useFetThongTinUser } from './customhook/callapimention'
import { useFetCreactFromTask } from './customhook/callapicopytask'
import { fetchDataTaskName } from './customhook/insertformdexuat'
import { ContentState, Editor, EditorState, convertToRaw } from 'draft-js'
import { stateToHTML } from 'draft-js-export-html'
import { useRouterContext, useNavigation } from '@pankod/refine-core'
import dayjs from 'dayjs'
import { BsCheckCircle } from 'react-icons/bs'
import Loading from 'components/Mytask/Loading/Loading'
interface FormData {
    tentask: string
    hoten: string
    phongban: string
    reason: string
    completionDate: string
    Files: File[]
    idMention: any
}
export const FromTaoDeXuat: React.FC = () => {
    const { useParams } = useRouterContext()
    const [dataUser, setDataUser] = useState<any>()
    const [dataTask, setDataTask] = useState<any>()
    const [nameMention, setNameMention] = useState<any>([])
    const [menTionId, setMenTionId] = useState<any>([])
    const [editorText, setEditorText] = useState('')
    const params: any = useParams()
    const [sucCess, setSucCess] = useState(false)
    const [resultDataResponse, setResultDataResponse] = useState<any>()
    const [loading, setLoading] = useState(true)
    const { push } = useNavigation()
    useFetThongTinUser(setDataUser)
    useFetCreactFromTask(setDataTask, params)
    const [editorState, setEditorState] = useState(EditorState.createEmpty())
    const [editorStateMention, setEditorStateMention] = useState(EditorState.createEmpty())
    const editorContentRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        const contentHeight = editorContentRef.current?.scrollHeight || 0
        if (contentHeight > 122) {
            editorContentRef.current?.classList.add('overflow-auto')
        } else {
            editorContentRef.current?.classList.remove('overflow-auto')
        }
    }, [editorState])

    const onEditorChange = (newEditorState: EditorState) => {
        setEditorState(newEditorState)
    }
    const mentions = useList({
        resource: 'resources',
        config: {
            filters: [
                {
                    field: 'resourcetype',
                    operator: 'eq',
                    value: 'user'
                }
            ],
            pagination: { current: 1, pageSize: 1500 }
        },
        metaData: {
            config: {
                fields: ['name', 'id']
            }
        }
    }).data as MentionData | undefined
    const [Files, setFiles] = useState<File[]>([])
    const dataMention: MentionData[] = mentions?.data
    const [formData, setFormData] = useState<FormData>({
        tentask: '',
        hoten: '',
        phongban: '',
        reason: '',
        completionDate: '',
        Files: [],
        idMention: null
    })

    const [formErrors, setFormErrors] = useState<{
        tentask: string
        reason: string
        completionDate: string
    }>({
        tentask: '',
        reason: '',
        completionDate: ''
    })

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = event.target
        setFormData({
            ...formData,
            hoten: dataUser?.name,
            phongban: dataUser?.tenPhongBang,
            idMention: 1,
            [name]: value.toUpperCase()
        })

    }

    const handleDateChange = (value: any) => {
        setFormData({
            ...formData,
            completionDate: value
        })
    }
    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        const options = {
            entityStyleFn: (entity: any) => {
                const entityType = entity.get('type').toLowerCase()

                if (entityType === 'mention') {
                    return {
                        element: 'a',
                        attributes: {
                            href: ``
                        }
                    }
                }
            }
        }

        const contentStateMention = editorStateMention.getCurrentContent()
        let editorHTML = stateToHTML(contentStateMention, options)
        const contentState = editorState.getCurrentContent()
        // Chuyển đổi nội dung sang HTML
        const contentAsHtml = stateToHTML(contentState)
        const errors: {
            tentask: string
            reason: string
            completionDate: string
        } = {
            tentask: '',
            reason: '',
            completionDate: ''
        }

        if (!formData.tentask.trim()) {
            errors.tentask = 'Tên task không được để trống'
        }
        if (!editorState.getCurrentContent().hasText()) {
            errors.reason = 'Lý do đề xuất không được để trống'
        }
        if (!formData.completionDate) {
            errors.completionDate = 'Thời gian hoàn thành không được để trống'
        }

        if (errors.tentask || errors.completionDate) {
            setFormErrors(errors)
        } else {
            setFormErrors({
                tentask: '',
                reason: '',
                completionDate: ''
            })

            const formatDate = dayjs(formData?.completionDate).format('DD/MM/YYYY')
            let resultComment = `
            <p style="font-weight: bold;">Họ tên người đề xuất:</p>
            <p style="padding-bottom: 10px;">${formData?.hoten}</p>
            <p style="font-weight: bold;">Phòng ban tạo đề xuất:</p> 
            <p style="padding-bottom: 10px;">${formData?.phongban}</p>
            <p style="font-weight: bold;">Lý do đề xuất:</p>
            <p>${contentAsHtml}</p>
            <p style="font-weight: bold;padding-top: 10px">Thời gian mong muốn hoàn thành đề xuất:</p>
            <p style="padding-bottom: 10px;">${formatDate}</p>
            <p style="font-weight: bold;">Mention người liên quan:</p>
            <p>${editorHTML}</p>
             `
            const taskData = {
                name: formData?.tentask,
                resourcetype: 'task',
                mytaskId: params?.id,
                mentions: menTionId,
                comment: resultComment
            }
            const filesData = new FormData()
            filesData.append('name', formData?.tentask)
            filesData.append('comment', resultComment)
            filesData.append('hasFile', 'true')
            filesData.append('mytaskId', taskData?.mytaskId.toString())
            filesData.append('resourcetype', 'task')
            filesData.append('mentions', menTionId)
            for (var i = 0; i < Files.length; i++) {
                filesData.append('workspace_file', Files[i])
            }
            fetchDataTaskName(filesData, setFormData, setFiles, Files, setSucCess, setResultDataResponse, setLoading)
            setEditorState(EditorState.createEmpty())
            setEditorStateMention(EditorState.createEmpty())
        }
    }

    const handleInputChangeFiles = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.currentTarget.files) {
            const files = e.currentTarget.files
            let filesArr: File[] = []

            for (const key in files) {
                if (Object.prototype.hasOwnProperty.call(files, key)) {
                    const element = files[key]
                    filesArr.push(element)
                }
            }

            if (filesArr.length > 10) {
                message.error('Please select up to 10 files !')
                return
            }
            setFiles((prev) => {
                return [...prev, ...filesArr]
            })
        }
    }
    const handleClickButtonFullScreen = () => {
        for (const result of resultDataResponse?.dataDetail) {
            if (result?.type?.id == 1) {
                const taskId = resultDataResponse?.resourceId
                const projectId = result?.type?.projectId
                push(`/task/show/${taskId}?projectid=${projectId}&resource=Inbox&fullscreen=true`)
            }
        }
    }

    function renderFromUiReload() {
        if (loading) {
            return (
                <div className="loading">
                    <Loading />
                </div>
            )
        }
        return (
            <>
                <div className="success">
                    <div style={{ color: ' #00ffa9', paddingRight: '10px', paddingTop: '8px' }}>
                        <BsCheckCircle />
                    </div>{' '}
                    <div> Tạo đề xuất form thành công.</div>
                </div>

                <div className="button-container">
                    <button className="button" onClick={handleClickButtonFullScreen}>
                        <h1 className="button_success"> Full screen</h1>
                    </button>
                </div>
            </>
        )
    }

    return (
        <>
            {sucCess ? (
                <div className="container_form_de_xuat_success">
                    <div className="container_success">{renderFromUiReload()}</div>
                </div>
            ) : (
                <div className="container_form_de_xuat">
                    <div className="container_form_de_xuat_fixed">
                        <div className="container_form_de_xuat_scroll">
                            {' '}
                            <form className="form_de_xuat" onSubmit={handleSubmit}>
                                <label className="label-form">
                                    {dataTask?.name ? (
                                        <h2 style={{ fontSize: '2rem' }} className="fontChu">
                                            {dataTask?.name}
                                        </h2>
                                    ) : (
                                        <h2 style={{ fontSize: '2rem' }} className="fontChu">
                                            Form tạo đề xuất mới
                                        </h2>
                                    )}
                                </label>
                                <label style={{ width: '85%' }}>
                                    <div className="fname fontChu">
                                        Tên task <span className="required">*</span>
                                    </div>
                                    <div>
                                        <input
                                            type="text"
                                            name="tentask"
                                            value={formData?.tentask}
                                            placeholder="Enter your answer"
                                            onChange={handleInputChange}
                                            className="input_de_xuat"
                                        />
                                        {formErrors.tentask && <div className="error">{formErrors.tentask}</div>}
                                    </div>
                                </label>
                                <label style={{ width: '85%' }}>
                                    <div className="fname fontChu">
                                        Họ tên người đề xuất <span className="required">*</span>
                                    </div>
                                    <div>
                                        <input
                                            type="text"
                                            name="hoten"
                                            value={dataUser?.name}
                                            className="input_de_xuat"
                                            onChange={handleInputChange}
                                            disabled
                                        />
                                    </div>
                                </label>
                                <label style={{ width: '85%' }}>
                                    <div className="fname fontChu">
                                        Phòng ban tạo đề xuất <span className="required">*</span>
                                    </div>
                                    <div>
                                        <input
                                            type="text"
                                            value={dataUser?.tenPhongBang}
                                            className="input_de_xuat"
                                            name="phongban"
                                            onChange={handleInputChange}
                                            disabled
                                        />
                                    </div>
                                </label>
                                <label style={{ width: '85%' }}>
                                    <div className="fname fontChu">
                                        Lý do đề xuất <span className="required">*</span>
                                    </div>
                                    <div className="custom-editor-style" ref={editorContentRef}>
                                        <Editor
                                            editorState={editorState}
                                            onChange={onEditorChange}
                                            placeholder="Enter your answer"
                                        />
                                    </div>
                                    {formErrors.reason && <div className="error">{formErrors.reason}</div>}
                                </label>
                                <label style={{ width: '85%' }}>
                                    <div className="fname fontChu">
                                        Thời gian mong muốn hoàn thành đề xuất <span className="required">*</span>
                                    </div>
                                    <div className="dateBox">
                                        <DateBox
                                            type="date"
                                            onValueChange={handleDateChange}
                                            displayFormat={'dd/MM/yyyy'}
                                            min={dayjs().format('YYYY-MM-DD')}
                                            value={formData?.completionDate}
                                        ></DateBox>
                                        {formErrors.completionDate && (
                                            <div className="error">{formErrors.completionDate}</div>
                                        )}
                                    </div>
                                </label>
                                <label style={{ width: '85%' }}>
                                    <div className="fname fontChu">Đính kèm file</div>
                                    <div className="file-store">
                                        <div className="fix">
                                            <div className="file custom-file-upload">
                                                <input
                                                    className="input_de_xuat"
                                                    type="file"
                                                    // value={formData?.Files}
                                                    onChange={handleInputChangeFiles}
                                                    multiple
                                                    style={{ display: 'none' }}
                                                />
                                                <span>Select a file...</span>
                                            </div>
                                            <div className="file-here">or drag and drop a file here</div>
                                        </div>
                                        <div className="ahihi">
                                            {Files?.length > 0 && (
                                                <div className="file">
                                                    {Files.map((file) => {
                                                        const fileType = file?.name.split('.').pop()
                                                        let imgUrl
                                                        if (
                                                            fileType === 'png' ||
                                                            fileType === 'jpeg' ||
                                                            fileType === 'jpg' ||
                                                            fileType === 'pjpeg'
                                                        ) {
                                                            imgUrl = URL.createObjectURL(file)
                                                        }
                                                        return (
                                                            <File
                                                                // setShowTollBar={setShowTollBar}
                                                                id={file.lastModified}
                                                                // editorRef={ref}
                                                                setFiles={setFiles}
                                                                name={file?.name}
                                                                type={file.type}
                                                                key={file?.name}
                                                                imgUrl={imgUrl}
                                                            />
                                                        )
                                                    })}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </label>
                                <label style={{ width: '85%' }}>
                                    <div className="fname fontChu">Mention người liên quan</div>
                                    <div className="mention">
                                        <SimpleMentionEditor
                                            mentions={dataMention}
                                            setFormData={setFormData}
                                            formData={formData}
                                            setNameMention={setNameMention}
                                            setEditorStateMention={setEditorStateMention}
                                            editorStateMention={editorStateMention}
                                            setMenTionId={setMenTionId}
                                            setEditorText={setEditorText}
                                        />
                                    </div>
                                </label>
                                <div>
                                    <button className="fname button_de_xuat" type="submit">
                                        Submit
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

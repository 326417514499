import { USER_ID } from "authProvider";
import React, { createContext, useContext, useState, useEffect } from "react";

import { SocketTeamContext } from "./socketcontext";
import axios from "axios";
type Props = {
  children: React.ReactNode;
};
type Context = {
  // socket: Socket;
  isNewInbox?: any;
  setIsNewInbox?: React.Dispatch<React.SetStateAction<boolean>>;
  tokenKey?: string;
  setTokenKey?: React.Dispatch<React.SetStateAction<string>>;
  socketCmtId?: number;
  setSocketCmtId?: React.Dispatch<React.SetStateAction<number>>;
};
const NewInboxContextTeam = createContext<Context | null>(null);

function NewInboxContextProvider({ children }: Props) {
  const userId = localStorage.getItem(USER_ID);
  const socketTeamContext = SocketTeamContext();
  const [isNewInbox, setIsNewInbox] = useState(false);
  const [tokenKey, setTokenKey] = useState("");
  const [socketCmtId, setSocketCmtId] = useState(0);

  useEffect(() => {
    socketTeamContext?.socket?.on("getNewInboxClient", (response: any) => {
      setIsNewInbox(response?.isNew);
      setSocketCmtId(response?.commentId);
    });

    socketTeamContext?.socket?.on("getNewCommentClient", (response: any) => {
     
    });
  }, [socketTeamContext?.socket]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.get("resources/checkNewInbox");

      setIsNewInbox(response?.data?.isNew);
      setSocketCmtId(response?.data?.commentId);
    };
    fetchData();
  }, [userId]);

  return (
    <NewInboxContextTeam.Provider
      value={{
        // socket,
        isNewInbox,
        setIsNewInbox,
        tokenKey,
        setTokenKey,
        socketCmtId,
        setSocketCmtId,
      }}
    >
      {children}
    </NewInboxContextTeam.Provider>
  );
}
const NewInboxSocketTeamContext = () => useContext(NewInboxContextTeam);
export { NewInboxSocketTeamContext, NewInboxContextProvider };

import "./Subtask.scss";
import { BsPlusLg, BsTrash } from "react-icons/bs";

import { SubTaskRowHOC } from "./SubtaskRow/SubtaskRow";
import { SubTaskAddRowHOC } from "./SubtaskAddRow/SubtaskAddRow";
import { useState, useRef, useEffect } from "react";
import { BaseRecord, useList, useRouterContext } from "@pankod/refine-core";
import { useCreate } from "@pankod/refine-core";
import { useMyTask } from "components/Mytask/Context/MyTask.context";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { reorder } from "components/Mytask/utils";
import axios from "axios";

interface SubtaskProps {
  subTasks: BaseRecord[];
  setSubTasks: React.Dispatch<React.SetStateAction<BaseRecord[]>>;
  hasMore: boolean;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  setGetRequest: React.Dispatch<React.SetStateAction<boolean>>;
}

export const Subtask: React.FC<SubtaskProps> = ({
  subTasks,
  setSubTasks,
  setPage,
  hasMore,
  setGetRequest,
}) => {
  const [selectedSubTaskId, setSelectedSubTaskId] = useState<number | null>(
    null
  );
  const addSubTaskRef = useRef<HTMLDivElement>(null);
  const addSubTaskBtnRef = useRef<HTMLButtonElement>(null);
  const [newSubTaskAfterInserted, setNewSubTaskAfterInserted] =
    useState<BaseRecord | null>(null);
  const [newSubTask, setNewSubTask] = useState<BaseRecord | null>(null);
  const { mutate } = useCreate();
  const values = useMyTask();
  const { useLocation, useParams } = useRouterContext();
  const location = useLocation();
  const params = useParams() as BaseRecord;

  let projectId: number;
  const { search } = location;
  if (search.includes("&")) {
    const searchArr = search.split("&");
    const searchStr = searchArr.join("=");
    projectId = Number(searchStr.split("=")?.at(3));
  } else {
    const searchArr = search.split("=");
    projectId = Number(searchArr?.at(1));
  }

  //Khi có tín hiệu insert task mới thì gọi hàm inserttask để gọi api lưu subtask vào db
  useEffect(() => {
    if (newSubTask) {
      const taskData = {
        name: newSubTask.name,
        resourcetype: "subtask",
        mytaskId: values?.currentProjectId || projectId,
        biggestParentTaskId: values?.selectedTaskId || Number(params?.id),
      };

      insertSubTask(taskData);
    }
  }, [newSubTask]);

  const insertSubTask = (taskData: BaseRecord) => {
    if (typeof values?.currentTaskDetailId === "string") {
      alert("Vui lòng tạo Task trước khi thêm SubTask !");
      return;
    }
    mutate(
      {
        resource: `resources/inserttask/${values?.currentTaskDetailId}`,
        values: taskData,
        successNotification: {
          message: "Tạo SubTask thành công !",
          type: "success",
        },
      },

      {
        //Khi thành công sẽ tạo một task mới với id vừa nhận về từ server để thay thế cho id giả đã tạo trước đó
        onSuccess: (data, variables, context) => {
          const insertedSubtaskId = data.data.resourcesid;
          const newSubTaskAfterInserted = {
            id: insertedSubtaskId,
            order: data?.data?.orderNumber,
            resourcesid: insertedSubtaskId,
            resources: {
              name: newSubTask?.name,
              id: insertedSubtaskId,
            },
          };

          setNewSubTaskAfterInserted(newSubTaskAfterInserted);
        },
        onError: (error, variables, context) => {
          //Nếu có lỗi thì xóa task vừa thêm khỏi mảng chứa task

          const newSubTasks = [...subTasks];
          newSubTasks.splice(newSubTask?.currentSubTaskIndex, 1);
          setSubTasks([...newSubTasks]);

          //Sau đó set 2 biến dưới về trạng thái ban đầu
          setNewSubTask(null);
          setNewSubTaskAfterInserted(null);
        },
      }
    );
  };

  useEffect(() => {
    //Khi có tín hiệu từ newSubTaskAfterInserted sẽ lấy index từ newSubtask để thay thế task id giả thành task có id thật
    if (newSubTaskAfterInserted) {
      const newSubTasks = [...subTasks];
      newSubTasks.splice(
        newSubTask?.currentSubTaskIndex,
        1,
        newSubTaskAfterInserted
      );
      setSubTasks([...newSubTasks]);

      //Sau khi thay thế xong thì trả 2 state dưới về trạng thái ban đầu
      setNewSubTask(null);
      setNewSubTaskAfterInserted(null);
    }
  }, [newSubTaskAfterInserted]);

  //Xử lý khi click ra ngoài phạm vi dòng đang được thêm thì sẽ ẩn đi
  const handleClick = async (event: MouseEvent) => {
    const isClickInside = addSubTaskRef.current?.contains(
      event.target as HTMLElement
    );
    const isClickAddSubTaskBtn = addSubTaskBtnRef.current?.contains(
      event.target as HTMLElement
    );

    const IsCloseAddSubTaskRow = () => {
      return !isClickInside && !isClickAddSubTaskBtn;
    };

    IsCloseAddSubTaskRow() && handleCloseAddSubTask();
  };

  useEffect(() => {
    subTasks.map((item) => {
      if (item.isNew) {
        document.addEventListener("click", handleClick);
      }
    });

    return () => document.removeEventListener("click", handleClick);
  }, [subTasks]);

  //--------------------------------------------------------------------------

  //handle func
  const handleAddSubTask = () => {
    const isAddSubTaskExist = subTasks.find(
      (subtask) => subtask.isNew === true
    );
    if (isAddSubTaskExist) {
      return;
    }

    // if (selectedSubTaskId) {
    //   let currentSelectedTaskIndex;
    //   for (let i = 0; i < subTasks.length; i++) {
    //     if (subTasks[i].resourcesid === selectedSubTaskId) {
    //       currentSelectedTaskIndex = i;
    //       break;
    //     }
    //   }
    //   if (typeof currentSelectedTaskIndex === "number") {
    //     const newSubTasks = [...subTasks];
    //     newSubTasks.splice(currentSelectedTaskIndex + 1, 0, {
    //       isNew: true,
    //       id: 99,
    //       name: "addsubtask",
    //     });

    //     setSubTasks([...newSubTasks]);
    //     setSelectedSubTaskId(null);

    //     return;
    //   }
    // }

    setSubTasks((prev) => [
      ...prev,
      { isNew: true, id: 99, name: "addsubtask" },
    ]);
    setSelectedSubTaskId(null);
  };

  const handleCloseAddSubTask = () => {
    const newSubTask = [...subTasks];
    setSubTasks(newSubTask.filter((subtask) => !subtask.isNew));
  };

  const handleLoadMore = () => {
    setPage((prev) => prev + 1);
    setGetRequest(true);
  };

  //Drag drop subtask
  const onDragEnd = (result: any) => {
    
    if (!result.destination) {
      return;
    }

    //Nếu vị trí không thay đổi thì khoong gọi api
    if(result.source.droppableId === result.destination.droppableId && result.source.index === result.destination.index) {
      return
    }
    const startOrder = subTasks?.at(result.source.index)?.order
    const toOrder = subTasks?.at(result.destination.index)?.order

    const reorderedSubTasks = reorder(subTasks, result.source.index, result.destination.index)
    setSubTasks(reorderedSubTasks as BaseRecord[])
    reOrderSubTasksPosition(startOrder, toOrder, values?.currentTaskDetailId as number, reorderedSubTasks as BaseRecord[])

    }

    const reOrderSubTasksPosition = async (startOrder: number, toOrder: number, dragSectionId: number,reorderedSubTasks: BaseRecord[]) => {
      await axios
      .post(`resourcesrelation/reorder`, {
        start: startOrder,
        end: toOrder,
        dragSectionId,
  
      }).then(res => {
        const updatedData = res.data;
        const updatedSubTasks = reorderedSubTasks.map((subTask: any) => {
          // Lặp qua mảng tasks hiện tại, so sánh với các item trả về, nếu có rsId giống nhau, thì cập nhật lại order
          // Còn nếu không giống nhau thì để nguyên
          let newSubTask = subTask;

          updatedData.forEach((_: any) => {
            if (_?.resourcesid === subTask?.resources.id) {
              // So sánh id để cập nhật lại order
              newSubTask = { ...newSubTask, order: _.order };
            }
          });

          return newSubTask;
        });

        setSubTasks(updatedSubTasks)
      })
    }
    

  return (
    <div className="subtask-container">
      {subTasks.length > 0 && <p>Subtasks</p>}

      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="subTaskDroppable">
          {(provided, snapshot) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {subTasks.map((item, index) => {
                if (item.isNew) {
                  return (
                    <SubTaskAddRowHOC
                      setNewSubTask={setNewSubTask}
                      newSubTask={newSubTask}
                      subTasks={subTasks}
                      index={index}
                      key={item.name}
                      addSubTaskref={addSubTaskRef}
                      setSubTasks={setSubTasks}
                    />
                  );
                }

                return (
                  <SubTaskRowHOC
                    key={item.resourcesid}
                    resources={item.resources}
                    subTaskId={item.resourcesid}
                    selectedSubTaskId={selectedSubTaskId}
                    setSelectedSubTaskId={setSelectedSubTaskId}
                    setSubTasks={setSubTasks}
                    index={index}
                  />
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>


      {hasMore && subTasks && subTasks.length > 0 && (
        <h4 className="loadmore" onClick={handleLoadMore}>
          Load more subtasks
        </h4>
      )}

      <button
        className="subtask-btn"
        onClick={handleAddSubTask}
        ref={addSubTaskBtnRef}
      >
        <BsPlusLg />
        <span>Add subtask</span>
      </button>
    </div>
  );
};

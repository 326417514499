import { keyBy } from 'lodash'
import create from 'zustand'

export interface Loading {
    sectionsFetchState: {
        [field : string]: {
            page: number
            hasMore: boolean
            extend: boolean
        }
    },
    updateSectionsFetchState: (sections: any) => void
    updateSectionsFetchStateById: (sectionId: number, value: any) => void

}

export const ueSectionFetchState = create<Loading>((set) => ({

    sectionsFetchState: {},
    updateSectionsFetchState: (sections: any) => set((state) => {
        state.sectionsFetchState = keyBy(sections, "sectionId")
        return { ...state }
    }),
    updateSectionsFetchStateById: (sectionId: number, value: any) => set((state) => {
        state.sectionsFetchState[sectionId] = {... state?.sectionsFetchState[sectionId], ...value}
        return { ...state }
    }),
    
}))
import React, { ReactElement, useCallback, useMemo, useRef, useState } from "react";
import { EditorState } from "draft-js";
import Editor from "@draft-js-plugins/editor";
import createMentionPlugin, { MentionData, defaultSuggestionsFilter } from "@draft-js-plugins/mention";
import editorStyles from "../../components/Mytask/Mention/MentionsStyles.module.css";
import { useList } from "@pankod/refine-core";
import mentionsStyles from "../../components/Mytask/Mention/MentionsStyles.module.css";
import { customSuggestionsFilter } from "components/Mytask/utils";

export default function SimpleMentionEditor({
  mentions,
  setFormData,
  formData,
  setNameMention,
  setEditorStateMention,
  editorStateMention,
  setMenTionId,
  setEditorText,
}: any): ReactElement {
  const ref = useRef<Editor>(null);
  // const [editorState, setEditorState] = useState(() => EditorState.createEmpty())

  const [open, setOpen] = useState(false);
  const [suggestions, setSuggestions] = useState(mentions);
  const { MentionSuggestions, plugins } = useMemo(() => {
    const mentionPlugin = createMentionPlugin({
      entityMutability: "IMMUTABLE",
      theme: mentionsStyles,
      mentionPrefix: "@",
      supportWhitespace: true,
    });
    // eslint-disable-next-line no-shadow
    const { MentionSuggestions } = mentionPlugin;
    // eslint-disable-next-line no-shadow
    const plugins = [mentionPlugin];
    return { plugins, MentionSuggestions };
  }, []);

  const onOpenChange = useCallback((_open: boolean) => {
    setOpen(_open);
  }, []);
  const onSearchChange = useCallback(
    ({ value }: { value: string }) => {
      if (mentions) {
        const filteredMentions = customSuggestionsFilter(value, mentions);
        setSuggestions(filteredMentions);
      }
    },
    [mentions]
  );

  const onAddMention = (mention: any) => {
    if (mention && mention.id) {
      const mentionText = editorStateMention.getCurrentContent().getPlainText().substring(mention.start);
      const mentionedText = `${mentionText}${mention.name}`;

      const lastSpaceIndex = mentionedText.lastIndexOf(" ");

      const mentionedPart = mentionedText.substring(0, lastSpaceIndex);
      const lastWord = mentionedText.substring(lastSpaceIndex + 1);
      const formattedMentionedText = `${mentionedPart} ${lastWord}`;

      console.log("Formatted Mentioned Text:", formattedMentionedText);
      setFormData({
        ...formData,
        idMention: mention.id,
      });
      let myString = mentionText;
      let result = myString.split("@")[0].trim();

      setEditorText(formattedMentionedText);
      setMenTionId((prev: any) => [...prev, mention?.id]);
      setNameMention((prev: any) => [...prev, formattedMentionedText]);
    }
  };

  return (
    <div
      className={editorStyles.editor}
      onClick={() => {
        ref.current!.focus();
      }}
    >
      <Editor
        editorKey={"editormention"}
        editorState={editorStateMention}
        onChange={setEditorStateMention}
        plugins={plugins}
        ref={ref}
        placeholder="Enter your answer"
      />
      <MentionSuggestions
        open={open}
        onOpenChange={onOpenChange}
        suggestions={suggestions}
        onSearchChange={onSearchChange}
        onAddMention={onAddMention}
      />
    </div>
  );
}

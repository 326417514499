import { BaseRecord, useCreate, useList } from "@pankod/refine-core";
import { USER_ID } from "authProvider";
import axios from "axios";
import Loading from "components/Mytask/Loading/Loading";
import { Topintop } from "pages/HeaderSidebar/LoadingSearch/Topintop/Topintop";
import { memo, useEffect, useState } from "react";
import { BiError } from 'react-icons/bi';
import { FcLock } from "react-icons/fc";
import { NewCommentSocketTeamContext } from "socket/newcommentcontext";
import { NewInboxSocketTeamContext } from "socket/newinboxcontext";
import { SocketTeamContext } from "socket/socketcontext";
import { useMyTask } from "../../Context/MyTask.context";
import {
  ResetDataWhenChangeTaskDeTails,
  ResetDataWhenOpenAddNewTask,
  checkPermission,
  getCommentWithFile,
} from "../myTaskDetailUtils";
import { Comments } from "./Comments/Comments";
import "./DetailsContent.scss";
import { FilesList } from "./FilesList/FileList";
import { SubTaskQueue } from "./SubTaskQueue/SubTaskQueue";
import { Subtask } from "./Subtask/Subtask";
import { TaskInfo } from "./TaskInfo/TaskInfo";
import { TaskNameInputHOC } from "./TaskNameInput/TaskNameInput";
import { useDetectProposePage } from "pages/ProposeTasks/hooks";
import { ProposeTaskNameInput } from "pages/ProposeTasks/components/Details/TaskNameInput/TaskNameInput";
import { ProposeTaskInfo } from "pages/ProposeTasks/components/Details/TaskInfo/TaskInfo";
import SectionLoader from "pages/ProposeTasks/components/SectionLoader";
import TextLoader from "pages/ProposeTasks/components/TextLoading";
import { RECENTLYTASK } from "components/Mytask/Constant";
import TaskNameDetailRecently from "pages/Recently-completed-task/components/TaskDetailRecently/TaskName";
import TaskInfoDetailRecently from "pages/Recently-completed-task/components/TaskDetailRecently/TaskInfo";


export interface HasMoreCmt {
  isHasMore: boolean;
  unLoadCmt: null | number;
}
interface DetailsContentProps {
  type?: string;
  currentTaskDetailId?: string | number | null | undefined;
  isDetailsLoading?: boolean | undefined;
  setIsDetailsLoading?:
    | React.Dispatch<React.SetStateAction<boolean>>
    | undefined;
  setDetailsHeaderData?:
    | React.Dispatch<React.SetStateAction<BaseRecord | undefined>>
    | undefined;
  setComments?: React.Dispatch<React.SetStateAction<BaseRecord[]>> | undefined;
  setProjectAndSection?: React.Dispatch<React.SetStateAction<BaseRecord[]>>;
  setCurrentTaskDetailTitle?: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
  taskDetailsQueue?: BaseRecord[] | undefined;
  setTaskDetailsQueue?: React.Dispatch<React.SetStateAction<BaseRecord[]>>;
  comments?: BaseRecord[];
  isScrollCmt: boolean;
  setIsScrollCmt: React.Dispatch<React.SetStateAction<boolean>>;
  setFileList: React.Dispatch<React.SetStateAction<BaseRecord[]>>;
  fileList:  BaseRecord[] ;
  isNewInboxSocket: any;
  setIsNewInboxSocket: any;
  socketCmtId: any;
  socket: any;
  dispatch: any;
  socketNewCmtId: any;
  userReceiveMessage: any;
  disPlayStatus?:any
  resourceDetails?:any
  setResourceDetails?:any
}
interface DetailsContentHOCProps {
  type?: string;
  isScrollCmt: boolean;
  setIsScrollCmt: React.Dispatch<React.SetStateAction<boolean>>;
  disPlayStatus?:any
}

export const DetailsContentHOC: React.FC<DetailsContentHOCProps> = ({
  type,
  setIsScrollCmt,
  isScrollCmt,
  disPlayStatus
}) => {
  const values = useMyTask();
  const valueSocketNewInbox = NewInboxSocketTeamContext();
  const valueSocketNewComment = NewCommentSocketTeamContext();
  const valueSocket = SocketTeamContext();
  const currentTaskDetailId = values?.currentTaskDetailId;
  const isDetailsLoading = values?.isDetailsLoading;
  const setIsDetailsLoading = values?.setIsDetailsLoading;
  const setDetailsHeaderData = values?.setDetailsHeaderData;
  const setComments = values?.setComments;
  const setProjectAndSection = values?.setProjectAndSection;
  const setCurrentTaskDetailTitle = values?.setCurrentTaskDetailTitle;
  const taskDetailsQueue = values?.taskDetailsQueue;
  const setTaskDetailsQueue = values?.setTaskDetailsQueue;
  const comments = values?.comments;
  const setFileList = values?.setFileList
  const fileList = values?.fileList
  const resourceDetails = values?.resourceDetails
  const setResourceDetails = values?.setResourceDetails

  return (
    <DetailsContent
      type={type}
      currentTaskDetailId={currentTaskDetailId}
      isDetailsLoading={isDetailsLoading}
      setIsDetailsLoading={setIsDetailsLoading}
      setDetailsHeaderData={setDetailsHeaderData}
      setComments={setComments}
      setProjectAndSection={setProjectAndSection}
      setCurrentTaskDetailTitle={setCurrentTaskDetailTitle}
      taskDetailsQueue={taskDetailsQueue}
      setTaskDetailsQueue={setTaskDetailsQueue}
      comments={comments}
      isScrollCmt={isScrollCmt}
      setIsScrollCmt={setIsScrollCmt}
      setFileList={setFileList as any}
      fileList={fileList as any}
      isNewInboxSocket={valueSocketNewInbox?.isNewInbox}
      setIsNewInboxSocket={valueSocketNewInbox?.setIsNewInbox}
      socketCmtId={valueSocketNewInbox?.socketCmtId}
      socket={valueSocket?.socket}
      dispatch={valueSocketNewComment?.dispatch}
      socketNewCmtId={valueSocketNewComment?.socketNewCmtId}
      userReceiveMessage={valueSocketNewComment?.userReceiveMessage}
      disPlayStatus = {disPlayStatus}
      resourceDetails={resourceDetails}
      setResourceDetails={setResourceDetails}
    />
  );
};

export const DetailsContent: React.FC<DetailsContentProps> = memo(
  ({
    type,
    currentTaskDetailId,
    isDetailsLoading,
    setIsDetailsLoading,
    setDetailsHeaderData,
    setComments,
    setProjectAndSection,
    setCurrentTaskDetailTitle,
    taskDetailsQueue,
    setTaskDetailsQueue,
    comments,
    isScrollCmt,
    setIsScrollCmt,
    setFileList,
    fileList,
    isNewInboxSocket,
    setIsNewInboxSocket,
    socketCmtId,
    socket,
    dispatch,
    socketNewCmtId,
    userReceiveMessage,
    disPlayStatus,
    resourceDetails,
    setResourceDetails
  }) => {
    //Declare variable
    const [getRequest, setGetRequest] = useState(false);
    const [getRqAllComment, setgetRqAllComment] = useState(false);
    const [hasMore, setHasmore] = useState<boolean>(false);
    const [description, setDescription] = useState(undefined);
    const [page, setPage] = useState<number>(1);
    const [hasMoreCmt, setHasmoreCmt] = useState<HasMoreCmt>({
      isHasMore: false,
      unLoadCmt: null,
    });
    const [subTasks, setSubTasks] = useState<BaseRecord[]>([]);
    // const [FileList, setFileList] = useState<BaseRecord[]>([]);
    // const [resourceDetails, setResourceDetails] = useState<BaseRecord[]>([]);
    const [resourceData, setResourceData] = useState<BaseRecord>({});
    const [isLock, setIsLock] = useState(false);
    const [err, setErr] = useState<any>(null);
    const createFunc = useCreate();
    // const [name, setName] = useState<string>('')
    const [cmId,setcmId]=useState([]);
    const [allError, setAllError] = useState({
      commentRes: true,
      taskRes: true,
      taskDetailRes: true,
      subTasksRes: true,
      FilesRes: true,
    });

    const [taskDetailId, setTaskDetailId] = useState(0);
    const [prevTaskDetailId, setPrevTaskDetailId] = useState(0);
    const userId = localStorage.getItem(USER_ID);

    useEffect(() => {
      //Reset data khi chuyen task detail
      ResetDataWhenChangeTaskDeTails({
        setPage,
        setgetRqAllComment,
        setIsDetailsLoading,
        setHasmore,
        setGetRequest,
        currentTaskDetailId,
        setHasmoreCmt,
      });
      setIsLock(false);
      setErr(null)
      setAllError({
        commentRes: true,
        taskRes: true,
        taskDetailRes: true,
        subTasksRes: true,
        FilesRes: true,
      });

      //Reset data khi thêm mở ô thêm task mới
      if (typeof currentTaskDetailId === "string") {
        ResetDataWhenOpenAddNewTask({
          setSubTasks,
          setDescription,
          setComments,
          setFileList,
          setIsDetailsLoading,
          setProjectAndSection,
          setResourceDetails,
        });

        setAllError({
          commentRes: false,
          taskRes: false,
          taskDetailRes: false,
          subTasksRes: false,
          FilesRes: false,
        });
      }
    }, [currentTaskDetailId,]);

    //Check Permission
    useEffect(() => {
      typeof currentTaskDetailId == "number" &&
        checkPermission({
          id: currentTaskDetailId,
          setGetRequest,
          setIsLock,
          setErr,
        });
    }, [currentTaskDetailId]);

    // Call Api-------------------------------------------------------------------------
    const taskDetailRes = useList({
      resource: "resourcesdetails",
      queryOptions: {
        enabled: typeof currentTaskDetailId === "number" ? getRequest : false,
        cacheTime: 0,
        onSuccess(data) {
          setAllError((prev) => ({ ...prev, taskDetailRes: false }));
        },
      },

      config: {
        filters: [
          {
            field: "resourcesid",
            operator: "eq",
            value: currentTaskDetailId,
          },
        ],
      },
      metaData: {
        config: {
          fields: [
            "description",
            "resourcesid",
            "dueOn",
            "assignee",
            "completed",
          ],
        },
      },
    }).data;

    const subTasksRes = useList({
      resource: "resourcesrelation",
      queryOptions: {
        enabled: typeof currentTaskDetailId === "number" ? getRequest : false,
        cacheTime: 0,
        onSuccess(data) {
          setAllError((prev) => ({ ...prev, subTasksRes: false }));
        },
      },

      config: {
        filters: [
          {
            field: "resourcesparentid",
            operator: "eq",
            value: currentTaskDetailId,
          },
          {
            field: "resources.resourcetype",
            operator: "eq",
            value: "subtask",
          },
        ],
        pagination: { current: page, pageSize: 10 },
        sort: [{ order: "asc", field: "order" }],
      },
      metaData: {
        config: {
          fields: ["resourcesid", "order"],
          joins: [
            { field: "resources", select: ["name"] },
            { field: "resources.resourcesDetails", select: ["completed"] },
          ],
        },
      },
    }).data;

    const FilesRes = useList({
      resource: "resourcesrelation",
      queryOptions: {
        enabled: typeof currentTaskDetailId === "number" ? getRequest : false,
        onSuccess(data) {
          setAllError((prev) => ({ ...prev, FilesRes: false }));
        },
      },

      config: {
        filters: [
          {
            field: "resourcesparentid",
            operator: "eq",
            value: currentTaskDetailId,
          },
          {
            field: "resources.resourcetype",
            operator: "eq",
            value: "file",
          },
        ],
        pagination: { current: page, pageSize: 1000 },
      },
      metaData: {
        config: {
          fields: ["resourcesid"],
          joins: [{ field: "resources", select: ["name", "createdAt"] }],
        },
      },
    }).data;

    const taskRes = useList({
      resource: "resources",
      queryOptions: {
        enabled: typeof currentTaskDetailId === "number" ? getRequest : false,
        onSuccess(data) {
          setAllError((prev) => ({ ...prev, taskRes: false }));
        },
      },

      config: {
        filters: [
          {
            field: "id",
            operator: "eq",
            value: currentTaskDetailId,
          },
        ],
        pagination: { current: 1, pageSize: 1 },
      },
      metaData: {
        config: {
          fields: ["createdby", "name", "resourcetype", "createdAt"],
        },
      },
    }).data;

    const taskProjectAndSections = useList({
      resource: "resourcestasks",
      queryOptions: {
        enabled: typeof currentTaskDetailId === "number" ? getRequest : false,
        cacheTime: 0,
      },

      config: {
        filters: [
          {
            field: "taskId",
            operator: "eq",
            value: currentTaskDetailId,
          },
        ],
        pagination: { current: 1, pageSize: 1500 },
      },
      metaData: {
        config: {
          fields: ["resourcesId", "sectionId"],
        },
      },
    }).data;

    // Lấy 3 cmt đầu tiên
    const commentRes = useList({
      resource: "resourcesrelation/get-comment",
      queryOptions: {
        enabled:
          typeof currentTaskDetailId === "number" && !isScrollCmt
            ? getRequest
            : false,
        onSuccess(data) {
          setAllError((prev) => ({ ...prev, commentRes: false }));
        },
      },

      config: {
        filters: [
          {
            field: "resourcesparentid",
            operator: "eq",
            value: currentTaskDetailId,
          },
          {
            field: "resources.resourcetype",
            operator: "in",
            value: ["comment","log"],
          },
        ],

        pagination: { current: 1, pageSize: 10 },
        sort: [{ order: "desc", field: "resources.createdAt" }],
      },
      metaData: {
        config: {
          fields: ["resourcesid"],
          joins: [
            {
              field: "resources",
              select: [
                "gid",
                "name",
                "createdby",
                "createdAt",
                "updatedAt",
                "resourcetype",
                "hasFile",
              ],
            },
            { field: "resources.resourcesDetails", select: ["pinTop"] },
            { field: "resources.resourcesLikes", select: ["userId"] },
          ],
        },
      },
    }).data;

    //Lấy tất cả comment
    let commentAllRes = useList({
      resource: "resourcesrelation/get-comment",
      queryOptions: {
        enabled: !isScrollCmt
          ? typeof currentTaskDetailId === "number"
            ? getRqAllComment
            : false
          : true,
        cacheTime: 0,
        onSuccess(data) {
          setAllError((prev) => ({ ...prev, commentRes: false }));
        },
      },

      config: {
        filters: [
          {
            field: "resourcesparentid",
            operator: "eq",
            value: currentTaskDetailId,
          },
          {
            field: "resources.resourcetype",
            operator: "in",
            value: ["comment", "log"],
          },
        ],
        pagination: { current: 1, pageSize: 1000 },
        sort: [{ order: "desc", field: "resources.createdAt" }],
      },
      metaData: {
        config: {
          fields: ["resourcesid"],
          joins: [
            {
              field: "resources",
              select: [
                "gid",
                "name",
                "createdby",
                "createdAt",
                "updatedAt",
                "resourcetype",
              ],
            },
            { field: "resources.resourcesDetails", select: ["pinTop"] },
            { field: "resources.resourcesLikes", select: ["userId"] },
       
          ],
        },
      },
    }).data;
    //Kiem tra va xet loading
    useEffect(() => {
      if (Object.values(allError).includes(true)) {
        setIsDetailsLoading && setIsDetailsLoading(true);
      } else {
        setGetRequest(false);
        setIsDetailsLoading && setIsDetailsLoading(false);
      }
    }, [allError]);

    useEffect(()=>{
      if(currentTaskDetailId && typeof currentTaskDetailId === 'string'){

        setTaskDetailId(prevState =>{ 

          setPrevTaskDetailId(prevState)
          return Number.parseInt(currentTaskDetailId ,10)
        })

      }
      if(currentTaskDetailId && typeof currentTaskDetailId === 'number'){
        setTaskDetailId(prevState =>{ 
          setPrevTaskDetailId(prevState)
          return currentTaskDetailId
        })

      }
      dispatch({type: 'joinRoomTask', payload: currentTaskDetailId?.toString()})
    },[currentTaskDetailId])
    
    useEffect(()=>{
      dispatch({type: 'leaveRoomTask', payload: prevTaskDetailId})
    },[prevTaskDetailId])

    //Khi commentRes thay đổi sẽ
    useEffect(() => {
      commentAllRes?.data &&
        commentAllRes?.data.length > 0 &&
        setComments &&
        getCommentWithFile({
          commentDataRes: commentAllRes,
          createFunc,
          setComments,
        });
      setgetRqAllComment(false);
    }, [commentAllRes]);

    useEffect(()=>{
      
      
      const fetchData2 = async ()=>{
        const response = await axios.get('resources/checkNewInbox')

        // console.log('responseresponse', response?.data);
        
        setIsNewInboxSocket(response?.data?.isNew)
      }
      fetchData2()
    // },[socketCmtId])
    },[socketCmtId, currentTaskDetailId])

    //Khi commentRes thay đổi sẽ có 2 trường hợp
    useEffect(() => {
      // TH1: Nếu có cmt sẽ gọi api để thấy cmt file
      commentRes?.data &&
        commentRes?.data.length > 0 &&
        setComments &&
        getCommentWithFile({
          commentDataRes: commentRes,
          createFunc,
          setComments,
        });

      //TH2: Nếu không có comment thì chỉ xét giá trị rỗng vào mảng thôi
      if (commentRes?.data && commentRes?.data.length === 0) {
        setComments && setComments([]);
        // setIsDetailsLoading && setIsDetailsLoading(false)
      }
    }, [commentRes]);

    

    useEffect(() => {
      taskRes && setResourceData(taskRes.data[0]);
      // taskRes && setResourceType((taskRes.data)[0]?.resourcetype)

      if (type === "singledetails" && taskRes) {
        setCurrentTaskDetailTitle &&
          setCurrentTaskDetailTitle(taskRes.data[0]?.name);

        //Set dữ liệu để làm subtask queue - lần đầu tiên kiểm tra mảng = 0 mới set dữ liệu vào mảng, còn có dữ liệu rồi thì không set
        if (taskDetailsQueue?.length === 0) {
          setTaskDetailsQueue &&
            setTaskDetailsQueue([
              {
                taskId: taskRes.data[0]?.id,
                name: taskRes.data[0]?.name,
              },
            ]);
        }
      }
    }, [taskRes]);

    //--------------------------------------------------------------------------------
    useEffect(() => {
      const taskDetailResData = taskDetailRes?.data[0];
      const description = taskDetailResData?.description;
      setDescription(description);
    }, [taskDetailRes]);

    useEffect(() => {
      const taskDetailResData = taskDetailRes?.data;
      taskDetailResData && setResourceDetails(taskDetailResData);
      taskDetailResData &&
        setDetailsHeaderData &&
        setDetailsHeaderData(taskDetailResData);
    }, [taskDetailRes]);

    useEffect(() => {
      if (page > 1) {
        subTasksRes?.data &&
          setSubTasks((prev) => [...prev, ...subTasksRes.data]);
        return;
      }

      subTasksRes?.data && setSubTasks(subTasksRes.data);
    }, [subTasksRes]);

    useEffect(() => {
 
      if(setComments && commentRes){

        const getComment = async () => {
          const res = await axios.get(`resourcesrelation/get-comment?filter=resourcesparentid%7C%7C%24eq%7C%7C${currentTaskDetailId}&filter=resources.resourcetype%7C%7C%24in%7C%7Ccomment,log&fields=resourcesid&join=resources%7C%7Cgid%2Cname%2Ccreatedby%2CcreatedAt%2CupdatedAt%2Cresourcetype%2ChasFile&join=resources.resourcesDetails%7C%7CpinTop&join=resources.resourcesLikes%7C%7CuserId&limit=10&page=1&offset=0&sort=resources.createdAt%2CDESC`);
          setComments((prev) => [...prev, res.data.data[0]]);
          
        };
        
        
        //userReceiveMessage
        if(userId === userReceiveMessage?.toString()){
           // console.log('userId',typeof userId);
          // console.log('userReceiveMessage',typeof userReceiveMessage);
          getComment();
        }
      }
    }, [socketNewCmtId, userReceiveMessage]);

    useEffect(() => {
      subTasks.length > 0 &&
        subTasksRes &&
        setHasmore(subTasksRes?.total > subTasks.length);
    }, [subTasks]);

    useEffect(() => {
      comments &&
        comments.length > 0 &&
        commentRes &&
        setHasmoreCmt({
          isHasMore: commentRes?.total > comments.length,
          unLoadCmt: commentRes?.total - comments.length,
        });
    }, [comments]);

    useEffect(() => {
      FilesRes?.data && setFileList(FilesRes.data);
    }, [FilesRes]);

    useEffect(() => {
      taskProjectAndSections &&
        setProjectAndSection &&
        setProjectAndSection(taskProjectAndSections?.data);
    }, [taskProjectAndSections]);


  const { isProposeTaskPage } = useDetectProposePage();

    return (
      <>
        <div className="dt-content-container">
          {!isDetailsLoading && !isLock && (
            <>
              <SubTaskQueue type={type} />
              {isProposeTaskPage ? <ProposeTaskNameInput /> : disPlayStatus == RECENTLYTASK && type !== 'singledetails' ? <TaskNameDetailRecently/> :  <TaskNameInputHOC resourceData={resourceData} type={type} />}
              {isProposeTaskPage ? <ProposeTaskInfo /> :  disPlayStatus == RECENTLYTASK && type !== 'singledetails' ? 
              <TaskInfoDetailRecently 
                description={description}
                resourcesid={
                  typeof currentTaskDetailId === "number"
                    ? currentTaskDetailId
                    : undefined
                }/> : 
              <TaskInfo
                description={description}
                resourcesid={
                  typeof currentTaskDetailId === "number"
                    ? currentTaskDetailId
                    : undefined
                }
                resourceDetails={resourceDetails}
              />}
              <Subtask
                setGetRequest={setGetRequest}
                subTasks={subTasks}
                setSubTasks={setSubTasks}
                hasMore={hasMore}
                setPage={setPage}
              />
              <FilesList FileList={fileList} setFileList={setFileList} />
              <Topintop currentTaskDetailId={currentTaskDetailId} />
              <Comments
                hasMoreCmt={hasMoreCmt}
                setHasmoreCmt={setHasmoreCmt}
                setgetRqAllComment={setgetRqAllComment}
                resourceData={resourceData}
                isScrollCmt={isScrollCmt}
                setIsScrollCmt={setIsScrollCmt}
                setFileList={setFileList}
              />
            </>
          )}

          {isDetailsLoading && !isLock && !err &&(
            <div className="Loading-container">
              {isProposeTaskPage ? <TextLoader /> : <Loading />}
              
            </div>
          )}

          {isLock && (
            <div className="Lock-container">
              <div className="lock-content">
                <FcLock className="lock-icon" />
                <h2>You don't have Permission</h2>
              </div>
            </div>
          )}

          {err && (
            <div className="Lock-container">
              <div className="lock-content">
                <BiError className="lock-icon" />
                <h2>{err?.message}</h2>
              </div>
            </div>
          )}
        </div>
      </>
    );
  }
);

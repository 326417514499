import React, { useCallback, useMemo, useRef, useState, useEffect } from "react";
import { EditorState } from "draft-js";
import "@draft-js-plugins/mention/lib/plugin.css";
import "./MentionMany.scss";
import Editor from "@draft-js-plugins/editor";
import createMentionPlugin, { defaultSuggestionsFilter } from "@draft-js-plugins/mention";
import mentionsStyles from "./MentionsStyles.module.css";
import { MentionData } from "@draft-js-plugins/mention";
import { useAppContext } from "App.context/App.context";
import { customSuggestionsFilter as suggestionFilter } from "../utils";
interface MentionProps {
  getData?: React.Dispatch<React.SetStateAction<MentionData | null>>;
  resestOnMention?: boolean;
}
export const MentionMany: React.FC<MentionProps> = ({ getData, resestOnMention = false }) => {
  const AppContext = useAppContext();
  const [open, setOpen] = useState(false);
  const [editorState, setEditorState] = useState(() => {
    return EditorState.createEmpty();
  });
  //Get users------------------------------------------------------------------------------
  const mentionsData = AppContext?.mentionsData ? AppContext?.mentionsData : [];

  // Mention-------------------------------------------------------------------------------
  const ref = useRef<Editor>(null);
  const [suggestions, setSuggestions] = useState(mentionsData);

  const { MentionSuggestions, plugins }: any = useMemo(() => {
    const mentionPlugin = createMentionPlugin({
      entityMutability: "IMMUTABLE",
      theme: mentionsStyles,
      mentionTrigger: "@",
      mentionPrefix: "@",
      supportWhitespace: true,
    });
    const { MentionSuggestions } = mentionPlugin;
    const plugins = [mentionPlugin];
    return { plugins, MentionSuggestions };
  }, []);

  const onChange = useCallback((_editorState: EditorState) => {
    // setEditorState(_editorState);
  }, []);

  const onOpenChange = useCallback((_open: boolean) => {
    setOpen && setOpen(_open);
  }, []);

  const onSearchChange = ({ value }: { value: string }) => {
    setSuggestions(suggestionFilter(value, mentionsData));
  };
  // Phú customMantion

  const customSuggestionsFilter = (searchValue: any, suggestions: any) => {
    const size = (list: any) => (list.constructor.name === "List" ? list.size : list.length);

    const get = (obj: any, attr: any) => (obj.get ? obj.get(attr) : obj[attr]);

    const value = searchValue.toLowerCase();
    const filteredSuggestions = suggestions.filter(
      (suggestion: any) => !value || get(suggestion, "name").toLowerCase().indexOf(value) > -1
    );
    const length = size(filteredSuggestions) < 15 ? size(filteredSuggestions) : 15;
    return filteredSuggestions.slice(0, length);
  };

  return (
    <div
      className="mention-container"
      onClick={() => {
        ref.current!.focus();
      }}
    >
      <Editor editorKey={"editor"} editorState={editorState} onChange={setEditorState} ref={ref} plugins={plugins} />

      <MentionSuggestions
        open={open}
        onOpenChange={onOpenChange}
        suggestions={suggestions}
        onSearchChange={onSearchChange}
        onAddMention={(data: any) => {
          getData && getData(data);

          if (resestOnMention) {
            setTimeout(() => {
              editorState.getUndoStack().map((item: any) => {
                if (item._map.size === 4) {
                  setEditorState((pre) => EditorState.undo(pre));
                  setEditorState((pre) => EditorState.undo(pre));
                }
              });
            }, 300);
          }
          // setOpen&& setOpen(false)
          // customFunc &&   customFunc(false)
        }}
      />
    </div>
  );
};

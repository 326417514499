import {
  useNavigation,
  useRouterContext,
  useUpdate
} from "@pankod/refine-core";
import { useAppContext } from "App.context/App.context";
import {
  MyTaskProvider,
  useMyTask
} from "components/Mytask/Context/MyTask.context";
import { useCallback, useEffect, useRef, useState } from "react";
// import './deletetask.scss'
import { DatePicker, Dropdown, Menu, Tooltip } from "@pankod/refine-antd";
import axios from "axios";
import { Avatar } from "components/avatar/avatar";
import { MyTaskDetailsHOC } from "components/Mytask/mtDetails/MyTaskDetails";
import { TeamContext } from "context/contextapi";
import dayjs from "dayjs";
import { BsCalendar2Week } from "react-icons/bs";
import { useParams } from "react-router";
import "./deletetask.scss";
import moment from "moment";
import { TOKEN_KEY_LOGIN } from "authProvider";
import Loading from "components/Mytask/Loading/Loading";
import useDragDropStore from "pages/myTask/state/DragDropStore";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { DISPLAYSTATUS } from "pages/CompletedTasks/CompletedTaskPage";
import { ALLTASK, COMPLETED, DELETETASK, INCOMPLETE, RECENTLYTASK } from "components/Mytask/Constant";
import { AiOutlineCheckCircle } from "react-icons/ai";
// import { replace } from "lodash";
// import { useNavigate } from 'react-router-dom'

export const DeleteTaskPageHOC: React.FC = () => {
  return (
    <>
      <MyTaskProvider>
        <DeleteTaskPage />
        <MyTaskDetailsHOC />
      </MyTaskProvider>
    </>
  );
};
interface ShowDeletetaskProps {
  item?: any;
  unDelete?: any;
  openDetailTask?: any;
  lastElement?: any;
  id?: any;
}

const ShowDeletetask: React.FC<ShowDeletetaskProps> = ({
  item,
  unDelete,
  openDetailTask,
  lastElement,
  id
}) => {
  // const {item,unDelete,openDetailTask,lastElement } = {item, unDelete, openDetailTask, lastElement}
  

  return (
    <div className="dt-task-content-row" key={item.id} ref={lastElement} >
      <div className="delete-task-row-nametask">
        <div className="delete-task-row-section-nametask">
          <div
            className="delete-task-row-nametask-icon"
            title="Undelete"
            onClick={() => unDelete(item)}
          >
            Undelete
          </div>
          <div className="delete-task-row-nametask-title">
            <svg
              stroke="currentColor"
              fill="currentColor"
              stroke-width="0"
              viewBox="0 0 16 16"
              className="row-title-check-icon"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
              style={{ color: "58a182" }}
            >
              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"></path>
            </svg>
            <input
              spellCheck="false"
              defaultValue={item.name}
              className="delete-task-row-input"
            />
          </div>
          <div
            className="delete-task-row-nametask-item"
            onClick={() => openDetailTask(item)}
          >
            <svg
              stroke="currentColor"
              fill="currentColor"
              stroke-width="0"
              viewBox="0 0 24 24"
              className="row-item-icon "
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g>
                <path fill="none" d="M0 0h24v24H0z"></path>
                <path d="M12 8H8.001L8 20H6V8H2l5-5 5 5zm10 8l-5 5-5-5h4V4h2v12h4z"></path>
              </g>
            </svg>
            <div className="row-item-detail">
              <span>Details</span>
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 24 24"
                className="row-item-detail-icon"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M10.707 17.707 16.414 12l-5.707-5.707-1.414 1.414L13.586 12l-4.293 4.293z"></path>
              </svg>
            </div>
          </div>
        </div>
      </div>
      <div className="delete-task-row-assigntask">
        <div className="delete-task-row-section-assigntask-icon">
          <Avatar userId={item?.resourcesDetails?.resourcesassign?.id} />
        </div>
        <div className="delete-task-row-section-assigntask-name">
          <Tooltip
            placement="bottom"
            title={item?.resourcesDetails?.resourcesassign?.name}
          >
            <p className="">{item?.resourcesDetails?.resourcesassign?.name}</p>
          </Tooltip>
        </div>
      </div>
      <div className="delete-task-row-duedatetask">
        <div className="delete-task-row-section-duedatetask">
          <label className="labelDueOn">{item?.resourcesDetails?.dueOn ? moment(item?.resourcesDetails?.dueOn).format("DD - MM - YYYY") : null}</label>
        </div>
      </div>
      <div className="delete-task-row-blanktask"></div>
    </div>
  );
};

export const DeleteTaskPage: React.FC = () => {
  const AppContext = useAppContext();
  const params = useParams();
  const getParam = useRouterContext();
  const getParamId: any = getParam.useParams();
  const { id, resource } = getParamId;
  const [isOpen, setIsOpen] = useState(false);
  const [allDeleteTasks, setAllDeleteTasks] = useState<any | undefined>([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [getRequest, setGetRequest] = useState(false);
  const dateFormat = "DD - MM - YYYY";
  const { goBack, replace, push } = useNavigation();

  const { useLocation, useHistory } = useRouterContext();
  const location = useLocation();
  const history = useHistory();

  const values = useMyTask();
  const isShow = TeamContext();
  const dataUpdate = TeamContext();
  const { mutate } = useUpdate();
  const idUserTaskList = localStorage.getItem(TOKEN_KEY_LOGIN);
  const {toggleSectionDrop, toggleTaskDrop, isDisableSectionDrop} = useDragDropStore()
  const IsMyTaskLoading = AppContext?.isMyTaskLoading;
  const SetIsMyTaskLoading = AppContext?.setIsMyTaskLoading;
  const showSidebar = AppContext?.showSideBar;

  const observe = useRef<IntersectionObserver | null>(null);
  const lastElement = useCallback(
    (node: HTMLDivElement) => {
      if (observe.current) observe.current?.disconnect();

      observe.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPage((prev) => prev + 1);
        }
      });

      if (node) {
        observe.current?.observe(node);
      }
    },
    [hasMore]
  );
  const openDetailTask = (archive: any) => {
    values?.setShowMyTaskDetails(true);
    values?.setCurrentTaskDetailId(archive?.id);
    values?.setCurrentTaskDetailTitle(archive?.name);
    isShow?.setShow(archive);
  };

  const unDelete = (item: any) => {
    mutate(
      {
        resource: "resources",
        id: item.id,
        values: {
          deletedAt: null,
        },
        successNotification: {
          message: "Cập nhật thành công !",
          type: "success",
        },
      },
      {
        onError: (error, variables, context) => {},
        onSuccess: (data, variables, context) => {
          setAllDeleteTasks((prev: any) => {
            const oldSubTasks = [...prev];
            const newSubTasks = oldSubTasks.filter(
              (items: any) => items.id !== item.id
            );
            return newSubTasks;
          });
        },
      }
    );
  };

  useEffect(() => {
    let url = `resources/getDeleteallTaskInProject/${id}/${page-1}/15`;
    axios.get(url).then((res) => {
      if (res.data) {
        setAllDeleteTasks((prev: any) => [...prev, ...res?.data?.data]);
        Array.isArray(res?.data) && setHasMore(res?.data?.length > 0);
      }
    });
  }, [id, page]);

  const goToBack = () => {
 
    if(location.pathname.includes(idUserTaskList)){
      replace(`/mytask/show/${idUserTaskList}`)
    }else{
      replace(`/projects/show/${id}`)

    }
 
  };

  const onDragEnd=(e:any)=>{
  }
  const onDragStart=(e:any)=>{

  }

  const menu = (
    <Menu
      selectable
      items={[
        {
          label: "Incomplete tasks",
          key: "1",
          onClick: () => handleSelectChange(INCOMPLETE),
        },

        {
          label: "Completed tasks",
          key: "2",
          onClick: () => handleSelectChange( COMPLETED),
        },

        {
          label: "All tasks",
          key: "3",
          onClick: () => handleSelectChange(ALLTASK),
        },
        {
          label: "Delete tasks",
          key: "4",
          onClick: () => handleDelete(DELETETASK),
        },
        {
          label: "Recently completed tasks",
          key: "5",
          onClick: () => handleSelectChange(RECENTLYTASK),
        },
      ]}
    />
  );

  const handleSelectChange = (val: string) => {
    if(val === option) return;
    
    if(id === idUserTaskList){
      localStorage.setItem(DISPLAYSTATUS,val);
      AppContext?.setTaskDisplayStatus(val)
      AppContext?.setIsMyTaskLoading(true)
      replace(`/mytask/show/${id}`)
    }else{
      localStorage.setItem(DISPLAYSTATUS,val);
      AppContext?.setTaskDisplayStatus(val)
      AppContext?.setIsMyTaskLoading(true)
      replace(`/projects/show/${id}`)

    }
    
  };

  const [option, setOption] = useState(() => {
    return DELETETASK
  });

  const handleDelete=(val: string)=>{
    setOption(val);
    push(location.pathname)
    replace(`/deletetask/show/${id}`)
  }
  const handleCompletedTask=(val: string)=>{
    setOption(val);
    push(location.pathname)
    replace(`/completedtask/show/${id}`)
  }



  return (
    <>
      <div className="delete-task-container">
        <div className="dt-task-header-container">
        <Dropdown overlay={menu} trigger={["click"]}>
          <div className="delete-task-header">
            <AiOutlineCheckCircle />
            <span>{option}</span>
          </div>
        </Dropdown>
          <div className="dt-task-container-header">
            <div className="dt-task-row-header-nametask">TaskName</div>
            <div className="dt-task-row-header-assigntask">
              <span style={{ paddingLeft: "10px" }}>Assignee</span>
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 1024 1024"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M862 465.3h-81c-4.6 0-9 2-12.1 5.5L550 723.1V160c0-4.4-3.6-8-8-8h-60c-4.4 0-8 3.6-8 8v563.1L255.1 470.8c-3-3.5-7.4-5.5-12.1-5.5h-81c-6.8 0-10.5 8.1-6 13.2L487.9 861a31.96 31.96 0 0 0 48.3 0L868 478.5c4.5-5.2.8-13.2-6-13.2z"></path>
              </svg>
            </div>
            <div className="dt-task-row-header-duedatetask">
              <span style={{ paddingLeft: "10px" }}>Due date</span>
            </div>
            <div className="dt-task-row-header-blanktask"></div>
          </div>
        </div>
        <div className="delete-task-content-container">
          <div className="dt-task-container">
            <div
              className="delete-task-title"
              style={{ fontSize: 16, fontWeight: "bold" }}
            >
              Deleted tasks
            </div>
            <div className={
              "mytask-content-container-completetask"
            }
            style={{ left: showSidebar ? "0px" : "200px" }}
            >

                {allDeleteTasks
                ? allDeleteTasks.map((item: any, index: any) => {
                    if (allDeleteTasks.length === index + 1) {
                      return (
                        <ShowDeletetask
                          item={item}
                          unDelete={unDelete}
                          openDetailTask={openDetailTask}
                          lastElement={lastElement}
                          id={id}
                        />
                      );
                    }
                    return (
                      <ShowDeletetask
                        item={item}
                        unDelete={unDelete}
                        openDetailTask={openDetailTask}
                        id={id}
                      />
                    );
                  })
                : null}
              </div>
              {IsMyTaskLoading && (
              <div className="mytask-loading">
                <Loading />
              </div>
            )}
          </div>
            
          </div>
        </div>
    </>
  );
};

import { BaseRecord } from "@pankod/refine-core";
import { useMyTask } from "components/Mytask/Context/MyTask.context";
import useDebounce from "hooks/useDebounce";
import React, { useEffect } from "react";
import { BiCheckCircle, BiChevronRight } from "react-icons/bi";
import { BsCalendarWeek } from "react-icons/bs";
import { MdDragIndicator } from "react-icons/md";
import { RiArrowUpDownFill } from "react-icons/ri";
import {SectionRowAddTaskProps} from '../../Type/MyTaskType'
import "./SectionRowAddTask.scss";
import {useClickAway} from 'react-use'
import {useRef} from 'react'


export const SectionRowAddTask: React.FC<SectionRowAddTaskProps> = ({inputRef,setNewAddTask}) => {
  const values = useMyTask();
  const Ipvaluedebounce = useDebounce(values?.currentAddTaskDetailTitle, 400)


  //Xử lý khi click ra ngoài phạm vi dòng đang được thêm thì sẽ ẩn đi
  // const handleClick =async (event: MouseEvent) => {
  //   const isClickInside = values?.addTaskRef.current?.contains(event.target as HTMLElement);
  //   const isClickAddTaskBtn = values?.addTaskBtnRef.current?.contains(event.target as HTMLElement);
  //   const isClickMyTaskDetails = values?.detailsRef.current?.contains(event.target as HTMLElement);
  //   const isClickInputRef = inputRef.current?.contains(event.target as HTMLElement);

    
  //   //Khi click ra ngoài phạm vi dòng được thêm sẽ đóng lại
  //   const isCloseWhenClickOutSide = () => {
  //     if(!values?.currentAddTaskDetailTitle) {           
  //       return !isClickInside && !isClickAddTaskBtn && !isClickMyTaskDetails
  //     } else {
  //       return !isClickInputRef
  //     }
  //   }
  //   
    
  //   // isCloseWhenClickOutSide() && values?.setAddTaskByBtn({position: null, show: false});
  // };

  const ref = useRef<HTMLDivElement>(null)

   useClickAway(ref, (event) => {
    const isClickMyTaskDetails = values?.detailsRef.current?.contains(event.target as HTMLElement);
    if(!values?.currentAddTaskDetailTitle) { 
      
      if(values?.showMyTaskDetails) {           
        !isClickMyTaskDetails && values?.setAddTaskByBtn({position: null, show: false});
      } else {
        values?.setAddTaskByBtn({position: null, show: false})
      }

    } else {
      values?.setInputBlur(!values?.inputBlur)
     setTimeout(() => {
       values?.setAddTaskByBtn({position: null, show: false})
     },0)
    }

    
  })


  //Focus
  useEffect(() => {
    inputRef.current && inputRef?.current.focus();
  },[])


  
  // useEffect(() => {
    
  //   if (values?.addTaskRef.current) {
  //    document.addEventListener("click", handleClick);  
  //   }

  //   return () => document.removeEventListener("click", handleClick);
  // }, [Ipvaluedebounce, values?.showMyTaskDetails]);


  


  //handle Func
  const handleShowDetails = () => {
    values?.setShowMyTaskDetails(true);
    values?.setCurrentTaskDetailId('ADDTASK');
  };

  const handleInPutBlur = () => {
    values?.setInputBlur(!values?.inputBlur)
  }

  //Xử lý khi enter thêm task mới
  const handleKeyPress = (e: React.KeyboardEvent) => {
     if(e.keyCode !== 13) {
        return
     }
     values?.setInputBlur(!values?.inputBlur)
     setTimeout(() => {
       values?.setAddTaskByBtn({position: null, show: false})
     },0)
  }

  useEffect(() => {
  
    if(values?.currentAddTaskDetailTitle){   
      const newTask: BaseRecord = {
        id: Math.floor(Date.now() + Math.random() ),
        resourcesid: Math.floor(Date.now() + Math.random() ),
        position: values.addTaskPosition,
        resources: {
          id: Math.floor(Date.now() + Math.random() ),
          name: values?.currentAddTaskDetailTitle
        }
      } 
      setNewAddTask && setNewAddTask(newTask)
    } 
  },[values?.inputBlur])

  const handleRowClick = () => {
    values?.setCurrentTaskDetailId && values?.showMyTaskDetails && values?.setCurrentTaskDetailId('ADDTASK');
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => { 
    values?.setCurrentAddTaskDetailTitle && values?.setCurrentAddTaskDetailTitle(e.target.value);
  };

  

  return (
    <div
      className="add-task-container onerow"
      ref={values?.addTaskRef}
      onClick={handleRowClick}
    >
      <div className="add-task-row" ref={ref}>
        <div className="add-task-input">
          <div className="session-row-title">
            <MdDragIndicator className="drag-icon" />

            <div className="row-title">
              <BiCheckCircle className="row-title-check-icon" />
              <input
                onChange={handleChange}
                value={values?.currentAddTaskDetailTitle ? values?.currentAddTaskDetailTitle : ''}
                className="row-title-ip"
                placeholder="Write a task name"
                ref={inputRef}
                onBlur={handleInPutBlur}
                onKeyDown={handleKeyPress}
              />
            </div>

            <div
              className={
                "row-item " + (values?.showMyTaskDetails ? "rowitem-hasDt" : "")
              }
            >
              <RiArrowUpDownFill className="row-item-icon" />
              <div className="row-item-detail" onClick={handleShowDetails}>
                <span>Details</span>
                <BiChevronRight className="row-item-detail-icon" />
              </div>
            </div>
          </div>

          {!values?.showMyTaskDetails && (
            <>
              <div className="add-task-col date">
                <BsCalendarWeek className="row-col-icon" />
              </div>
              <div className="add-task-col"></div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

import {BiLike, BiArrowToRight} from 'react-icons/bi'
import {MdOutlineAttachFile} from 'react-icons/md'
import {TbSubtask} from 'react-icons/tb'
import {FiLink, FiMoreHorizontal} from 'react-icons/fi'
import {AiOutlineExpandAlt} from 'react-icons/ai'


export const headerItems = [
    // {icon: BiLike, desc: 'Tính năng này chưa làm mấy bạn khoan test !!'},
    // {icon: MdOutlineAttachFile, desc: 'Tính năng này chưa làm mấy bạn khoan test !!'},
    // {icon: TbSubtask, desc: 'Tính năng này chưa làm mấy bạn khoan test !!'},
    {icon: FiLink, type: 'link', desc: 'Copy task link'},
    {icon: AiOutlineExpandAlt, type: 'expand', desc: 'Full screen'},
    // {icon: FiMoreHorizontal, desc: 'Tính năng này chưa làm mấy bạn khoan test !!'},
]
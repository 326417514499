import { Popover } from "@pankod/refine-antd";
import { BaseRecord, useCreate } from "@pankod/refine-core";
import React, { useEffect, useState } from "react";
import { AiFillLike, AiOutlineLike } from "react-icons/ai";
import { Content } from "../LikeListContent/LikeListContent";
import "./Like.scss";

interface LikeProps {
  resourceId: number;
  likes: BaseRecord[];
  CurrentUserId: string | null;
}

export const Like: React.FC<LikeProps> = ({
  resourceId,
  likes,
  CurrentUserId,
}) => {
  const { mutate } = useCreate();
  const [likeAmount, setLikeAmount] = useState<number>(likes?.length || 0);
  const [isLiked, setIsLiked] = useState(() => {
    return likes?.some((like) => like?.userId === CurrentUserId);
  });

  useEffect(() => {
    setIsLiked(() => {
      return likes?.some((like) => like.userId === CurrentUserId);
    });
    setLikeAmount(likes?.length);
  }, [likes]);

  //handle function
  const toggleLike = () => {
    setIsLiked((prev) => !prev);
    mutate(
      {
        resource: "resources/like",
        values: {
          resourcesId: resourceId,
        },
        successNotification: false,
        invalidates:['all'],
      },
      {
        onSuccess(data, variables, context) {
          if (!isLiked) {
            setLikeAmount((prev) => {
              if (!prev) {
                return 1;
              } else {
                return prev + 1;
              }
            });
          } else {
            setLikeAmount((prev) => (prev as number) - 1);
          }
        },
        onError(error, variables, context) {
          setIsLiked((prev) => prev);
        },
      }
    );
  };

  const overlayStyle = {backgroundColor: 'var(--tool-tip-bg-color)'}

  return (
    <>
      <div className="like-amount">{likeAmount > 0 ? likeAmount : ""}</div>
      <Popover overlayInnerStyle={overlayStyle}  content={likeAmount > 0  ? <Content cmtId={resourceId} /> : null} placement="topRight" >
        {!isLiked ? (<AiOutlineLike className="comment-icon" onClick={toggleLike} />) : (<AiFillLike className="comment-icon liked" onClick={toggleLike} />)}
      </Popover>
    </>
  );
};



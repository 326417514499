import React, { useState } from "react";
import {
  AntdLayout,
  Menu,
  Grid,
  Icons,
  Sider as DefaultSider,
} from "@pankod/refine-antd";
import {
  useTranslate,
  useTitle,
  CanAccess,
  ITreeMenu,
  useRouterContext,
  useMenu,
  useRefineContext,
} from "@pankod/refine-core";
import { Title as DefaultTitle } from "../title";
import { antLayoutSider, antLayoutSiderMobile } from "./styles";
import './siderstyle.css';
import SidebarContext from "pages/SIdebarTeam/SidebarTeam";
import { useAppContext } from "App.context/App.context";
const { UnorderedListOutlined, LogoutOutlined } = Icons;
const { SubMenu } = Menu;
export const Sider: typeof DefaultSider = ({ render }) => {
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const { Link } = useRouterContext();
  const Title = useTitle();
  const translate = useTranslate();
  const { menuItems, selectedKey, defaultOpenKeys } = useMenu();
  const breakpoint = Grid.useBreakpoint();
  const { hasDashboard } = useRefineContext();
  const values = useAppContext()

  const isMobile =
    typeof breakpoint.lg === "undefined" ? false : !breakpoint.lg;
  const RenderToTitle = Title ?? DefaultTitle;
  const renderTreeView = (tree: ITreeMenu[], selectedKey: string) => {
    return tree.map((items: ITreeMenu) => {
      const { icon, label, route, name, children, parentName } = items;

      if (children.length > 0) {
        return (
          <CanAccess
            key={route}
            resource={name.toLowerCase()}
            action="list"
            params={{
              resource: items,
            }}
          >
            <SidebarContext/>
          </CanAccess>
        );
      }
      const isSelected = route === selectedKey;
      const isRoute = !(parentName !== undefined && children.length === 0);
      return (
        <CanAccess
          key={route}
          resource={name.toLowerCase()}
          action="list"
          params={{
            resource: items,
          }}
        >
          <Menu.Item
            key={route}
            style={{
              fontWeight: isSelected ? "bold" : "normal",
            }}
            icon={icon ?? (isRoute && <UnorderedListOutlined />)}
          >
            <Link to={route}>{label}</Link>
            {!collapsed && isSelected && (
              <div className="ant-menu-tree-arrow" />
            )}
          </Menu.Item>
        </CanAccess>
      );
    });
  };
  const dashboard = hasDashboard && (
    <Menu.Item
      key="dashboard"
      style={{
        fontWeight: selectedKey === "/" ? "bold" : "normal",
      }}
      icon={<Icons.DashboardOutlined />}
    >
      <Link href="/">
        {translate("dashboard.title", "Dashboard")}
        Dashboard
      </Link>
      {!collapsed && selectedKey === "/" && (
        <div className="ant-menu-tree-arrow" />
      )}
    </Menu.Item>
  );

  const items = renderTreeView(menuItems, selectedKey);

  const renderSider = () => {
    if (render) {
      return render({
        dashboard,
        items,
        logout: undefined,
        collapsed,
      });
    }
    return (
      <>
        {dashboard}
        {items}
      
      </>
    );
  };

  return (
    <AntdLayout.Sider
      collapsible
      collapsed={collapsed}
      onCollapse={(collapsed: boolean): void =>{
        values?.setShowSideBarInbox(collapsed)
        values?.setShowSideBar(collapsed)
        setCollapsed(collapsed)}}
      collapsedWidth={ 0 }
      breakpoint="lg"
      style={ antLayoutSider}
      className="sideBar-container"
     
    >
      <RenderToTitle collapsed={collapsed} />
      <Menu
        selectedKeys={[selectedKey]}
        defaultOpenKeys={defaultOpenKeys}
        mode="inline"
        onClick={() => {

          if (!breakpoint.lg) {            
            setCollapsed(true);            
          }
        }}
      >
      
        {renderSider()}
      </Menu>
    </AntdLayout.Sider>
  );
};

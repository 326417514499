export const INCOMPLETE = 'Incomplete tasks'
export const COMPLETED = 'Completed tasks'
export const ALLTASK = 'All tasks'
export const RECENTLYTASK = 'Recently completed tasks'
export const DELETETASK = 'Delete tasks'


export const MARKCOMPLETE = 'completed'
export const MARKUNCOMPLETE = 'uncomplete'
export const ADDDUEDATE = `changed the due date to`
export const REMOVEDUEDATE = 'removed the due date.'
export const ADDASSIGN = `assigned to`
export const REMOVEASSIGN = `unassigned from`


export const DESCRIPTIONCONTENT = 'Description'
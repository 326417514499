import axios from 'axios'
import { useEffect } from 'react'
import { USER_ID } from 'authProvider'

export const useFetThongTinUser = (setDataUser: any) => {
    const userId = localStorage.getItem(USER_ID)
    useEffect(() => {
        const fetchData = async () => {
            const result = await axios.get(`resourcesmembers/getUserPhongBan/${userId}`)
            setDataUser(result?.data)
        }

        fetchData()
    }, [])
}

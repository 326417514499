import "./DescTextEditor.scss";
import React, { useCallback, useEffect, useRef, useState } from "react";
import "../../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { FooterTextEditHOC } from "../DetailsFooter/FooterTextEdit/FooterTextEdit";
import { DESCRIPTIONCONTENT } from "components/Mytask/Constant";
import { useMyTask } from "components/Mytask/Context/MyTask.context";
import axios from "axios";
import { SocketAssigneeConText } from "socket/socket-asssign-context";

interface DescTextEditorProps {
  description?: string;
  resourcesid?: number;
}


export const DescTextEditor: React.FC<DescTextEditorProps> = ({
  resourcesid,
}) => {
  const [valueDescription,setValueDescription] = useState<any>()
  const [hideEditorDescription,setHideEditorDescription] = useState<boolean>(true)

  const valueSocketAssignee = SocketAssigneeConText()
  const changeAssigneeSocket = valueSocketAssignee?.changeAssigneeSocket

  const values = useMyTask()
  const descriptionRef = useRef<any>()

  useEffect(() => {
    getDescription()
  }, [changeAssigneeSocket]);


  const handleClickValueDescription = (e:any) => {
    const isAnchorTagClick = e.target.tagName.toLowerCase() === 'a' || e.target.closest('a');
    if (isAnchorTagClick) {
      setHideEditorDescription(true)
    } else {
      setHideEditorDescription(false)
    }
  }

  const getDescription = async () => {
    const description = values?.currentTaskDetailId && await axios.get(`resourcesdetails/description-by-resouceid?resouceId=${values?.currentTaskDetailId}`)
    if (description) {
      setValueDescription(description.data)
    } 
  }

  return (
    <>
      {!hideEditorDescription ? 
      <div>
        <FooterTextEditHOC
          content={DESCRIPTIONCONTENT} 
          resourcesid={resourcesid}
          setValueDescription={setValueDescription}
          setHideEditorDescription={setHideEditorDescription}
          valueDescription={valueDescription}
        />
      </div> : 
      <div className="description" ref={descriptionRef}
        onClick={handleClickValueDescription}
        dangerouslySetInnerHTML={{ __html: valueDescription }}
      />
     }
    </>
  );
};

/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Form, Input, Select } from "@pankod/refine-antd";
import {
  useCreate, useList,
  useNavigation,
  useRouterContext,
  useUpdate
} from "@pankod/refine-core";
import { TeamContext } from "context/contextapi";
import React, { memo, useEffect, useState } from "react";
import "./style.less";

const { Option } = Select;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};
interface PropProject {
  dataNewProject?:any;
  setDataNewProject?:any
  setDataUpP?:any
  dataUpP?:any

}


const CreateProjectContext :React.FC =()=>{
  const dataNewProjectValue=TeamContext()
  const setDataNewProjectValue = TeamContext()
  return <ProjectCreate  setDataNewProject={setDataNewProjectValue?.setDataNewProject} 
                         dataNewProject ={dataNewProjectValue?.dataNewProject}
                         setDataUpP={dataNewProjectValue?.setDataUpP}
                         dataUpP={dataNewProjectValue?.dataUpP}
                         />
}
const ProjectCreate: React.FC<PropProject>= memo(({setDataUpP,dataUpP}) => {

  const { mutate } = useUpdate();
  const Create  = useCreate();
  const { show } = useNavigation();
  const mutateCreate =Create.mutate
  const [form] = Form.useForm();
  const {useParams,useLocation} = useRouterContext()
  const getParam:any = useParams()
  let {id} = getParam
  const [parentId, setParentId] = useState<any>()
  const {pathname} = useLocation()
  const getParentId =JSON.parse(localStorage.getItem("parent_id") || "") 
  const dataUpdate =  useList({
    resource: "resources/list-project",
    config: {
      filters: [
        {
          field: "id", // dieu kien where.
          operator: "eq",
          value: id, // id trong where
        },
        
      ],
      pagination: {current: 1, pageSize:100}
    },
    metaData: {
      config: {
        fields: ["id","name"],
        joins: [
          {
            field: "resourcesDetails",
            select: ["id","isPublic"],
          },
          {
            field: "resourceRelation",
            select: ["id","resourcesparentid"],
          },
          {
            field: "resourcesmember",
            select: ["memberId"],
          },
        
        ],
        
      },
    },
  }).data;

  useEffect(() => {
    !pathname.includes('create') && form.setFieldsValue({
      name: dataUpdate?.data?.at(0)?.name,
      status:  dataUpdate?.data?.at(0)?.resourcesDetails?.isPublic === false ? "0" : "1",
      id:id,
    })
    setDataUpP(dataUpdate?.data);
  }, [dataUpdate])

  
  useEffect(() => {
     setParentId(getParentId)
   }, [parentId])

  //  console.log(parentId)

  const onFinish = (values: any) => {
 
    if (pathname.includes('create')) {
     
      mutateCreate({
        resource: "resources/newproject",
        values: {
          name: values.name,
          status: values.status,
          parentId: id,
        },
        invalidates:['all'],
        
        
      },
      {
        onSuccess: (data, variables, context) => {
          show("team",id)
          form.resetFields()

        },
      }
      );
    } 
    else {
      mutate({
        resource: "resources/newproject",

        values: {
          name: values.name,
          status: values.status,
          parentId: values.parentId,
        },
        id: values.id,
        invalidates:['all']

      },{
        onSuccess: (data, variables, context) => {
          
          show("team", getParentId)
          form.resetFields()
        },
      });
    }
  };

  return (
    <div className="create-form-wrapper">
      <div className="create-form">
        <h3>
          {pathname.includes('create')
            ? "New project"
            : "Update project"}
        </h3>
        <Form
          {...layout}
          form={form}
          name="control-hooks"
          onFinish={onFinish}
          initialValues={{
            parentId: getParentId,
            id: id,
          }}
        >
          <Form.Item
            name="name"
            label="Project name"
            rules={[{ required: true }]}

          >
            <Input />
          </Form.Item>

          <Form.Item
            name="status"
            label="Privacy"
            rules={[{ required: true }]}
          >
            <Select
              placeholder="Select a option"
              // onChange={onGenderChange}
              allowClear
            >
              <Option value="0">Private</Option>
              <Option value="1">Public</Option>
            </Select>
          </Form.Item>
          <Form.Item hidden name="id">
            <Input type="hidden" />
          </Form.Item>
          <Form.Item hidden name="parentId">
            <Input type="hidden" />
          </Form.Item>
          <Form.Item {...tailLayout}>
            <Button type="primary" htmlType="submit">
              {pathname.includes('create') ? "Create Project" : "Save Change"}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
})

export default CreateProjectContext;

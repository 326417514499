import "./Loadingsearch.scss";


const Loadingsearch = () => {
  return (
    <div className="bodysearch">
      <div className="circlsearch"></div>
        <div className="container_circle">
          <div className="dot dot1"></div>
          <div className="dot dot2"></div>
        <div className="dot dot3"></div>
      </div>
    </div>
  );
};

export default Loadingsearch;

import { useList } from "@pankod/refine-core";
import { useAppContext } from "App.context/App.context";
import axios from "axios";
import { TeamContext } from "context/contextapi";
import { flatten } from "hooks/useGetValue";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import DropdownFilter from "../mt_activity/DropdownFilter";
import ArchiveItem from "./ArchiveItem";
import { isBoolean, size, uniqBy } from "lodash";
import useDebounce from "hooks/useDebounce";
import useSWRImmutable from "swr/immutable";
import useFilterState from "../state";

const PAGE_SIZE = 10;

const getUrl = (
  mode: string | boolean,
  page: number,
  userId: number | null
) => {
  if (isBoolean(mode) && userId) {
    return `resourcesfilterlastesmention/dataOnlyMention?join=resourcecmt&filter=resourcecmt.createdby||$eq||${userId}&filter=userId||$eq||1&join=resourcecmt.resourcesLikes&filter=isArchived||$eq||1&join=resourcecmt.resourcesDetails&join=resourcecmt.resourcecm&page=${page}&limit=${PAGE_SIZE}&sort=createdAt,DESC&join=resourceDetail&join=taskname&join=taskname.resourcestasks&join=taskname.resourcestasks.projectname`;
  } else if (mode === "All") {
    return `resources/dataActivity?join=resourcecm&join=resourceRelation&join=resourceRelation.resourcesParent&join=resourceRelation.resourcesParent.resourcesDetails&join=resourcesmentions&filter=resourcesmentions.isArchived||$eq||1&filter=resourcesmentions.memberId||$eq||1&join=resourcesDetails&join=resourcesLikes&join=resourceRelation.resourcesParent.resourcestasks&join=resourceRelation.resourcesParent.resourcestasks.projectname&page=${page}&limit=${PAGE_SIZE}&sort=createdAt,DESC`;
  } else {
    return `resourcesfilterlastesmention/dataOnlyMention?join=resourcecmt&filter=userId||$eq||1&join=resourcecmt.resourcesLikes&filter=isArchived||$eq||1&join=resourcecmt.resourcesDetails&join=resourcecmt.resourcecm&page=${page}&limit=${PAGE_SIZE}&sort=createdAt,DESC&join=resourceDetail&join=taskname&join=taskname.resourcestasks&join=taskname.resourcestasks.projectname`
  }
};

interface IsContext {
  keyFilter: any;
  setKeyFilter: any;
  type: any;
  setType: any;
  isInboxLoading: any;
  setIsInboxLoading: any;
  dataScreenActivity: any;
  setDataScreenActivity: any;
  dataScreenArchive: any;
  setDataScreenArchive: any;
  showSideBarInbox: any;
  tabActive?: any;
}
interface isProp {
  dataScreenActivity: any;
  setDataScreenActivity: any;
  dataScreenArchive: any;
  setDataScreenArchive: any;
  tabActive: any;
}
const ArchiveContext: React.FC<isProp> = ({
  dataScreenActivity,
  setDataScreenActivity,
  dataScreenArchive,
  setDataScreenArchive,
  tabActive,
}) => {
  const values = TeamContext();
  const collapse = useAppContext();

  return (
    <Archive
      keyFilter={values?.keyFilter}
      setKeyFilter={values?.setKeyFilter}
      type={values?.type}
      setType={values?.setType}
      isInboxLoading={values?.isInboxLoading}
      setIsInboxLoading={values?.setIsInboxLoading}
      dataScreenActivity={dataScreenActivity}
      setDataScreenActivity={setDataScreenActivity}
      dataScreenArchive={dataScreenArchive}
      setDataScreenArchive={setDataScreenArchive}
      showSideBarInbox={collapse?.showSideBarInbox}
      tabActive={tabActive}
    />
  );
};

const Archive: React.FC<IsContext> = ({
  keyFilter,
  setKeyFilter,
  type,
  setType,
  isInboxLoading,
  setIsInboxLoading,
  dataScreenActivity,
  setDataScreenActivity,
  dataScreenArchive,
  setDataScreenArchive,
  showSideBarInbox,
  tabActive,
}) => {
  const getUserId = localStorage.getItem("userId");
  const [page, setPage] = useState(1);
  const [noMore, setNoMore] = useState(false);
  const [selected, setSelected] = useState("");

  const { isFilterByFromPerson, selectedUserId } = useFilterState();

  // Khanh refactor code ngày 14/4/2024
  // Wait for reset data when change filter condition
  const jsonData = useDebounce(JSON.stringify({ keyFilter, page ,isFilterByFromPerson, selectedUserId}), 200);

  const fetcher = useCallback(async ([jsonData]: any) => {
    const { keyFilter, page, isFilterByFromPerson, selectedUserId } = JSON.parse(jsonData);

    const url = getUrl(  isFilterByFromPerson ? isFilterByFromPerson : keyFilter, page, selectedUserId);
    const res = await axios.get(url);

    if (size(res.data.data) < PAGE_SIZE) {
      setNoMore(true);
    }

    return res.data.data ? res.data.data : [];
  }, []);

  const { data } = useSWRImmutable([jsonData], fetcher);

  useEffect(() => {
    if(size(data)) {
        setDataScreenArchive((prev: any) => uniqBy([...prev, ...data], "id"))
    }
  },[data])

  const resetFilterConditions = useCallback(() => {
    setDataScreenArchive([]);
    setNoMore(false);
    setPage(1);
  }, []);

  const observe = useRef<IntersectionObserver | null>(null);

  const lastElement = useCallback(
    (node: HTMLElement) => {
      if (observe.current) observe.current?.disconnect();

      observe.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && !noMore) {
          setPage(page + 1);
        }
      });

      if (node) {
        observe.current?.observe(node);
      }
    },
    [dataScreenArchive]
  );

  const customType = useMemo(() => {
    if(isFilterByFromPerson && selectedUserId) {
      return 'Mention'
    }

    return type
  },[isFilterByFromPerson, selectedUserId, type])
  

  return (
    <div
      className="asign-list-archive"
      style={{ width: `${showSideBarInbox ? "52%" : "45%"}` }}
    >
      <div className="archive-all-notification">
        <DropdownFilter
          setKeyFilter={setKeyFilter}
          setType={setType}
          type={type}
          isArchive
          tabActive={tabActive}
          resetFilterConditions={resetFilterConditions}
        />
      </div>
      {dataScreenArchive &&
        dataScreenArchive?.map((item: any, index: any) => {
          if (dataScreenArchive?.length === index + 1) {
            return (
              <div
                className="active-item-ibox"
                style={{ background: `${item?.id === selected ? "#eee" : ""}` }}
                onClick={() => {
                  setSelected(item?.id);
                }}
                key={item.id}
              >
                <ArchiveItem
                  archive={flatten(item)}
                  getUserId={getUserId}
                  lastElement={lastElement}
                  index={index}
                  type={customType}
                  dataScreenActivity={dataScreenActivity}
                  setDataScreenActivity={setDataScreenActivity}
                  dataScreenArchive={dataScreenArchive}
                  setDataScreenArchive={setDataScreenArchive}
                  setPage={setPage}
                />
              </div>
            );
          }
          return (
            <div
              className="active-item-ibox"
              style={{ background: `${item?.id === selected ? "#eee" : ""}` }}
              onClick={() => {
                setSelected(item?.id);
              }}
              key={item.id}
            >
              <ArchiveItem
                archive={flatten(item)}
                getUserId={getUserId}
                index={index}
                type={customType}
                dataScreenActivity={dataScreenActivity}
                setDataScreenActivity={setDataScreenActivity}
                dataScreenArchive={dataScreenArchive}
                setDataScreenArchive={setDataScreenArchive}
                setPage={setPage}
              />
            </div>
          );
        })}
    </div>
  );
};
export default ArchiveContext;

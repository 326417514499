import { MentionData } from "@draft-js-plugins/mention";
import { BaseRecord, useCreate } from "@pankod/refine-core";
import { useAppContext } from "App.context/App.context";
import { TOKEN_KEY_LOGIN, USER_ID } from "authProvider";
import { Avatar } from "components/avatar/avatar";
import { useMyTask } from "components/Mytask/Context/MyTask.context";
import { Mention } from "components/Mytask/Mention/Mention";
import { PopUpCarlendar } from "components/Mytask/PopUpCarlendar/PopUpCarlendar";
import { useEffect, useState } from "react";
import { FaTimes } from "react-icons/fa";
import { DescTextEditor } from "../../DescTextEditor/DescTextEditor";
import AddToProject from "./AddToProject/AddToProject";
import "./TaskInfo.scss";
import { SocketTeamContext } from "socket/socketcontext";
import { useDetectProposePage } from "pages/ProposeTasks/hooks";
import { updateAssignee } from "pages/ProposeTasks/services";
import { useProposeState } from "pages/ProposeTasks/state";
import { useProposeTaskDetail } from "pages/ProposeTasks/state/details";
import useMyTaskStore from "pages/myTask/state/store";
interface TaskInfoProps {
  description?: string;
  resourcesid?: number;
  resourceDetails?: BaseRecord[];
}

export const TaskInfo: React.FC<TaskInfoProps> = ({
  description,
  resourcesid,
  resourceDetails,
}) => {
  const [assignee, setAssignee] = useState<MentionData | null>(null);
  const [assigneeData, setAssigneeData] = useState<MentionData | null>(null);
  const [open, setOpen] = useState(false);
  const [showMention, setShowMention] = useState(false);
  const [oldAssignee, setOldAssignee] = useState<MentionData | null>(null);
  const socketTeamContext = SocketTeamContext();

  const values = useMyTask();
  const currentProjectId = values?.currentProjectId;
  const addTaskByid:any = values?.addTaskByid
  const appContext = useAppContext();
  const setComments = values?.setComments;
  const userId = localStorage.getItem(USER_ID);
  const idUserTaskList =JSON.parse(localStorage.getItem(TOKEN_KEY_LOGIN) || "") ;
  const { data: myTaskData,updateTaskState } = useMyTaskStore()

  const { mutate } = useCreate();
  const handleAssignClick = () => {
    if (assignee) {
      setOldAssignee(assignee);
    }
    setShowMention(true);
    assignee && setAssignee(null);
  };

  const handleRemoveAssignee = async () => {
    const detaisChangeValue = {
      assignee: "removed",
    };
    currentProjectId == idUserTaskList && await socketTeamContext?.socket?.emit("joinRoomAssignee", {type:'remove',assignee, resourceId: resourcesid});
    await mutate(
      {
        resource: `resources/removeassign`,
        values: { resourceId: resourcesid },
        successNotification: {
          message: "Thành công !",
          type: "success",
        },
      },

      {
        onSuccess: (data, variables, context) => {
          setAssignee(null);
          values?.setResourceDetailsChange(detaisChangeValue);

          const newComment = {
            resourcesid: Math.random(),
            resources: {
              createdAt: new Date().toISOString(),
              createdby: userId,
              name: `{{${userId}}} unassigned from  {{${assignee?.id}}}`,
              resourcetype: "log",
            },
          };
          setComments && setComments((prev) => [...prev, newComment]);
        },
        onError: (error, variables, context) => {},
      }
    );
  };

  useEffect(() => {
    const handleAddAssign = () => {
      if (assigneeData?.type) {
        setAssignee(assigneeData);
        setAssigneeData(null);
        setOldAssignee(null);

        return;
      }

      if (assigneeData) {
        const assigneeValues = {
          memberId: assigneeData.id,
          resourceId: resourcesid,
          type: "addassign",
        };

        const detaisChangeValue = {
          assignee: assigneeData.id,
        };

        mutate(
          {
            resource: `resources/assigntask`,
            values: assigneeValues,
            successNotification: {
              message: "Thành công !",
              type: "success",
            },
            invalidates: ["all"],
          },

          {
            onSuccess: (data, variables, context) => {
              setAssignee(assigneeData);
              setAssigneeData(null);
              values?.setResourceDetailsChange(detaisChangeValue);

              const newComment = {
                resourcesid: Math.random(),
                resources: {
                  createdAt: new Date().toISOString(),
                  createdby: userId,
                  name: `{{${userId}}} assigned to {{${assigneeData.id}}}`,
                  resourcetype: "log",
                },
              };
              setComments && setComments((prev) => [...prev, newComment]);
             
              //check ở mytask nếu assigne người khác thì update lại state để khi assignee qua người khác xong về lại bị trùng sectionId 
              if(userId !== assigneeData?.id && addTaskByid && currentProjectId == idUserTaskList) {
                socketTeamContext?.socket?.emit("joinRoomAssignee", {type:'add',assigneeData,resourceId: resourcesid});       
                const allDataSection = myTaskData[addTaskByid]?.tasks ?? []
                const dataSectionFilter = allDataSection.filter((item:any) => item.resources.id !== resourcesid)
                updateTaskState(addTaskByid,dataSectionFilter)
              }
            },
          }
        );
      }
    };
    handleAddAssign();
  }, [assigneeData]);

  useEffect(() => {
    const getAssignee = (resourceDetails: BaseRecord) => {
      if (resourceDetails?.assignee) {
        const user = appContext?.mentionsData.find(
          (user) => user.id === +resourceDetails?.assignee
        );
        if (!user) return;
        setAssignee(user);
      } else {
        setAssignee(null);
      }
    };
    appContext?.mentionsData &&
      resourceDetails &&
      getAssignee(resourceDetails[0]);
  }, [appContext?.mentionsData, resourceDetails]);

  const { isProposeTaskPage } = useDetectProposePage();
  const {updateTask} = useProposeState()
  const {task} = useProposeTaskDetail()

  const handleUpdateAssignee = (data: MentionData) => {
    if(task) {   
        updateAssignee(data, task, updateTask)
    }
  }

  return (
    <div className="TaskInfo-container">
      <div className={"info-item " + (open && "focus")}>
        {/* Khu vực asigne */}
        <div className="info-label">Assignee</div>
        <div className="info-detail" onClick={handleAssignClick}>
          {assignee && <Avatar userId={Number(assignee.id)} />}
          {!assignee && (
            <>
              {showMention ? (
                <Mention
                  oldAssignee={oldAssignee}
                  customFunc={setShowMention}
                  open={open}
                  getData={ setAssigneeData}
                  setOpen={setOpen}
                  onSelectMention={isProposeTaskPage ? handleUpdateAssignee : undefined}
                />
              ) : (
                <span>Add Assignee</span>
              )}
            </>
          )}
          {assignee && <div className="asignee-name">{assignee.name}</div>}
        </div>
        {!open && assignee && (
          <FaTimes
            className="del-assigne-icon"
            onClick={handleRemoveAssignee}
          />
        )}
      </div>

      <div className="info-item">
        <div className="info-label">Due date</div>
        <div className="info-detail">
          {resourceDetails && values?.currentTaskDetailId && (
            <PopUpCarlendar
              placeholder="Add due date"
              dueOn={resourceDetails[0]?.dueOn}
              resourceId={
                typeof values?.currentTaskDetailId === "number"
                  ? values?.currentTaskDetailId
                  : undefined
              }
            />
          )}
        </div>
      </div>

      <AddToProject />

      <div className="info-item">
        <div className="info-label">Description</div>
      </div>
      <DescTextEditor description={description} resourcesid={resourcesid} />
    </div>
  );
};

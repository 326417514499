import { useEffect, useState } from "react";
import ProjectItemRecently from "./ProjectItemRecently";
import ProjectSelectDetailRecently from "./ProjectSelectDetailRecently";
import { useProjectRecentlyState } from "pages/Recently-completed-task/state/project-list";

interface TaskProjectDetailRecentlyIF {
  task?:any
  updateValueTask?:any
}

const TaskProjectDetailRecently:React.FC<TaskProjectDetailRecentlyIF> = ({task,updateValueTask}) => {
  const [openSearch, setOpenSearch] = useState(false)
  const [project, setProject] = useState([])
  
  const {projects}:any = useProjectRecentlyState()

  
  useEffect(() => {
    const data = task?.resourcesTask?.map((resource:any) => {
      const matching= projects?.find((projects:any) => projects.value === resource.projectId);
      return {
        ...resource,
        label: matching ? matching.label : null
      };
    })
    setProject(data)
  },[task,projects])

    const onBlur = () => {
      setOpenSearch(false)
    }
  
    const handleOpen = () => {
      setOpenSearch(true)
    }

    return (
        <div className="info-items">
          <div className="info-label">Projects</div>
            <div className="projects-details">
              <div className="listproject">
                {project?.map((item:any) => {
                   return <ProjectItemRecently key={item.id} item={item} task={task}/>
                })}
             
              </div>
              {!openSearch && <div className="project-btn" onClick={handleOpen}>Add to projects</div>}
    
              {openSearch &&  
              <ProjectSelectDetailRecently
                task = {task}
                setOpenSearch={setOpenSearch}
                onBlur={onBlur}
                updateValueTask={updateValueTask}
              />}
            </div>
          </div>
      );
}
export default TaskProjectDetailRecently
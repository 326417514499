import { useEffect, useRef, useState } from "react";
import { BiCheckCircle } from "react-icons/bi";


import {
  BsCalendarWeek,
  BsChat, BsPersonCircle
} from "react-icons/bs";
import { MdDragIndicator } from "react-icons/md";

import { BaseRecord } from "@pankod/refine-core";
import "./SubtaskAddRow.scss";
interface SubTaskAddRowProps {
  addSubTaskref:  React.RefObject<HTMLDivElement>;
  setSubTasks?: React.Dispatch<React.SetStateAction<BaseRecord[]>>;
  index: number;
  subTasks: BaseRecord[];
  newSubTask: BaseRecord | null
  setNewSubTask: React.Dispatch<React.SetStateAction<BaseRecord | null>>;
}



export const SubTaskAddRowHOC: React.FC<SubTaskAddRowProps> = ({newSubTask,
  setNewSubTask,
  addSubTaskref,index,subTasks, setSubTasks}) => {


  return <SubTaskAddRow 
  newSubTask={newSubTask}
  setNewSubTask={setNewSubTask}
  subTasks={subTasks}
  index={index} 
  setSubTasks={setSubTasks} 
  addSubTaskref={addSubTaskref}/>
}

 const SubTaskAddRow: React.FC<SubTaskAddRowProps> = ({newSubTask,
  addSubTaskref,
  setNewSubTask,
  setSubTasks,subTasks, index}) => {


  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const [taskContent, setTaskContent] = useState("");




  useEffect(() => {
    textAreaRef?.current && textAreaRef?.current.focus()
  },[])

  //Textbox autoresize
  const resizeTextArea = () => {
    textAreaRef?.current && (textAreaRef.current.style.height = "auto");
    textAreaRef?.current &&
      (textAreaRef.current.style.height =
        textAreaRef.current?.scrollHeight + "px");
  };

  useEffect(resizeTextArea, [taskContent]);

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setTaskContent(e.target.value);
  };
  //-------------------------------------------------------

  //Handle func
  const handleBlur = () => {
    if(!taskContent) return

    //Tạo task giả để đưa vào mảng giúp hiển thị nhanh trước
    const newSubTask: BaseRecord = {
      id: Math.floor(Date.now() + Math.random() ),
      resourcesid: Math.floor(Date.now() + Math.random() ),
      resources: {
        id: Math.floor(Date.now() + Math.random() ),
        name: taskContent
      }}


      //Xóa dóng đang được thêm và insert subtask vừa tạo
      const newSubTasks = [...subTasks];
      newSubTasks.splice(index, 1, newSubTask)
      setSubTasks && setSubTasks([...newSubTasks])


      //Gửi data và index để gọi api insertTask
      const taskData = {
        name: newSubTask.resources.name,
        currentSubTaskIndex: index
      }
      setNewSubTask(taskData)
  }

  const handleOnKeyDown = (e: React.KeyboardEvent) => {
    if(e.keyCode !== 13) {   
      return
    }
    e.preventDefault()
    handleBlur()
    textAreaRef.current?.blur()
  }


  

  return (
    <div className="subtaskaddrow-container " ref={addSubTaskref}>
      <div className="subtaskrow-wrapper">
        <MdDragIndicator className="dragIcon" />

        <div className="subtaskrow-title">
          <BiCheckCircle className="subtaskrow-checkicon" />
        </div>

        <div className="textarea-container">
          <textarea rows={1} ref={textAreaRef} onChange={handleChange} onBlur={handleBlur} onKeyDown={handleOnKeyDown}/>
        </div>

        <div className="subtaskrow-detail-icon">
          <BsCalendarWeek className="st-row-icon" />
          <BsPersonCircle className="st-row-icon" />

          <div className="chat-icon-wrapper">
            <BsChat className="st-row-icon" />
          </div>
        </div>
      </div>
    </div>
  );
};

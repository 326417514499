import { useMyTask } from "components/Mytask/Context/MyTask.context";
import { useDetectScroll, useTextboxAutoResize } from "pages/ProposeTasks/components/Details/TaskNameInput/hooks";
import { useTaskRecentSelect } from "pages/Recently-completed-task/hooks";
import { updateRecentlyTaskAsync } from "pages/Recently-completed-task/service";
import { useRecentlyState } from "pages/Recently-completed-task/state";
import { useRecentlyTaskOld } from "pages/Recently-completed-task/state/recently-task-old";
import React, { useCallback, useEffect, useRef } from "react"

const TaskNameDetailRecently:React.FC = () => {
    const values = useMyTask();
    const detailsRef = values?.detailsRef;
    const setChangeTitle = values?.setChangeTitle;
    const currentTaskDetailTitle = values?.currentTaskDetailTitle;
    const selectedTaskId:any = values?.selectedTaskId
   

    const textAreaRef = useRef<HTMLTextAreaElement>(null);

    useTextboxAutoResize(textAreaRef, currentTaskDetailTitle)
    useDetectScroll(detailsRef, textAreaRef, setChangeTitle)

    const {task: taskRecently,updateRecentlyTaskOld}:any = useRecentlyTaskOld()
    const taskRecentSelect = useTaskRecentSelect(taskRecently?.taskId ?? selectedTaskId)
    const { updateValueTask } = useRecentlyState()


    const handleChange = useCallback((e:any) => {
        const value = { name: e.target.value }
        updateValueTask(taskRecentSelect.taskId, value);
    },[taskRecentSelect])   

    const handleBlur = () => {
        if(taskRecentSelect) {
            updateRecentlyTaskAsync(taskRecentSelect.taskId, {name: taskRecentSelect?.name}, taskRecently , updateValueTask,updateRecentlyTaskOld)
        }
    }

    const handleKeyDown = (e: React.KeyboardEvent) => {
        if (e.keyCode !== 13) {
            return;
        }
        e.preventDefault();
    };

    
    return (
        <div className="taskname-container">
            <textarea
                placeholder="Write a task name"
                ref={textAreaRef}
                value={taskRecentSelect?.name}
                onChange={handleChange}
                rows={1}
                onBlur={handleBlur}
                spellCheck={false}
                onKeyDown={handleKeyDown}
            />
        </div>
      );
}
export default TaskNameDetailRecently
import {
    AiOutlineBold,
    AiOutlineItalic,
    AiOutlineUnderline,
    AiOutlineStrikethrough,
    AiOutlineUnorderedList,
    AiOutlineOrderedList,
  } from "react-icons/ai";


export const INLINE_STYLES = [
    {Icon: AiOutlineBold, style: 'BOLD'},
    {Icon: AiOutlineItalic, style: 'ITALIC'},
    {Icon:  AiOutlineUnderline, style: 'UNDERLINE'},
    {Icon: AiOutlineStrikethrough, style: 'STRIKETHROUGH'},
  ];

 export const BLOCK_TYPES = [
    {Icon: AiOutlineUnorderedList, style: 'unordered-list-item'},
    {Icon: AiOutlineOrderedList, style: 'ordered-list-item'},
  ];
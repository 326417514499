import { ErrorComponent, notificationProvider, ReadyPage } from '@pankod/refine-antd'
import { Refine, useNavigation, useRouterContext } from '@pankod/refine-core'
import { RefineKbarProvider } from '@pankod/refine-kbar'
import routerProvider from '@pankod/refine-react-router-v6'
import { MyTaskPage } from 'pages/myTask/MyTask'
import { useTranslation } from 'react-i18next'
import 'styles/antd.less'
import nestjsxCrudDataProvider from '../src/customs/index'
import { Footer, Layout, OffLayoutArea, Sider, Title, Header } from 'components/layout'
import { authProvider, TOKEN_KEY, TOKEN_KEY_LOGIN, USER_ID } from './authProvider'
import Test from 'pages/projects/Test'
import { Login } from 'components/login/Login'
import { TeamContext } from 'context/contextapi'
// import ProjectCreate from "pages/projects/list";
import ProjectContext from 'pages/Teams/Teamsproject'
import axios from 'axios'
import { ProjectsPage } from 'pages/ProjectsPage/ProjectsPage'
import { useParams } from 'react-router-dom'
import CreateProjectContext from 'pages/projects/Create_UpdateNewProject'
import { ListProjectTeam } from 'pages/Teams/ListProjectTeam/ListProjectTeam'
import { SingleTaskDetailsPage } from 'pages/SingleTaskDetails/SingleTaskDetailsPage'
// import InboxScreen from "pages/inbox/mt_inbox/InboxScreen";
import TabScreenInbox from 'pages/inbox/mt_tab_inbox/Tabs'
import { DeleteTaskPageHOC } from 'pages/DeleteTasks/DeleteTaskPage'
import useCheckIsMobile from 'hooks/useCheckIsMobile'
import { RedirectMobilePage } from 'pages/RedirectMobile/RedirectMobile'
import ApiConstants, { MOBILE_URL } from 'constanst'
import { UserTask } from 'pages/Favorites/UserTask'
import { Policy } from 'pages/Policy/Policy'
import { useEffect } from 'react'
import Cookies from 'universal-cookie'
import { decodeToken } from 'react-jwt'
import { useLogout } from '@pankod/refine-core'
import { SocketTeamContext } from 'socket/socketcontext'
import { NewInboxSocketTeamContext } from 'socket/newinboxcontext'
import './dx-styles.scss'
import './themes/generated/theme.additional.css'
import './themes/generated/theme.base.css'
import 'devextreme/dist/css/dx.common.css'
import { CompletedTaskPageHOC } from 'pages/CompletedTasks/CompletedTaskPage'
import ProposeTasks from 'pages/ProposeTasks'
import { CauHinhDanhMucFormHOC } from 'pages/cau-hinh-danh-muc-form'
import { FromTaoDeXuat } from 'pages/From'
// import './fontawasome';

function App() {
    const { useLocation, useHistory } = useRouterContext()
    const value = SocketTeamContext()

    const { t, i18n } = useTranslation()
    axios.defaults.baseURL = `${process.env.REACT_APP_API_URL || 'http://localhost:8000'}`
    axios.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem('refine-auth')

            if (token && config && config.headers) {
                config.headers['Authorization'] = `Bearer ${token}`
            }
            if (value && value?.setTokenKey) {
                value?.setTokenKey(`Bearer ${token}`)
            }
            return config
        },
        (error) => {
            return Promise.reject(error)
        }
    )
    const API_URL = `${process.env.REACT_APP_API_URL || 'http://localhost:8000'}`
    const dataProvider = nestjsxCrudDataProvider(API_URL)
    const i18nProvider = {
        translate: (key: string, params: object) => t(key, params),
        changeLocale: (lang: string) => i18n.changeLanguage(lang),
        getLocale: () => i18n.language
    }
    const dataTeams = TeamContext()
    const isMobile = useCheckIsMobile()
    const fullUrl = window.location.href
    const origin = window.location.origin

    const pathUrl = fullUrl.replace(origin, '')
    const pathName = MOBILE_URL + pathUrl

    if (pathUrl === '/policy') {
        return <Policy />
    }

    if (isMobile) {
        window.location.replace(pathName)
    }

    const cookies = new Cookies()
    const token = localStorage.getItem('refine-auth')

    const myDecodeToken = token && (decodeToken(token) as any)
    const expireDay = 1671930000

    if (myDecodeToken?.iat <= expireDay) {
        localStorage.removeItem(TOKEN_KEY)
        localStorage.removeItem(USER_ID)
        localStorage.removeItem(TOKEN_KEY_LOGIN)

        window.location.href = origin + '/login'
    }

    cookies.set('auth-cookie', token, { path: '/', expires: new Date(Date.now() + 60 * 60 * 24 * 7 * 1000) })

    return (
        <RefineKbarProvider>
            {!isMobile ? (
                <Refine
                    notificationProvider={notificationProvider}
                    ReadyPage={ReadyPage}
                    catchAll={<ErrorComponent />}
                    routerProvider={routerProvider}
                    dataProvider={dataProvider}
                    authProvider={authProvider}
                    LoginPage={Login}
                    disableTelemetry={true}
                    resources={[
                        {
                            name: `mytask`,
                            list: MyTaskPage,
                            show: MyTaskPage,
                            options: { hide: true }
                        },
                        {
                            name: `propose-task`,
                            list: ProposeTasks,
                            show: ProposeTasks,
                            options: { hide: true }
                        },
                        {
                            name: `user`,
                            list: UserTask,
                            show: UserTask,
                            options: { hide: true }
                        },
                        {
                            name: `task`,
                            show: SingleTaskDetailsPage,
                            options: { hide: true }
                        },
                        {
                            name: 'projects',
                            show: ProjectsPage,
                            options: { hide: true },
                            create: CreateProjectContext,
                            edit: CreateProjectContext
                        },
                        {
                            name: 'Inbox',
                            list: TabScreenInbox,
                            show: TabScreenInbox,
                            options: { hide: true }
                        },
                        {
                            name: 'team',
                            show: ProjectContext
                        },
                        {
                            name: 'deletetask',
                            list: DeleteTaskPageHOC,
                            show: DeleteTaskPageHOC,
                            options: { hide: true }
                        },

                        {
                            name: `${dataTeams?.dataTeams?.resources?.id}`,
                            parentName: 'team',
                            show: ProjectContext
                        },
                        {
                            name: 'overview',
                            parentName: `${dataTeams?.dataTeams?.resources?.id}`,
                            list: ProjectContext,
                            options: { hide: true }
                        },
                        {
                            name: 'completedtask',
                            list: CompletedTaskPageHOC,
                            show: CompletedTaskPageHOC,
                            options: { hide: true }
                        },
                        {
                            name: 'cauhinhdanhmucform',
                            list: CauHinhDanhMucFormHOC,
                            options: { hide: true }
                        },
                        {
                            name: `form`,
                            list: FromTaoDeXuat,
                            show: FromTaoDeXuat,
                            options: { hide: true }
                        }
                    ]}
                    Title={Title}
                    Header={Header}
                    Sider={Sider}
                    Footer={Footer}
                    Layout={Layout}
                    OffLayoutArea={OffLayoutArea}
                    i18nProvider={i18nProvider}
                />
            ) : (
                <RedirectMobilePage />
            )}
        </RefineKbarProvider>
    )
}

export default App
